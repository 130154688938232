export const options = {
    // 只能选择过去的时间
    disabledDate(time) {
        return time.getTime() > Date.now();
    },
    shortcuts: [
        {
            text: '今天',
            onClick(picker) {
                const start = new Date(new Date().setHours(0, 0, 0, 0));  // 当天00:00:00
                const end = new Date(new Date().setHours(0, 0, 0, 0) + 3600 * 24 * 1000 - 1) // 当天23:59:59
                picker.$emit('pick', [start, end]);
            }
        },
        {
            text: '最近3天',
            onClick(picker) {
                const start = new Date();
                const end = new Date();
                start.setTime(start.getTime() - 3600 * 24 * 1000 * 3);
                picker.$emit('pick',[start,end])
            }
        },
        {
            text: '最近7天',
            onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit('pick', [start, end]);
            }
        },
        {
            text: '最近30天',
            onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                picker.$emit('pick', [start, end]);
            }
        }, {
            text: '最近90天',
            onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                picker.$emit('pick', [start, end]);
            }
        }]
}
