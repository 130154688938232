<!-- 值班信息 -->
<template>
  <div class="WorkLog">
    <div class="pup_head">
      <div class="flex">
        <div class="pup_img">
          <img src="~@/assets/zong_lan_ye_img/jinye.png" style="width: 100%; height: 100%" alt />
        </div>
        <div class="fontOne">值班表</div>
      </div>
      <div class="flex">
        <div class="flex">
          <div
            class="clear-filter-button f25 iconfont cf icon-rizhiguanli mr16 cp"
            @click="$emit('beOnDuty')"
          ></div>
          <div class="WorkLog_img mt4 cp mr16 cp" @click="newAdd">
            <img src="~@/assets/zong_lan_ye_img/jairu.png" style="width: 100%; height: 100%" alt />
          </div>
          <!-- <div class="WorkLog_img mr16" @click="handle_infoFn">
            <img
              src="~@/assets/zong_lan_ye_img/baocun.png"
              style="width: 100%; height: 100%"
              alt=""
            />
          </div>-->
          <!-- <div class="WorkLog_img mr16" @click="download">
            <img
              src="~@/assets/zong_lan_ye_img/xaizai.png"
              style="width: 100%; height: 100%"
              alt=""
            />
          </div>
          <div class="WorkLog_img ">
            <img
              src="~@/assets/zong_lan_ye_img/beifen.png"
              style="width: 100%; height: 100%"
              alt=""
            />
          </div>-->
        </div>
        <div class="fontTwo" @click="pupClose">关闭</div>
      </div>
    </div>
    <div class="nei">
      <div style="height: 36px" class="seach">
        <el-date-picker
          v-model="timeDate"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="请选择值班时间"
          @change="dateTime"
        ></el-date-picker>
      </div>
      <div class="kuang">
        <div class="tr">
          <div class="w100 center">日期</div>
          <div class="w90 center">值班领导</div>
          <div class="w100 center">值班民警</div>
          <div class="w100 center">值班辅警</div>
          <div class="w100 center">值班辅警</div>
          <div class="w50 center">操作</div>
        </div>
        <div class="contain" v-infinite-scroll="load">
          <div class="dutybox mt10 addInput" v-for="(item, index) in dutyInfo" :key="index">
            <div class="center">
              <!-- <el-date-picker
              v-model="item.timeDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期时间"
              :picker-options="pickerOptions"
            >
              </el-date-picker>-->
              <input
                :class="item.isSetting ? 'edit' : ''"
                type="text"
                :disabled="!item.isSetting"
                v-model="item.timeDate"
              />
            </div>
            <div class="td" :class="!item.isSetting ? 'focus' : 'edit'">
              <el-select
                v-model="item.flag1"
                @input="selectLeader(item)"
                placeholder="请选择"
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="(item2, index2) in userList"
                  :key="index2"
                  :label="item2.name"
                  :value="index2"
                />
              </el-select>
              <div class="phone" style="font-size: 12px">{{ item.leaderPhone }}</div>
            </div>
            <div class="td" :class="!item.isSetting ? 'focus' : 'edit'">
              <el-select
                v-model="item.flag2"
                @input="selectPolice(item)"
                :popper-append-to-body="false"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item2, index2) in userList"
                  :key="index2"
                  :label="item2.name"
                  :value="index2"
                />
              </el-select>
              <div class="phone" style="font-size: 12px">{{ item.policePhone }}</div>
            </div>
            <div class="td" :class="!item.isSetting ? 'focus' : 'edit'">
              <el-select
                v-model="item.flag3"
                @input="selectAssistone(item)"
                :popper-append-to-body="false"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item2, index2) in userList2"
                  :key="index2"
                  :label="item2.name"
                  :value="index2"
                />
              </el-select>
              <div class="phone" style="font-size: 12px">{{ item.auxiliaryPolicePhone }}</div>
            </div>

            <div class="td" :class="!item.isSetting ? 'focus' : 'edit'">
              <el-select
                v-model="item.flag4"
                @input="selectAssisttwo(item)"
                :popper-append-to-body="false"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item2, index2) in userList2"
                  :key="index2"
                  :label="item2.name"
                  :value="index2"
                />
              </el-select>
              <div class="phone" style="font-size: 12px">{{ item.auxiliaryPolicePhoneTwo }}</div>
            </div>

            <el-tooltip content="编辑" placement="top">
              <img class="w23 h23" @click="editDuty(item)" src="~@/assets/images/home/edit.svg" alt />
            </el-tooltip>
            <el-tooltip content="保存" placement="top">
              <div @click="handle_info(item)" class="iconfont icon-baocun ml5 mt14"></div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      userList: null, //值班信息
      userList2: null,
      dutyInfo: null,
      timeDate: "",
      page: 1,
      pagenum: 10,
    };
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  mounted() {
    this.getRosterUser();
    this.getRosterUser2();
    this.getinfo(1);
  },
  methods: {
    dateTime(event) {
      // this.timeDate = event.target.value
      this.getinfo(1);
    },
    async handle_infoFn() {
      try {
        if (
          this.dutyInfo.every(
            (item) =>
              item.timeDate &&
              item.leaderOnDuty &&
              item.policeOnDuty &&
              item.auxiliaryPoliceOnDuty &&
              item.auxiliaryPoliceOnDutyTwo
          )
        ) {
          if (this.dutyInfo?.length) {
            let res = await API.HOME.addRoster(this.dutyInfo);
            let dutyInfo2 = new Set(
              this.dutyInfo.map((item) => {
                item["timeDate"];
              })
            );
            if (res) {
              // 这里的提示消息是后台反的
              this.$message.success(res);
              // 调用Bus 刷新总览页值班情况
              Bus.$emit("ToRequest");
            } else if (
              this.dutyInfo.length != dutyInfo2.length &&
              this.dutyInfo.length > 1
            ) {
              //  this.$message.warning("请勿输入重复时间");
            } else {
              this.$message.success("导入成功");
              this.$emit("update");
              this.$emit("handleClose");
              this.dutyInfo = [];
            }
          }
        } else this.$message.warning("请填入完整有效信息");
      } catch (error) {
        // this.$message.warning("请勿输入重复时间");
      }
      console.log(this.dutyInfo, "保存数据");
    },
    async download() {
      let url = `${process.env.VUE_APP_BASE_URL}/hawksystemserver/roster/exportTemplate?FLYINGSESSIONID=${this.user_info.FLYINGSESSIONID}&mmc-identity=${this.user_info["mmc-identity"]}`;
      const fileName = url;
      if ("download" in document.createElement("a")) {
        let downloadElement = document.createElement("a");
        downloadElement.href = url;
        downloadElement.download = fileName;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
      }
    },
    // 新增值班
    newAdd() {
      this.$emit("pup", 13);
    },
    pupClose() {
      this.$emit("handleClose");
    },
    selectLeader(item) {
      item.leaderOnDuty = this.userList[item.flag1].name;
      item.leaderPhone = this.userList[item.flag1].phone;
    },
    selectPolice(item) {
      item.policeOnDuty = this.userList[item.flag2].name;
      item.policePhone = this.userList[item.flag2].phone;
    },
    selectAssistone(item) {
      console.log("item123123", item);
      item.auxiliaryPoliceOnDuty = this.userList2[item.flag3].name;
      item.auxiliaryPolicePhone = this.userList2[item.flag3].phone;
    },
    selectAssisttwo(item) {
      console.log("item456456", item);
      item.auxiliaryPoliceOnDutyTwo = this.userList2[item.flag4].name;
      item.auxiliaryPolicePhoneTwo = this.userList2[item.flag4].phone;
    },
    async getRosterUser() {
      let res = await API.HOME.getRosterUser({
        type: 0,
        pageNo: 1,
        pageSize: 999,
      });
      this.userList = res.data.map((item) => {
        return {
          ...item,
          lable: item.name + item.phone,
          flag1: item.leaderOnDuty,
          flag2: item.policeOnDuty,
          flag3: item.auxiliaryPoliceOnDuty,
          flag4: item.auxiliaryPoliceOnDutyTwo,
        };
      });
    },
    async getRosterUser2() {
      let res = await API.HOME.getRosterUser({
        type: 1,
        pageNo: 1,
        pageSize: 999,
      });
      this.userList2 = res.data.map((item) => {
        return {
          ...item,
          lable: item.name + item.phone,
          flag1: null,
          flag2: null,
          flag3: null,
          flag4: null,
        };
      });
    },
    editDuty(item) {
      item.isSetting = true;
    },
    async handle_info(item) {
      try {
        let data = {
          id: item.id,
          timeDate: item.timeDate,
          leaderOnDuty: item.leaderOnDuty,
          auxiliaryPoliceOnDuty: item.auxiliaryPoliceOnDuty,
          auxiliaryPoliceOnDutyTwo: item.auxiliaryPoliceOnDutyTwo,
          policeOnDuty: item.policeOnDuty,
          policePhone: item.policePhone,
          leaderPhone: item.leaderPhone,
          auxiliaryPolicePhone: item.auxiliaryPolicePhone,
          auxiliaryPolicePhoneTwo: item.auxiliaryPolicePhoneTwo,
        };
        let res = await API.HOME.editRoster(data);

        this.$emit("update");
      } catch (error) {
        // this.$message.warning("请勿输入重复时间");
      }
    },
     load() {
      setTimeout(async () => {
        this.page++;
        let res = await API.HOME.getRoster(this.page, this.pagenum, {
          queryDate: this.timeDate,
        });

        res.records.forEach((item) =>
          this.dutyInfo.push({
            ...item,
            isSetting: false,
            flag1: item.leaderOnDuty,
            flag2: item.policeOnDuty,
            flag3: item.auxiliaryPoliceOnDuty,
            flag4: item.auxiliaryPoliceOnDutyTwo,
          })
        );
      }, 500);
      console.log("dutyInfo", this.dutyInfo);
      // this.getinfo()
    },
    async getinfo(page) {
      console.log(this.page, "this.page");
      this.dutyInfo = [];
      let res = await API.HOME.getRoster(page || this.page, this.pagenum, {
        queryDate: this.timeDate,
      });

      res.records.forEach((item) =>
        this.dutyInfo.push({
          ...item,
          isSetting: false,
          flag1: item.leaderOnDuty,
          flag2: item.policeOnDuty,
          flag3: item.auxiliaryPoliceOnDuty,
          flag4: item.auxiliaryPoliceOnDutyTwo,
        })
      );
      // this.dutyInfo.push(...a)
      // console.log("dutyInfo", this.dutyInfo);
    },
  },
};
</script>

<style scoped lang="scss">
.WorkLog {
  width: 650px;
  height: 314px;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);
}

.WorkLog_img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.flex {
  display: flex;
}

.pup_head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 16px 0 12px;
  align-items: center;
  height: 32px;
  background: linear-gradient(
    180deg,
    #9198ff 0%,
    rgba(45, 81, 153, 0.45) 40%,
    #05091a 100%
  );
  box-shadow: inset 0px 0px 10px 2px #3f9dff;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #427dff;

  .pup_img {
    display: flex;
    width: 19px;
    height: 25px;
    margin: 0 4px 0 0;
  }

  .fontOne {
    font-size: 20px;
    font-family: YouSheBiaoTiHei;
    color: #14faff;
    line-height: 26px;
    text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
    background: linear-gradient(
      135deg,
      #e3aa77 0%,
      #f5cda9 38%,
      #f9ecd3 58%,
      #fcdbb1 79%,
      #edb07a 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .fontTwo {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #d2dfff;
    cursor: pointer;
    line-height: 20px;
  }
}

.nei {
  width: 100%;
  box-sizing: border-box;
  padding: 12px;

  .seach {
    /deep/ .el-input__inner {
      color: #409eff !important;
      border: 1px solid #008fff;
      background-color: #1c2125 !important;
    }
  }
}

.kuang {
  width: 626px;
  height: 218px;
  background: rgba(8, 26, 58, 0.8);
  border-radius: 4px;
  border: 1px solid #315ec7;
  box-sizing: border-box;
  padding: 4px 4px 4px 10px;
}

.tr {
  width: 100%;
  height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-family: YouSheBiaoTiHei;
  color: #ebf9ff;
  line-height: 22px;
}

.th_tt {
  width: 100px;
}

.line_width {
  width: 80px;
  text-align: center;
}

/deep/.el-select-dropdown__item {
  color: #feffff;
}

/deep/ .el-scrollbar {
  overflow: hidden;
  position: relative;
  background-color: #1a2131;
  border: 1px solid #00b6ff;
}

//item选项的hover样式
/deep/ .el-select-dropdown__item.hover,
/deep/ .el-select-dropdown__item:hover {
  color: #409eff;
}

::v-deep.el-select-dropdown.el-popper {
  top: 260px;
}

.edit {
  /deep/ .el-select .el-input__inner {
    color: #fff !important;
  }
}

.td {
  /deep/.el-input__suffix {
    display: none;
  }
}

/deep/.el-select .el-input__inner {
  background-color: transparent;
  color: #92d9ff;
  border: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep.el-input--suffix .el-input__inner {
  background-color: transparent;
  // height: 30px;
  border: 1px solid #92d9ff;
  color: #fff;
  padding: 0;
}

::v-deep {
  .el-input--suffix .el-input__inner {
    border: 0;
    text-align: center;
    height: 24px;
  }

  .el-input__prefix {
    .el-input__icon {
      display: none;
    }
  }
}

.addInput {
  // height: 55px;
  box-sizing: border-box;
  display: flex;

  ::v-deep .el-input__icon {
    line-height: 5px;
  }

  .edit {
    color: #fff !important;
  }

  img {
    height: 25px;
    width: 25px;
    // margin-left: 15px;
    margin-top: 15px;
  }

  .td {
    position: relative;
    margin-left: 2px;
    text-align: center;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    .text {
      width: 80px;
      height: 30px;
      // border: 1px solid #00b6ff;
      // border-radius: 5px;
      font-size: 12px;

      .edit {
        color: #fff !important;
      }

      .name {
        margin-left: 30px;
        color: #92d9ff;
      }

      .phone {
        margin-left: 2px;
        color: #92d9ff;
      }
    }

    .seclect {
      outline: none;

      .innerbox {
        margin-top: 10px;
        width: 160px;
        height: 120px;
        overflow: hidden;
        overflow-y: scroll;
        margin-left: 5px;
      }

      width: 170px;
      height: 130px;
      position: absolute;
      top: 50px;
      z-index: 10;
      background-color: #1a2131;
      background-image: url("~@/assets/images/home/roster.png");
      background-repeat: no-repeat;

      span {
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #92d9ff;
        letter-spacing: 0;
        font-weight: 400;
      }
    }
  }
}

.icon-baocun {
  font-size: 23px;
  color: #00ffff;
}

.focus {
  pointer-events: none;
  color: #92d9ff;
}

.contain {
  height: 158px;
  overflow: hidden;
  overflow-y: scroll;

  .dutybox:nth-child(odd) {
    background: rgba(73, 135, 210, 0.2);
  }

  .dutybox:nth-child(even) {
    background: transparent;
  }
}

.dutybox {
  display: flex;

  input {
    border: 0;
    outline: none;
    font-family: MicrosoftYaHei;
    font-size: 12px;
    color: #92d9ff;
    letter-spacing: 0;
    font-weight: 400;
    width: 98px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: transparent;
  }
}

.infobox {
  position: fixed;
  left: 35%;
  top: 200px;
  width: 483px;
  height: 348px;
  background: rgba(22, 29, 45, 0.9);
  border: 1px solid #009fff;
  box-shadow: inset 0 0 7px 0 #008fff;
  border-radius: 10px;
}

.itembox {
  .header {
    margin-top: 30px;
    display: flex;
    padding-left: 25px;
    padding-right: 10px;

    input {
      margin-left: 30px;
      border: 1px solid #00b6ff;
      box-shadow: inset 0 0 3px 0 #00b6ff;
      border-radius: 4px;
      outline: none;
      background-color: transparent;
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #92d9ff;
      letter-spacing: 0;
      font-weight: 400;
    }

    .button {
      width: 70px;
      height: 28px;
      background-color: #00ffff;
      border-radius: 5px;
      margin-left: 20px;
      display: flex;
      font-family: MicrosoftYaHei-Bold;
      font-size: 14px;
      color: black;
      letter-spacing: 0;
      font-weight: 700;
    }
  }

  .divider {
    border: 0 solid #1575b0;
    width: 458px;
    height: 1px;
  }

  .tr {
    padding: 15px;
    padding-left: 0px !important;
    display: flex;
    font-family: MicrosoftYaHei-Bold;
    font-size: 14px;
    color: #00ffff;
    letter-spacing: 0;
    font-weight: 700;
  }
}

.triangle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #009fff;
}

/deep/.el-input__prefix {
  top: -6px;
}

/deep/ .el-input__suffix {
  top: -6px;
}

::v-deep {
  .timeDate_input {
    border: 1px solid #00b6ff;
    box-shadow: inset 0 0 3px 0 #00b6ff;
    border-radius: 4px;
    width: 184px;
    height: 27px;

    .el-input__inner {
      height: 27px;
    }
  }

  .el-input .el-input__clear {
    line-height: 30px;
  }
}
/deep/ .el-select-dropdown {
  left: auto !important;
  top: auto !important;
}
</style>
