import API from '@/api'
let data_source = null
let uav_data = null
import dayjs from 'dayjs'
import _ from 'lodash'
import * as turf from '@turf/turf'
import Bus from '@/assets/ligature.js'
import { fabric } from 'fabric'
let uavDeviceData = null

/**
 * 1 创建无人机列表
 * **/
let uavMap = new Map()
let homeUavCustomDataSource = null

class Parser {
  static uav_data_parser(metadata) {
    let data = JSON.parse(metadata)
    return data
  }
}

export default {
  /**
   *@Description: 接收无人机飞行数据信息
   *@Author: name
   *@Date: 2023-11-09 14:26:16
   *@Params1:
   *@Return1:
   */
  init_ws() {
    homeUavCustomDataSource = new Cesium.CustomDataSource('home_uav_custom_datasource')
    window.viewer.dataSources.add(homeUavCustomDataSource)
    let { user_info } = this
    let _this = this
    let username = user_info.username
    let token = user_info['mmc-identity'] + user_info['FLYINGSESSIONID']
    let appId = user_info.appid
    let ws_url = process.env.VUE_APP_WS_URL
    let ws = (this.uav.ws = new WebSocket(ws_url))
    let params = {
      type: 100,
      systemCode: 'mmc',
      state: 1,
      username,
      token,
      appId
    }
    ws.onopen = (e) => {
      console.log(`首页无人机ws: ${ws_url}连接已打开`, e)
      ws.send(JSON.stringify(params))
    }
    ws.onmessage = (e) => {


      // console.log('eeeeeeeeeeee');
      

      // 鹰视监听禁飞区
      // let metadata = JSON.parse(e.data)
      // console.log(`首页无人机飞行websocket${ws_url}接收数据`, e);
      let metadata = null
      try {
        // console.info(e.data, '原始数据');
        metadata = JSON.parse(e.data);
        // console.log('bbbbb', metadata?.deviceHardId);
        if (metadata?.deviceHardId) {
          // console.log(uavMap.get(metadata.deviceHardId), '根据deviceHardId查询');
          if (!uavMap.get(metadata.deviceHardId)) {
            // console.log(`新上线无人机 ${metadata.deviceHardId},添加到map集合中,添加到地图上`);
            uavMap.set(metadata.deviceHardId, metadata)
            this.uav_add_uav_map(metadata)
          } else {
            // console.log(`无人机 ${metadata.deviceHardId}已存在,更新地图上位置`);
            this.uav_update_uav_map(metadata)
          }
          // console.log('当前无人机有：' + uavMap.size + ' 个');
          // console.log(uavMap);
        }
      } catch (e) {
        console.error(e)
      }
      if (metadata && metadata.msgnum === 4120) {
        _this.$el_message('admin账号控制中，请稍后控制！', () => {}, 'error')
      }
      if (metadata && metadata.type === 300) {
        _this.$el_message('执行成功！')
      }
      if (metadata && metadata.type === 300 && metadata.cmdFunction == 9000) {
        _this.$el_message('航线上传成功！')
        // if (this.takeOff == true) {
        this.fly_take_off()
        // }
      }
      // if (metadata && metadata.type === 300 && metadata.cmdFunction == 9001) {
      //     this.uav.airway = metadata
      //     let {
      //         data: {
      //             points
      //         }
      //     } = this.uav.airway
      //     this.uav_handleAirLinePreview(points)
      // }
      // 监听无人机上锁
      if (metadata && metadata.type === 810) {
        let {
          uav: { control }
        } = this

        let deviceHardId = control.deviceHardId
        if (deviceHardId == metadata.deviceHardId) {
          // this.uav.taskStatus = true
        }
      }
      // // 视频推流状态更新 弃用
      // if (metadata && metadata.type === 999) {
      //     if (metadata.pushStatus) {
      //         this.uav_update_device_video_status(metadata.pushStatus, metadata.deviceHardId)
      //     }
      // }

      if (metadata && metadata.msgnum) {
        // 设备上线
        if (metadata.msgnum === 4132) {
          let deviceHardId = metadata && metadata.deviceHardId
          this.uav_update_device_report_status(1, deviceHardId, 'list')
          this.uav_update_device_report_status(1, deviceHardId, 'mountList')
        }
        // 设备离线
        if (metadata.msgnum === 4121) {
          let deviceHardId = metadata && metadata.deviceHardId
          this.uav_update_device_report_status(0, deviceHardId, 'list')
          this.uav_update_device_report_status(0, deviceHardId, 'mountList')
        }
      } else {
        // 无人机上报数据
        if (metadata && metadata.deviceData) {
          uav_data = JSON.parse(metadata.deviceData)

          // console.log('无人机上报数据 uav_data', uav_data);
          


          //模拟飞机上升
          // uav_data.data.uavInfo.altitude += (height += 0.1);
          if (uav_data && uav_data.deviceHardId) {
            if (uav_data.data && uav_data.data.uavInfo) {
              if (this?.uav?.control?.device?.deviceHardId == uav_data.deviceHardId && this.wsShow) {
                this.uav_update_control(this.uav.control.device, uav_data.data.uavInfo)
                this.uav_update_mounts(this.uav.control.device, uav_data.data.mountInfo)
                this.uav_update_control_battery(this.uav.control.device, uav_data.data.uavInfo)
                // this.uav_update_control(uav_data)
                if (uav_data.data.uavInfo.push_status) {
                  let push_status = uav_data.data.uavInfo.push_status
                  // 视频推流数据刷新
                  // this.uav_update_device_video_status(push_status, uav_data.deviceHardId, 'mountList')
                  // this.uav_update_device_video_status(push_status, uav_data.deviceHardId, 'list')
                }
              }
            }
            if (this.wsShow) {
              let lon = uav_data.data.uavInfo.longitude;
              let lat = uav_data.data.uavInfo.latitude;
              let height = uav_data.data.uavInfo.height;
              if (lon && lat && height) {
                this.uav_update_locations(uav_data)
              }

              // this.uav_update_locations(uav_data)
            }
          }
        }
        if (metadata && metadata.type === 800) {
          // this.uav.imgUrl = metadata.url
          // this.uav.imgVisible = true
        }
      }
    }
    ws.onerror = (error) => {
      console.log(`首页无人机ws: ${ws_url} 连接错误`, error)
    }
    ws.onclose = (data) => {
      console.log(`首页无人机ws: ${ws_url} 连接关闭`, data)
    }
  },
  /**
   *@Description: 接收各种状态信息 无人机离线，在线
   *@Author: name
   *@Date: 2023-11-09 14:26:51
   *@Params1:
   *@Return1:
   */
  init_ws_status() {
    let { user_info } = this
    let _this = this
    let username = user_info.username
    let token = user_info['mmc-identity'] + user_info['FLYINGSESSIONID']
    let appId = user_info.appid
    let ws_url = process.env.VUE_APP_WS_URL_ALARM
    let ws_status = (this.uav.ws_status = new WebSocket(ws_url))
    let params = {
      type: 100,
      systemCode: 'mmc',
      state: 1,
      username,
      token,
      appId
    }
    console.log('ws连接参数', params);
    
    ws_status.onopen = (e) => {
      console.log(`首页无人机状态ws: ${ws_url} 已连接`, e)
      ws_status.send(JSON.stringify(params))
    }
    ws_status.onmessage = (e) => {
      // console.info(e);
      // console.log(12121);
      // console.log(`首页无人机状态websocket${ws_url}接收数据`, e);
      try {
        let metadata = null
        // console.info(e, `${ws_url} e状态信息原始数据`);
        // console.info(e.data, `${ws_url} e.data状态信息原始数据`);
        metadata = JSON.parse(e.data)
        // console.info(metadata, `${ws_url} metadata状态信息原始数据`)
        /**
         * 1 判断是否为 msgnum 4139 的消息
         * 2 获取 deviceHardId
         * 3 根据 deviceHardId 获取uavMap里面的无人机信息
         * 4 根据状态onLine 更新无人机数据
         * 5 直接去更新无人机状态信息
         * **/
        if (metadata.msgnum === 4139) {
          // console.log(metadata.data.deviceHardId + '设备状态更新通知---4139')
          let deviceHardId = metadata.data.deviceHardId
          let uav = uavMap.get(deviceHardId)
          let en = uav.entity
          let deviceName = uav.deviceName
          if (uav && en && deviceName) {
            uav && (uav.onLine = parseInt(metadata.data.onLine))
            let onLineFlag = metadata?.data?.onLine ? parseInt(metadata.data.onLine) : 1
            if (onLineFlag === 1) {
              // en.label.text = `${deviceName}`
              // en.label.fillColor = Cesium.Color.fromCssColorString('#00F4FE')
            } else {
              // en.label.text = `${deviceName} 离线`
              // en.label.fillColor = Cesium.Color.fromCssColorString('#c0c0c0')
            }
          }
        }
      } catch (error) {
        console.info(error, '状态消息-dev')
      } finally {
      }
      // 测试
      // let keys = ['MMC301X22210002', 'MMC301R22208212', '3761157803188172087', 'MMC301R22208068'];
      // let keys_index = Math.floor(Math.random() * 4);
      // let deviceHardId = keys[keys_index];
      // let uav = uavMap.get(deviceHardId);
      // uav && (uav.onLine = parseInt(Math.round(Math.random())));
    }
    ws_status.onerror = (error) => {
      console.log(`首页无人机状态ws: ${ws_url} 连接错误`, error)
    }
    ws_status.onclose = (data) => {
      console.log(`首页无人机状态ws: ${ws_url} 连接关闭`, data)
    }
  },
  /**
   *@Description: 新上线的无人机，添加到地图上
   *@Author: name
   *@Date: 2023-11-09 11:07:29
   *@Params1:
   *@Return1:
   */
  add_uav_map(metadata) {
    // debugger
    let deviceData = JSON.parse(metadata.deviceData)
    // console.log(deviceData);
    let data = deviceData.data
    // console.log(metadata, 'metadata');
    // console.log(data, 'dataaaaaaaaaaaaaaaaa');
    // console.log(`添加${deviceData.deviceHardId}无人机到地图上`);
    let lng = parseFloat(data.uavInfo.longitude)
    let lat = parseFloat(data.uavInfo.latitude)
    let alt = parseFloat(data.uavInfo.altitude)
    let position = new Cesium.Cartesian3.fromDegrees(lng, lat, alt)
    // console.log(lng, lat, alt, 'lng, lat, alt');
    let orientation = Cesium.Transforms.headingPitchRollQuaternion(position, new Cesium.HeadingPitchRoll(Cesium.Math.toRadians(parseFloat(data.uavInfo.yaw)), Cesium.Math.toRadians(parseFloat(data.uavInfo.pitch)), Cesium.Math.toRadians(parseFloat(data.uavInfo.roll))))
    uavDeviceData = deviceData
    let text = ``
    let canvas = this.uav_generateCanvas(deviceData)
    let deviceName = '名称: ' + deviceData.deviceName
    let height = '高度: ' + deviceData.data.uavInfo.height + ' m'
    let longitude = '经度: ' + deviceData.data.uavInfo.longitude
    let latitude = '纬度: ' + deviceData.data.uavInfo.latitude
    let taskId = '任务ID: ' + deviceData.data.uavInfo.taskId
    let airSpeed = '速度: ' + parseFloat(deviceData.data.uavInfo.airSpeed).toFixed(2) + ' m/s'
    let distanceToHome = '距离: ' + deviceData.data.uavInfo.distanceToHome + ' m'
    text = deviceName + '\n' + height + '\n' + longitude + '\n' + latitude + '\n' + taskId + '\n' + airSpeed + '\n' + distanceToHome
    let en = homeUavCustomDataSource.entities.add({
      id: 'home_uav_' + deviceData.deviceHardId,
      self: deviceData,
      orientation: orientation,
      position: position,
      model: {
        uri: 'cesium/model/Q20/Q20_withAnimation_20230926_01.gltf',
        minimumPixelSize: 100,
        maximumScale: 10000,
        scale: 60
      },
      label: {
        // text: `${deviceData.deviceHardId} 在线`,
        // text: `${deviceData.deviceName} 在线`,
        show: false,
        // text: `${deviceData.deviceName}`,
        // text: text,
        // text: new Cesium.CallbackProperty((result, time) => {
        //   let deviceName = '名称: ' + uavDeviceData.deviceName;
        //   let height = '高度: ' + uavDeviceData.data.uavInfo.height + ' m';
        //   let longitude = '经度: ' + uavDeviceData.data.uavInfo.longitude;
        //   let latitude = '纬度: ' + uavDeviceData.data.uavInfo.latitude;
        //   let taskId = '任务ID: ' + uavDeviceData.data.uavInfo.taskId;
        //   let airSpeed = '速度: ' + parseFloat(uavDeviceData.data.uavInfo.airSpeed).toFixed(2) + ' m/s';
        //   let distanceToHome = '距离: ' + uavDeviceData.data.uavInfo.distanceToHome + ' m';
        //   text = deviceName + '\n'
        //     + height + '\n'
        //     + longitude + '\n'
        //     + latitude + '\n'
        //     + taskId + '\n'
        //     + airSpeed + '\n'
        //     + distanceToHome;
        //   return text;
        // }, true),
        // show: true,
        // text: "名称: dsadfdsfsdasd"
        //   + "\n" + "速度: 456.65"
        //   + "\n" + "速度: 456.65",
        // showBackground: true,
        // backgroundColor: Cesium.Color.fromCssColorString("rgba(0, 0, 0, 0.4)"),
        // horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
        showBackground: false,
        backgroundPadding: new Cesium.Cartesian2(5, 5),
        // font: "12px monospace",
        font: '14px sans-serif',
        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
        // horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        // verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        // pixelOffset: new Cesium.Cartesian2(-70, -90),
        pixelOffset: new Cesium.Cartesian2(-80, -30),
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        // fillColor: Cesium.Color.fromCssColorString("#00F4FE"), // 字体颜色
        fillColor: Cesium.Color.fromCssColorString('#ffffff'), // 字体颜色
        // fillColor: Cesium.Color.fromCssColorString("#ffffff"), // 字体颜色
        // translucencyByDistance: new Cesium.NearFarScalar(50, 1, 500000, 0),
        eyeOffset: new Cesium.Cartesian3(0, 0, -10),
        verticalOrigin: Cesium.VerticalOrigin.CENTER,
        translucencyByDistance: new Cesium.NearFarScalar(50000, 1, 1000000, 0)
      },
      billboard: {
        show: false,
        image: canvas,
        // image: new Cesium.CallbackProperty(() => {
        //   let canvas = this.uav_generateCanvas(uavDeviceData);
        //   return canvas;
        // }),
        // translucencyByDistance: new Cesium.NearFarScalar(50, 1, 500000, 0),
        pixelOffset: new Cesium.Cartesian2(-30, -85),
        eyeOffset: new Cesium.Cartesian3(0, 0, 0)
      }
    });
    // 经纬度转屏幕坐标
    // let screenCoordinate = Cesium.SceneTransforms.wgs84ToWindowCoordinates(window.viewer.scene, position);
    // this.createUavDivInfo(en, screenCoordinate);
    let uav = uavMap.get(deviceData.deviceHardId)
    // console.log(deviceData.deviceHardId, 'deviceData.deviceHardId');
    // console.log(uav, 'uavuavuav');
    uav && (uav.entity = en)
    uav && (uav.deviceName = deviceData.deviceName)
    // console.log(uavMap, 'uavMapuavMapuavMapuavMapuavMap');
  },
  generateCanvas(deviceData) {
    console.log(deviceData, 'uavInfouavInfouavInfo')
    // 创建一个自定义广告牌
    // 创建二维画布
    let canvas = document.createElement('canvas')
    let ctx = canvas.getContext('2d')
    let deviceName = '名称: ' + deviceData.deviceName
    let height = '高度: ' + deviceData.data.uavInfo.height
    let longitude = '经度: ' + deviceData.data.uavInfo.longitude
    let latitude = '纬度: ' + deviceData.data.uavInfo.latitude
    let taskId = '任务ID: ' + deviceData.data.uavInfo.taskId
    let airSpeed = '速度: ' + deviceData.data.uavInfo.airSpeed
    let distanceToHome = '距离: ' + deviceData.data.uavInfo.distanceToHome
    let a = []
    a.push(ctx.measureText(deviceName).width)
    a.push(ctx.measureText(height).width)
    a.push(ctx.measureText(longitude).width)
    a.push(ctx.measureText(latitude).width)
    a.push(ctx.measureText(taskId).width)
    a.push(ctx.measureText(airSpeed).width)
    a.push(ctx.measureText(distanceToHome).width)

    //绘制并填充一个圆角矩形
    fillRoundRect(ctx, 90, 0, 180, 130, 5, 'rgba(255, 255, 0, 0.1)')

    function fillRoundRect(cxt, x, y, width, height, radius, fillColor) {
      //圆的直径必然要小于矩形的宽高
      if (2 * radius > width || 2 * radius > height) {
        return false
      }
      cxt.save()
      cxt.translate(x, y)
      //绘制圆角矩形的各个边
      drawRoundRectPath(cxt, width, height, radius)
      cxt.fillStyle = fillColor || '#000' //若是给定了值就用给定的值否则给予默认值
      cxt.fill()
      cxt.restore()
    }
    function drawRoundRectPath(cxt, width, height, radius) {
      cxt.beginPath(0)
      //从右下角顺时针绘制，弧度从0到1/2PI
      cxt.arc(width - radius, height - radius, radius, 0, Math.PI / 2)

      //矩形下边线
      cxt.lineTo(radius, height)

      //左下角圆弧，弧度从1/2PI到PI
      cxt.arc(radius, height - radius, radius, Math.PI / 2, Math.PI)

      //矩形左边线
      cxt.lineTo(0, radius)

      //左上角圆弧，弧度从PI到3/2PI
      cxt.arc(radius, radius, radius, Math.PI, (Math.PI * 3) / 2)

      //上边线
      cxt.lineTo(width - radius, 0)

      //右上角圆弧
      cxt.arc(width - radius, radius, radius, (Math.PI * 3) / 2, Math.PI * 2)

      //右边线
      cxt.lineTo(width, height - radius)
      cxt.closePath()
    }
    ctx.font = '14px Microsoft YaHei'
    ctx.fillStyle = 'rgba(255, 0, 0, .5)'
    ctx.fillStyle = '#00F4FE'
    // ctx.fillText(deviceName, 100, 23);
    // ctx.fillText(height, 100, 43);
    // ctx.fillText(longitude, 100, 63);
    // ctx.fillText(latitude, 100, 83);
    // ctx.fillText(taskId, 100, 103);
    // ctx.fillText(airSpeed, 100, 123);
    // ctx.fillText(distanceToHome, 100, 143);
    return canvas
  },
  /**
   *@Description: 更新在线的无人机状态，添加到地图上
   *@Author: name
   *@Date: 2023-11-09 13:34:20
   *@Params1:
   *@Return1:
   */
  update_uav_map(metadata) {
    /**
     * 1 当前发送过来的无人机的 deviceHardId
     * 2 在uavMap中查找key值为deviceHardId的无人机信息，
     * 3 查询无人机在线状态 onLine
     * 4 获取其en属性值
     * 5 更新坐标和姿态
     * **/
    let deviceData = JSON.parse(metadata.deviceData)
    // console.log(deviceData, 'update_deviceData');
    let data = deviceData.data
    // console.log(data, 'update_data');
    let deviceHardId = deviceData.deviceHardId
    // console.log(deviceHardId, 'update_deviceHardId');

    let uav = uavMap.get(deviceHardId)
    let en = uav.entity
    // console.log(uav, 'uavuavuavuavuavuavuavuavuavuavuavuav');
    // console.log(uav.onLine, '===============================');
    if (en) {
      let lng = parseFloat(data.uavInfo.longitude)
      let lat = parseFloat(data.uavInfo.latitude)
      let alt = parseFloat(data.uavInfo.altitude)
      let position = new Cesium.Cartesian3.fromDegrees(lng, lat, alt)
      let orientation = Cesium.Transforms.headingPitchRollQuaternion(position, new Cesium.HeadingPitchRoll(Cesium.Math.toRadians(parseFloat(data.uavInfo.yaw)), Cesium.Math.toRadians(parseFloat(data.uavInfo.pitch)), Cesium.Math.toRadians(parseFloat(data.uavInfo.roll))))
      en.position = position
      en.orientation = orientation
      en._self = deviceData
      // uavDeviceData = deviceData;

      // let text = ``;
      // // let canvas = this.uav_generateCanvas(deviceData);
      // let deviceName = '名称: ' + deviceData.deviceName;
      // let height = '高度: ' + deviceData.data.uavInfo.height + ' m';
      // let longitude = '经度: ' + deviceData.data.uavInfo.longitude;
      // let latitude = '纬度: ' + deviceData.data.uavInfo.latitude;
      // let taskId = '任务ID: ' + deviceData.data.uavInfo.taskId;
      // let airSpeed = '速度: ' + parseFloat(deviceData.data.uavInfo.airSpeed).toFixed(2) + ' m/s';
      // let distanceToHome = '距离: ' + deviceData.data.uavInfo.distanceToHome + ' m';
      // text = deviceName + '\n'
      //   + height + '\n'
      //   + longitude + '\n'
      //   + latitude + '\n'
      //   + taskId + '\n'
      //   + airSpeed + '\n'
      //   + distanceToHome;
      // en.label.text = text;

      // let canvas = this.uav_generateCanvas(deviceData);
      // en.billboard.image = canvas;
      // uavDeviceData = deviceData;
    }

    // if (_.isUndefined(uav.onLine) || uav.onLine === 1) {
    //   // 在线
    //   // console.log(deviceHardId, '在线');
    //   let lng = parseFloat(data.uavInfo.longitude);
    //   let lat = parseFloat(data.uavInfo.latitude);
    //   let alt = parseFloat(data.uavInfo.altitude);
    //   let position = new Cesium.Cartesian3.fromDegrees(lng, lat, alt);
    //   let orientation = Cesium.Transforms.headingPitchRollQuaternion(position, new Cesium.HeadingPitchRoll(
    //     Cesium.Math.toRadians(parseFloat(data.uavInfo.yaw)), Cesium.Math.toRadians(parseFloat(data.uavInfo.pitch)),
    //     Cesium.Math.toRadians(parseFloat(data.uavInfo.roll))));
    //   en.position = position;
    //   en.orientation = orientation;
    //   en.label.text = `${deviceData.deviceName}`;
    //   en.label.fillColor = Cesium.Color.fromCssColorString("#00F4FE");
    // } else {
    //   // 离线
    //   // 文字颜色设置为灰色
    //   console.log(deviceHardId, '离线');
    //   en.label.text = `${deviceData.deviceName} 离线`;
    //   en.label.fillColor = Cesium.Color.fromCssColorString("#4A647C");
    // }
  },
  //请求无人机控制权限
  async jursdiction() {
    let res = await API.FCKERNEL.checkUavControl({
      deviceHardId: this.uav.NoticeData.deviceHardId
    })
    if (res.code == 200) {
      this.$message.success(res.msg)
      this.uav.NoticeFlag = false
    }
  },
  async init_data_source() {
    data_source = new Cesium.CustomDataSource('FCKERNEL_LAYER')
    await viewer.dataSources.add(data_source)
  },
  destroy_data_source() {
    if (data_source) {
      data_source.entities.removeAll()
      viewer.dataSources.remove(data_source, true)
      data_source = null
    }
  },

  list_filter(search) {
    let device_list = tree2list(this.uav.list)
    let org_list
    let org_ids = []
    if (search) {
      device_list.forEach((item) => (item.display = false))
      let filter_device = device_list.filter((item) => item.deviceName && item.deviceName.indexOf(search) !== -1)
      filter_device.forEach((item) => {
        item.display = true
        org_ids.push(item.org_id)
      })

      org_list.forEach((item) => (item.display = false))
      let filter_org = org_list.filter((item) => item.orgName && item.orgName.indexOf(search) !== -1)
      function each_display(tree) {
        tree.forEach((item) => {
          item.display = true
          if (item.child && item.child.length) {
            each_display(item.child)
          }
          if (item.devices && item.devices.length) {
            item.devices.forEach((device) => (device.display = true))
          }
        })
      }
      filter_org.forEach((item) => each_display([item]))

      filter_org.forEach((item) => org_ids.push(item.id))
      let pid = 0
      org_ids.forEach((id) => {
        pid = id
        while (pid >= 0) {
          let item = org_list.find((item) => item.id === pid)
          if (item) {
            item.display = true
            pid = item.pid
          } else {
            pid = -1
          }
        }
      })
    } else {
      device_list.forEach((item) => (item.display = true))
      org_list.forEach((item) => (item.display = true))
    }
  },

  async list(search) {
    /*  if (this.uav.list && this.uav.list.length) {
         this.accident_list_filter(search, 'list')
         return
     } */
    let index = this.$route.query.page
    let type = ''
    if (index == 0) {
      type = 'patrol'
    } else if (index == 1) {
      type = 'traffic'
    } else if (index == 2) {
      type = 'food'
    } else if (index == 3) {
      type = 'net'
    } else if (index == 4) {
      type = 'water'
    } else if (index == 5) {
      type = 'crimes'
    }
    // else if (index == 6) {
    //   type = 'crimes'
    // }
    let list = await API.ACCIDENT.strikeOrganTree({
      type
    })
    try {
      if (list[0].child) {
        list[0].child.sort(function (a, b) {
          return a.sort - b.sort
        })
      }
    } catch (error) {}

    function each(tree, flag) {
      return tree.map((item) => ({
        ...item,
        collapse: flag,
        // display: true,
        devices:
          item.devices && item.devices.length
            ? item.devices.map((device) => ({
                ...device,
                _location: false,
                _video: false,
                _airway: false,
                _control: false,
                org_id: item.id
                // display: true
              }))
            : [],
        child: item.child && item.child.length ? each(item.child, true) : []
      }))
    }

    if (list && list.length) {
      list = each(list)
    }
    this.uav.list = list || []

    // return;
    // console.log();

    // 气体检测标志
    if (index == 6) {
      console.log('气体检测进入')
      this.uav.gas = true
      this.nsetLeftBoxShowHandle(false)
      Bus.$emit('gas_init')
      return
    } else {
      console.log('气体检测退出')
      this.uav.gas = false
      this.nsetLeftBoxShowHandle(true)
      this.uav_claerGas()
    }
    // 灾情对比标志
    // debugger
    if (index == 7) {
      this.uav.disaster = true
      this.$nextTick(() => {
        this.uav_disaster()
        this.nsetLeftBoxShowHandle(false)
      })
      return
    } else {
      this.uav.disaster = false
      this.uav_remove_disaster()
      this.nsetLeftBoxShowHandle(true)
    }
  },

  subscribe(topic, callbck) {
    this.mqtt_subscribe(topic, callbck)
  },

  async control_ws(cate) {
    // let {
    //     flight_line_id,
    //     id
    // } = this.$store.state.task.controlPanelData;
    let deviceHardId = this.uav.control.device.deviceHardId
    // let taskId = this.$store.state.fckernel.taskId
    // if (!deviceHardId) {
    //     return false
    // }
    let username = this.user_info.username
    // if (cate > 8 && taskId) {} else {
    //     return this.$message.info('请选择航线')
    // }
    if (cate == 11) {
      // 安全降落
      this.$confirm('请确认是否执行安全降落操作?', '安全确认', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        customClass: 'uav_controlPane',
        showClose: false
      })
        .then(() => {
          this.uav_send_ws({
            type: 200,
            systemCode: 'mmc',
            state: 1,
            username,
            data: {
              cmdFunction: 2116
            },
            deviceHardId
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    } else if (cate == 12) {
      // 一键返航
      this.$confirm('请确认是否进行一键返航操作?', '返航确认', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        customClass: 'uav_controlPane',
        showClose: false
      })
        .then(() => {
          this.uav_send_ws({
            type: 200,
            systemCode: 'mmc',
            state: 1,
            username,
            data: {
              cmdFunction: 2112
            },
            deviceHardId
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    } else if (cate == 13) {
      // 航线模式
      this.uav_send_ws({
        type: 200,
        systemCode: 'mmc',
        state: 1,
        username,
        data: {
          cmdFunction: 2115
        },
        deviceHardId
      })
    } else if (cate == 17) {
      // 暂停飞行
      this.uav_send_ws({
        type: 200,
        systemCode: 'mmc',
        state: 1,
        username,
        data: {
          cmdFunction: 2117
        },
        deviceHardId
      })
    }
  },

  send_ws(data) {
    this.uav.ws && this.uav.ws.send(JSON.stringify(data))
  },
  async fn(cate, device, orgName) {
    console.log('===========场景面板============')

    this.uavId = device

    if (device.reportStatus !== 1) {
      // this.$message('无人机不在线')
      return
    }
    if (!data_source) {
      await this.uav_init_data_source()
    }
    // this.controlMenuFlag = !this.controlMenuFlag
    this.controlMenuFlag = false

    // 将列表缩进左边
    // this.collapseFlagfn()
    let uavCate = device.cateName == 'px4' ? 'PX4' : 'APM'
    let topic = `${uavCate}/RECEIVE/${device.deviceHardId}`
    if (cate === 1) {
      // 定位
      device._location = !device._location
      if (device._location) {
        this.uav_show_location(device)
      } else {
        this.uav_hide_location(device)
      }
    } else if (cate === 2) {
      // 航线
      device._airway = !device._airway
      if (device._airway) {
        this.uav_show_airway(device)
      } else {
        this.uav_hide_airway(device)
      }
    } else if (cate === 3) {
      // 视频
      device._video = !device._video
      if (device._video) {
        this.uav_show_video(device, orgName)
      } else {
        this.uav_hide_video(device)
      }
    } else if (cate === 4) {
      // let list = await API.FCKERNEL.ListUAV()
      // 控制面板
      device._control = !device._control
      if (device._control) {
        this.controlMenuFlag = false
        device._video = true
        this.uav_show_video(device, orgName)
        this.uav_show_control(device)
        this.ControlFlag = true
        // this.flag = false
        this.VideoTomapFlag = true
        // 打开 任务面板
        if (device.stationType == 1) {
          this.wsShow = false
          this.uav_subscribe(topic, () => {
            console.log(`订阅无人机数据：topic:${topic}`)
          })
        } else {
          if (this.mqtt.client) {
            this.mqtt.client.end()
            this.mqtt.client = null
          }
          this.wsShow = true
          this.uav_send_ws({
            type: 1126,
            deviceHardId: device.deviceHardId
          })
        }
        // this.collapseFlag = true
      } else {
        this.controlMenuFlag = false
        this.VideoTomapFlag = false
        // if (this.mqtt.client) {
        //     this.mqtt.client.end()
        //     this.mqtt.client = null
        // }
        // // 取消订阅
        device._video = false
        this.ControlFlag = false
        // this.mqtt_unsubscribe(topic)
        this.uav_hide_control(device)
        this.uav_hide_video(device)
      }
    } else if (cate === 5) {
      // 查看是否有接管权限
      let res = await API.FCKERNEL.getTakeOverInfo({ deviceHardId: device.deviceHardId })
      if (res.code == 201) {
        this.$message.warning(res.msg)
        return false
      } else if (res.code == 200) {
        let res = await API.FCKERNEL.checkUseOperate({ deviceHardId: device.deviceHardId })
        if (res.code == 201) {
          let text = `是否请求接管 ${orgName} 下的 ${device.deviceName}`
          this.uav.NoticeData.deviceHardId = device.deviceHardId
          this.uav.NoticeData.content = text
          this.uav.NoticeFlag = true
        } else if (res.code == 200) {
          return this.$message.warning(res.msg)
        }
      }
    }
  },
  trans_location_data(device, uav_data) {
    if (!uav_data) {
      return uav_data
    }

    let uavInfo = uav_data.uavInfo
    let position = null
    let positions = []
    let orientation = null
    try {
      position = Cesium.Cartesian3.fromDegrees(uavInfo.longitude * 1, uavInfo.latitude * 1, uavInfo.altitude * 1)
      let yaw = Cesium.Math.toRadians(uavInfo.yaw + 90),
        pitch = Cesium.Math.toRadians(uavInfo.pitch),
        roll = Cesium.Math.toRadians(uavInfo.roll),
        hpr = new Cesium.HeadingPitchRoll(yaw, pitch, roll)
      orientation = Cesium.Transforms.headingPitchRollQuaternion(position, hpr)

      if (uavInfo.longitude != 0 && uavInfo.latitude != 0) {
        positions.push(position)
      }
    } catch (e) {
      this.$message({
        type: 'warning',
        message: '定位失败'
      })
    }

    return {
      position,
      positions,
      orientation,
      device
    }
  },
  trans_location_datas(device, uav_data) {
    if (!uav_data) {
      return uav_data
    }

    let uavInfo = uav_data.data.uavInfo
    uavInfo.altitude += 10
    let position = Cesium.Cartesian3.fromDegrees(uavInfo.longitude * 1, uavInfo.latitude * 1, uavInfo.altitude * 1)
    let yaw = Cesium.Math.toRadians(uavInfo.yaw + 90),
      pitch = Cesium.Math.toRadians(uavInfo.pitch),
      roll = Cesium.Math.toRadians(uavInfo.roll),
      hpr = new Cesium.HeadingPitchRoll(yaw, pitch, roll),
      orientation = Cesium.Transforms.headingPitchRollQuaternion(position, hpr)

    let positions = []
    if (uavInfo.longitude != 0 && uavInfo.latitude != 0) {
      positions.push(position)
    }

    return {
      position,
      positions,
      orientation,
      device
    }
  },
  trans_mqtt_location_data(device, uav_data) {
    if (!uav_data) {
      return uav_data
    }
    // 获取航线
    let location = uav_data.locationCoordinate3D
    let position = Cesium.Cartesian3.fromDegrees(location.longitude * 1, location.latitude * 1, location.altitude * 1)

    let positions = []
    let attitude = uav_data.attitude
    let yaw = Cesium.Math.toRadians(attitude.yaw + 90),
      pitch = Cesium.Math.toRadians(attitude.pitch),
      roll = Cesium.Math.toRadians(attitude.roll),
      hpr = new Cesium.HeadingPitchRoll(yaw, pitch, roll),
      orientation = Cesium.Transforms.headingPitchRollQuaternion(position, hpr)
    if (this.uav.model) {
      let model = this.uav.model
      const curPos = model.position.getValue(Cesium.JulianDate.now())
      if (curPos) {
        const curCart = new Cesium.Cartesian3(curPos.x, curPos.y, curPos.z)
        const afterCart = new Cesium.Cartesian3(position.x, position.y, position.z)
        if (curCart.equals(afterCart)) {
        } else {
          // 航线数据
          positions.push(position)
        }
      } else {
      }
    }

    return {
      position,
      positions,
      orientation,
      device
    }
  },
  Cartesian3_to_WGS84(point) {
    var cartesian33 = new Cesium.Cartesian3(point.x, point.y, point.z)
    var cartographic = Cesium.Cartographic.fromCartesian(cartesian33)
    var lat = Cesium.Math.toDegrees(cartographic.latitude)
    var lng = Cesium.Math.toDegrees(cartographic.longitude)
    var alt = cartographic.height
    return { lng: lng, lat: lat, alt: alt }
  },
  // 鹰视  航线 高度 加10
  async show_location(device) {
    if (!this.wsShow) {
      if (!this.linevisible) {
        this.linevisible = true
        viewer.trackedEntity = null
        let uav_data = await API.FCKERNEL.GetLastUavData({
          deviceHardId: device.deviceHardId
        })

        if (!uav_data) {
          this.$el_message('暂未找到位置信息', () => {}, 'error')
          return uav_data
        }
        if (!data_source) {
          await this.uav_init_data_source()
        }
        let entities = data_source.entities
        let cesium_layer = this.g_cesium_layer()
        try {
          //由于3D地图悬浮于2D地图表面，需要增加无人机模型的海拔高度，不然会被3D地图遮挡
          uav_data.uavInfo.altitude += 10
        } catch (e) {}
        // console.log('海拔高度增加10米', uav_data.uavInfo.altitude);
        let data = this.uav_trans_location_data(device, uav_data)
        let deviceHardId = device.deviceHardId
        let model = entities.getById(`fckernel_model_${deviceHardId}`)
        this.uav.online[deviceHardId] = data

        if (!model) {
          this.uav.online[deviceHardId].entity_id = `fckernel_model_${deviceHardId}`
          model = this.uav.model = cesium_layer.add_model(entities, this.uav.online[deviceHardId])

          //cartesian3转wgs84, 通过经纬度高度1000米创建新cartesian3，并设置相机飞入此位置
          var ellipsoid = viewer.scene.globe.ellipsoid
          var cartesian3 = model.position.getValue(Cesium.JulianDate.now())
          var cartogtraphic = ellipsoid.cartesianToCartographic(cartesian3)
          var lat = Cesium.Math.toDegrees(cartogtraphic.latitude)
          var lng = Cesium.Math.toDegrees(cartogtraphic.longitude)

          viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(lng, lat, 1000), //model.position.getValue(Cesium.JulianDate.now()),
            maximumHeight: 100,
            complete: () => {
              viewer.trackedEntity = model
            }
          })
          model.viewFrom = new Cesium.Cartesian3(0, 0, 1000)

          this.uav.model = model
        }

        let polyline = entities.getById(`fckernel_polyline_${deviceHardId}`)
        if (!polyline) {
          let model = cesium_layer.add_polyline(entities, {
            ...data,
            entity_id: `fckernel_polyline_${deviceHardId}`,
            options: {
              show: true
            }
          })
        }
      } else {
        this.linevisible = false
        this.uav_remove_model(device)
      }
    } else {
      if (!this.linevisible) {
        this.linevisible = true
        let uav_data = Parser.uav_data_parser(device.latestData)
        this.uav_show_airways(device, uav_data)
      } else {
        this.linevisible = false
        this.uav_hide_locations(device, uav_data)
      }
    }
  },
  hide_locations(device, uav_data) {
    let cesium_layer = this.g_cesium_layer()
    let viewer = cesium_layer.viewer()
    viewer.trackedEntity = null
    let entities = viewer.entities.getById(this.uav.online[device.deviceHardId].entity_id)
    viewer.entities.remove(entities)
  },
  show_airways(device, uav_data) {
    // console.log(device,uav_data);
    let entities = window.viewer.entities
    if (!device._location) {
      device._location = true
      this.uav_show_locations(device, uav_data)
    }
    let entity = entities.getById(`observe_polylineUav_${device.deviceHardId}`)
    entity && (entity.polyline.show = true)
  },
  show_locations(device, uav_data) {
    // if (!data_source) {
    //     return false
    // }
    if (!uav_data) {
      // this.$el_message("暂未找到位置信息", () => { }, "error")
      return uav_data
    }

    // let devices = tree2list(this.uav.list)
    // devices.map(item => item.deviceHardId != device.deviceHardId && (item._location = false))
    let { deviceHardId } = device
    let cesium_layer = this.g_cesium_layer()
    let viewer = window.viewer
    let data = this.uav_trans_location_datas(device, uav_data)
    let model = window.viewer.entities.getById(`observe_model_${deviceHardId}`)
    if (!model) {
      this.uav.online[deviceHardId] = data
      this.uav.online[deviceHardId].entity_id = `observe_model_${data.device.deviceHardId}`
      model = cesium_layer.add_model(window.viewer.entities, this.uav.online[deviceHardId])
    }

    let polyline = window.viewer.entities.getById(`observe_polylineUav_${deviceHardId}`)
    if (!polyline) {
      cesium_layer.add_polyline(window.viewer.entities, {
        ...data,
        entity_id: `observe_polylineUav_${data.device.deviceHardId}`,
        options: {
          show: false
        }
      })
    }

    viewer.camera.flyTo({
      destination: model.position.getValue(Cesium.JulianDate.now()),
      maximumHeight: 10,
      complete: () => {
        viewer.trackedEntity = model
      }
    })
    model.viewFrom = new Cesium.Cartesian3(0, 0, 1000)
  },
  remove_model(device) {
    // 删除飞控 无人机 模型航线
    if (data_source) {
      let entities = data_source.entities
      entities.removeById(`fckernel_model_${device.deviceHardId}`)
    }
    this.linevisible = false
    this.uav_hide_airway(device)
  },
  hide_location(device, uav_data) {
    viewer.trackedEntity = null
  },
  update_location(device, uav_data) {
    let online = this.uav.online[device.deviceHardId]
    if (online) {
      let data = this.uav_trans_mqtt_location_data({ device }, uav_data)
      online.orientation = data.orientation
      online.position = data.position
      if (data.positions.length > 0) {
        online.positions.push(data.position)
      }
    }
  },
  update_locations(uav_data) {
    let online = this.uav.online[uav_data.deviceHardId]
    // this.uav.datalist = uav_data.data
    if (online) {
      let data = this.uav_trans_location_datas({}, uav_data)
      online.orientation = data.orientation
      online.position = data.position
      online.positions.push(online.position)
    }
  },
  async show_airway(device) {
    let polyline = entities.getById(`fckernel_polyline_${deviceHardId}`)
    if (!polyline) {
      cesium_layer.add_polyline(entities, {
        ...data,
        entity_id: `fckernel_polyline_${data.device.deviceHardId}`,
        options: {
          show: true
        }
      })
    }
  },
  hide_airway(device) {
    if (!data_source) {
      return false
    }

    let entities = data_source.entities
    let entity = entities.getById(`fckernel_polyline_${device.deviceHardId}`)
    entity && (entity.polyline.show = false)
    if (device.deviceHardId) {
      try {
        // 删除飞机模型
        window.viewer.entities.removeById(`fckernel_polyline_${device.deviceHardId}`)
      } catch (error) {}
    }
  },
  // 显示视频
  async show_video(device, orgName) {
    // 先清空所有无人机控制的状态
    try {
      let device_list = tree2list(this.uav.list)
      device_list.forEach((item) => {
        if (item.deviceHardId !== device.deviceHardId && item._control) {
          item._video = false
          this.uav_hide_control(item)
        }
      })

      this.$set(this.uav.control, 'device', device)
    } catch (error) {}
    // onLine
    let data = await API.FCKERNEL.GetDeviceStreamAddressByDeviceId({ deviceHardId: device.deviceHardId })
    if (!data.list) {
      device._video = false
      return this.$message.info('暂无视频')
    } else {
      let index = this.uav.videos.findIndex((item) => item === undefined)
      if (index !== -1) {
        this.uav.videos.splice(index, 1, {
          ...device,
          orgName,
          videos: data
        })
      } else {
        if (this.uav.videos.length < 4) {
          this.uav.videos = [
            {
              ...device,
              orgName,
              videos: data
            }
          ]
          // this.uav.videos.push({
          //     ...device,
          //     orgName,
          //     videos: data,
          // })
        } else {
          this.uav_hide_video(this.uav.videos[0])
          this.uav_show_video(device, uav_data)
        }
      }
    }
  },
  hide_video(device) {
    let { deviceHardId } = device
    let index = this.uav.videos.findIndex((item) => item && item.deviceHardId === deviceHardId)

    let devices = tree2list(this.uav.list)
    device = devices.find((item) => item.deviceHardId === deviceHardId)

    if (index !== -1) {
      device._video = false
      this.uav.videos.splice(index, 1)
    }
  },

  show_control(device) {
    // 先清空所有无人机控制的状态
    let device_list = tree2list(this.uav.list)
    device_list.forEach((item) => {
      if (item.deviceHardId !== device.deviceHardId && item._control) {
        item._control = false
        this.uav_hide_control(item)
      }
    })

    this.$set(this.uav.control, 'device', device)
  },
  hide_control(device) {
    this.uav.control.device = null
    this.uav.control.device = null
    this.uav.control.data = null
    this.uav.control.battery = null
    this.uav.control.mount = null
    this.uav.control.mounts = []
    this.uav.control.mounts = []
  },
  update_control(device, uav_data) {
    let control_device = this.uav.control.device
    if (control_device && control_device.deviceHardId === device.deviceHardId) {
      this.$set(this.uav.control, 'data', uav_data)
    }
  },
  update_control_battery(device, battery_data) {
    let control_device = this.uav.control.device
    if (control_device && control_device.deviceHardId === device.deviceHardId) {
      this.$set(this.uav.control, 'battery', battery_data)
    }
  },
  update_channelData(device, channelData) {
    // if (this.uav.control.device.deviceHardId === device.deviceHardId) {
    this.$set(this.uav.control, 'channelData', channelData)
    // }
  },
  // 无人机提示信息
  update_control_info(device, battery_data) {
    let control_device = this.uav.control.device
    if (control_device && control_device.deviceHardId === device.deviceHardId) {
      this.uav.control.info.push(battery_data)
    }
  },
  update_mount(device, data) {
    let control_device = this.uav.control.device
    if (control_device && control_device.deviceHardId === device.deviceHardId) {
      let mount = this.mount.list.find((item) => item.name === data.gimbalName)
      if (mount) {
        let find_item = this.uav.control.mounts.find((item) => item.name === data.gimbalName)
        if (!find_item) {
          this.uav.control.mounts.push({
            ...data,
            ...mount
          })
        }
      }
    }
  },
  update_mounts(device, data) {
    this.uav.control.mounts = data
    this.mount.list.forEach((item, i) => {
      this.uav.control.mounts.forEach((val, j) => {
        if (val.mountName === item.name) {
          val.name = item.name
          val.icon = item.icon
          val.component = item.component
        }
      })
    })
  },
  change_mount(mount) {
    this.$set(this.uav.control, 'mount', mount)
  },
  mount_directive(data) {
    let mount = this.uav.control.mount

    if (mount) {
      this.uav_mqtt_fn({
        type: 514,
        data: {
          mountId: mount.mountId,
          payload: data
        }
      })
    }
  },
  move_directive(data) {
    if (data.mountId && data.data) {
      if (this.wsShow) {
        let sendData = {
          type: 200,
          systemCode: 'mmc',
          state: 1,
          username: this.user_info && this.user_info.username,
          height: 0,
          idlingTime: 0,
          data: {
            cmdControlType: 7000,
            cmdFunction: null,
            cmdState: null,
            cmdValue: null,
            cmdBuffer: data.data.join(','),
            cmdName: data.name
          },
          deviceHardId: data.deviceHardId
        }
        this.uav_send_ws(sendData)
      } else {
        this.uav_mqtt_fn({
          type: 514,
          data: {
            mountId: data.mountId,
            payload: data.data
          }
        })
      }
    }
  },
  /**
   * 切换无人机模式
   * { type: 513, data: mode }
   * @param {*} data
   */
  change_mode(mode) {
    this.uav_mqtt_fn({ type: 513, data: mode.data })
  },

  update_org_status(cate, id) {
    let list = tree2list_org(this.uav.list)
    let org_id = id
    while (org_id) {
      let org = list.find((x) => x.id === org_id)
      if (org) {
        if (cate === 1) {
          org.offline -= 1
          org.online += 1
        }
        if (cate === 0) {
          org.offline += 1
          org.online -= 1
        }
        org_id = org.pid
      } else {
        org_id = null
      }
    }
  },

  /**
   * cate { -1: 离线, 1: 上线 }
   * @param {*} cate
   * @param {*} deviceHardId
   */
  update_device_report_status(cate, deviceHardId) {
    let devices = tree2list(this.uav.list)
    let did = this.uav.control.device && this.uav.control.device.deviceHardId
    devices.map((item) => {
      if (item.deviceHardId == deviceHardId) {
        if (item.reportStatus !== cate) {
          item.reportStatus = cate
          if (cate === 1) {
            this.uav_update_org_status(1, item.org_id)
          } else if (cate == 0) {
            this.uav_update_org_status(0, item.org_id)
            item._control = false
            // // 视频推流状态关闭
            // item._videoStatus = 0
            if (did && did == deviceHardId) {
              this.uav.control = {}
            }
          }
        }
      }
    })
  },

  /**
   * 无人机 mqtt 发送指令
   * @param {*} data
   */
  mqtt_fn(data, type, device = this.uav.control.device) {
    if (device) {
      let uavCate = device.cateName == 'px4' ? 'PX4' : 'APM'
      // let uavCate = device.cateName == 'PX4'
      let topic = `${uavCate}/OBTAIN/${device.deviceHardId}`
      this.mqtt_publish(topic, data, (e) => {
        // console.log('发送指令', data, 'topic', topic);
      })
    }
  }
}
