import API from '@/api'
import { Utils } from '@/lib/cesium'
let show_areas = []

export default {
  async handle_show (item) {
    let cesium_layer = this.g_cesium_layer()
    let viewer = cesium_layer.viewer()
    viewer.trackedEntity && (viewer.trackedEntity = undefined);
    viewer.scene.globe.show = true;
    viewer.scene.skyAtmosphere.show = true;
    viewer.scene.globe.showGroundAtmosphere = true;
    window.plate_data_source && (window.plate_data_source.show = false);
    let positions = item.regionList
    let position = Utils.transformWGS842Cartesian({
      lng: Number(positions[0]),
      lat: Number(positions[1]),
      alt: 10000,
    })
    viewer.camera.flyTo({
      destination: position,
    })
    this.airspace_init_location_polygon(item.regionType, positions, item)
  },
  async handle_hide (item) {
    let cesium_layer = this.g_cesium_layer()
    let viewer = cesium_layer.viewer()
    show_areas.forEach(val => {
      if (val.id == item.id) {
        viewer.entities.remove(val)
      }
    })
  },
  init_location_polygon (regionType, positions, item) {
    // console.log(regionType, 'regionType');
    let cesium_layer = this.g_cesium_layer()
    let viewer = cesium_layer.viewer()

    if (regionType == 'CIRCLE') {
      let location_polygon = viewer.entities.add({
        position: Cesium.Cartesian3.fromDegrees(positions[0], positions[1]),
        ellipse: {
          semiMajorAxis: positions[2],
          semiMinorAxis: positions[2],
          material:
            item.typeId == 2
              ? Cesium.Color.GREEN.withAlpha(0.5)
              : Cesium.Color.RED.withAlpha(0.5),
        },
        show: true,
        id: item.id,
      })
      show_areas.push(location_polygon)
    }
    if (regionType == 'POLYGON') {
      let location_polygon = viewer.entities.add({
        polygon: {
          hierarchy: {
            positions: Cesium.Cartesian3.fromDegreesArray(positions),
          },
          // height: 50000,
          outline: true,
          outlineColor: Cesium.Color.WHITE,
          outlineWidth: 2,
          material:
            item.typeId == 2
              ? Cesium.Color.GREEN.withAlpha(0.5)
              : Cesium.Color.RED.withAlpha(0.5),
        },
        show: true,
        id: item.id,
      })
      show_areas.push(location_polygon)
    }
  },
}
