<!-- 实时点调 -->
<template>
  <div class="main">
    <div class="head">
      <div class="title">呼叫应答</div>
      <div class="more" @click="btn_point">
        <span>更多</span>
        <img src="~@/assets/zong_lan_ye_img/jiantou_more.png" />
      </div>
    </div>
    <div class="nei">
      <div class="flex call">
        <div id="callChart">
        </div>
        <!-- 正常 -->
        <div class="card card1">
          <div class="text">
            <span class="num">300</span>
            <span class="name">正常</span>
          </div>
        </div>
        <div class="card card2">
          <div class="text">
            <span class="num">200</span>
            <span class="name">异常</span>
          </div>
        </div>
      </div>


      <div class="header_file flex" v-if="false">
        <div>序号</div>
        <div>单位</div>
        <div>完成状态</div>
      </div>
      <div class="Table_Properties" id="heziliu" @mouseover="mouseOver" @mouseleave="mouseLeave">
        <!-- 模板 -->
        <div class="kuang" v-for="(item, index) in toneList" :key="index">
          <div>{{ index + 1 }}</div>
          <div class="changdu">{{ item.orgName }}</div>
          <div class="status" v-if="item.isTimely == 0" style="color: #dfe0e2">
            <span class="point point-color-red"></span>
            {{ '未响应' }}
          </div>
          <div class="status" v-else-if="item.isTimely == 1" style="color: #00d45c">
            <span class="point point-color-green"></span>{{ '正常' }}
          </div>
          <div class="status" v-else style="color: #ffbd36">{{ '超时' }}</div>
        </div>
        <!-- 模板 -->
        <p v-if="toneList.length == 0" style="text-align: center; color: #fff">
          暂无数据
        </p>
      </div>
    </div>
    <!-- 四个角标 -->
    <div class="corn corn1"></div>
    <div class="corn corn2"></div>
    <div class="corn corn3"></div>
    <div class="corn corn4"></div>
  </div>
</template>

<script>
import API from '@/api'
export default {
  data() {
    return {
      search: {
        pageNo: 1,
        pageSize: 10,
      },
      pagination: {
        pageNo: 1,
        pageSize: 10,
      },
      pickTime: null,
      toneList: [],
      times: null, //定时器
      newTop: 0, //位置
      oldTop: 0, //老位置
      total: 500,
    }
  },
  mounted() {
    this.getUnitList()
    this.abc()
    this.initEchart();
  },
  methods: {
    // 滚动条滚动效果
    abc() {
      let box = document.getElementById('heziliu')
      this.times = setInterval(function () {
        this.oldTop = box.scrollTop
        //获得老位置
        this.newTop = this.oldTop + 1
        //得到新位置
        box.scrollTop = this.newTop
        //将计算的新位置进行赋值，使滚动条移动
        // 到底重来一遍
        if (this.newTop + box.clientHeight > box.scrollHeight) {
          box.scrollTop = 0
        }
      }, 50)
      //循环时间
    },
    // 鼠标悬停
    mouseOver() {
      clearInterval(this.times)
    },
    //鼠标离开
    mouseLeave() {
      this.abc()
    },
    async getUnitList() {
      if (this.pickTime) {
        this.search.start = this.pickTime[0]
        this.search.end = this.pickTime[1]
      } else {
        this.search.start = null
        this.search.end = null
      }

      let sceneConfigData = window.localStorage.getItem('sceneConfigData');
      sceneConfigData = JSON.parse(sceneConfigData);

      let res = await API.TONE.getItemInfoListById()
      if (
        sceneConfigData &&
        sceneConfigData['pointData'] &&
        sceneConfigData['pointData'].length > 0
      ) {
        this.toneList = sceneConfigData['pointData']
      } else {
        this.toneList = res.data || []
      }
    },
    // 跳向实时点调  跳转到UU跑腿飞行监控界面，先到订单任务界面
    btn_point() {
      // let { href } = this.$router.resolve({ path: '/observe_uu/home?page=1' })
      // window.open('#/taskOrder_uu', '_self')
      // window.open("#/taskOrder_uu", "_self");
      // this.$router.go(0);
      // // return;
      // // 跳向信息管理-实时调度-实时调度
      let { href } = this.$router.resolve({
        path: '/command?page=5',
        query: {
          type: '调度中心',
          item: JSON.stringify({ id: 5, title: '实时调度' }),
        },
      })
      localStorage.setItem("infoPage", 5);
      window.open(href, '_blank')
      // window.open(href, '_self');
      this.$router.go(0);
      // // this.$router.push();
    },
    // 绘制图表
    initEchart() {
      //
      let dom = document.getElementById('callChart')
      let chart = this.$echarts.init(dom)
      let color = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: '#02FFD3', // 0% 处的颜色
        },
        {
          offset: 1,
          color: '#0077F5', // 100% 处的颜色
        },
      ]);
      let option = {
        backgroundColor: 'rgba(0,0,0,0)',
        title: {
          zlevel: 0,
          text: `${this.total}次\r\n\r\n总数`,
          top: '38%',
          left: '39%',
          textAlign: 'center',
          textStyle: {
            color: '#ffffff',
            fontSize: 8,
          },
        },
        series: [
          //  花瓣
          {
            type: 'gauge',
            center: ['40%', '50%'],
            startAngle: 0,
            endAngle: 360,
            splitNumber: 5,
            radius: '80%',
            pointer: {
              show: false,
            },
            progress: {
              show: false,
              roundCap: true,
              width: 0,
            },
            axisLine: {
              show: false,
              lineStyle: {
                width: 0,
                color: [
                  [0.4, color],
                  [1, color],
                ],
              },
            },
            axisTick: {
              distance: -25,
              length: 8,
              splitNumber: 5,
              lineStyle: {
                width: 3,
                color: 'auto',
                cap: 'round'
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10,
              lineStyle: {
                width: 6,
                color: '#099fe4',
              },
            },
            axisLabel: {
              show: false,
            },
            anchor: {
              show: false,
            },
            title: {
              show: false,
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 100,
              }
            ]
          },
          // 外圈
          {
            data: [
              { show: false, value: "100" }
            ], //作用不清楚
            name: "", //刻度背景
            type: "gauge",
            center: ['40%', '50%'],
            radius: "55%",
            startAngle: "0",
            endAngle: "-359.99",
            axisLine: {
              // 坐标轴线
              lineStyle: {
                // 属性lineStyle控制线条样式
                color: [[1, "#415A73"]],
                width: 4,
                opacity: 1, //刻度背景宽度
              },
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            pointer: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            detail: {
              show: 0,
            },
          },
          //   实际数值
          {
            data: [
              { show: false, value: 30 }
            ], //作用不清楚
            name: "", //刻度背景
            type: "gauge",
            center: ['40%', '50%'],
            radius: "60%",
            min: 0,
            max: 360,
            startAngle: 90,
            endAngle: -269.9999,
            axisLine: {
              show: true,
              lineStyle: {
                width: 8,
                color: [
                  [0.5, color],
                  [1, 'rgba(0, 0, 0, 0)'],
                ],
              },
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            pointer: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            detail: {
              show: 0,
            },
          },
          //     最大的外圈
          {
            name: "外层细线",
            z: 0,
            type: 'custom',
            coordinateSystem: "none",
            renderItem: function (params, api) {
              return {
                type: 'arc',
                shape: {
                  cx: api.getWidth() / 2.5,
                  cy: api.getHeight() / 2,
                  r: Math.min(api.getWidth(), api.getHeight()) / 2 * 0.95,
                  startAngle: 0,
                  endAngle: 360
                },
                style: {
                  stroke: 'rgb(105,206,195)',
                  fill: "transparent",
                  lineWidth: 0.5
                },
                silent: true
              };
            },
            data: [0]
          },
          // 外圈刻度

          {
            name: "刻度间隔",
            z: 2,
            type: "gauge",
            radius: '100%',
            center: ['40%', '50%'],
            startAngle: 0,
            endAngle: 359.9,
            splitNumber: 4,
            hoverAnimation: true,
            axisTick: {
              show: true,
              splitNumber: 1,
              length: 0,
              lineStyle: {
                width: 40,
                color: '#1C4A5D'
              }
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: false
            },
            axisLine: {
              lineStyle: {
                opacity: 0,
              }
            },
            detail: {
              show: false
            },
            data: [100]
          },
        ]
      };
      chart.setOption(option)

      window.addEventListener('resize', () => {
        chart.resize()
      })
    },
  },
}
</script>

<style scoped lang="scss">
.flex {
  display: flex;
}

.main {
  width: 100%;
  height: 100%;
  background-color: rgba(35, 47, 79, 0.75);
  border-radius: 12px;
  color: #fff;
  font-family: PingFangSC-Semibold, PingFang SC;
  background: linear-gradient(360deg, #14203E 0%, rgba(28, 74, 93, 0.91) 100%);
  position: relative;

  // 四个角标
  .corn {
    width: 22px;
    height: 20px;
    //border: 1px solid #00F5FF;
    position: absolute;
  }

  .corn1 {
    top: 0;
    left: 0;
    background-image: url("~@/assets/img/corn1.png");
    background-repeat: no-repeat;
  }

  .corn2 {
    top: 0;
    right: 0;
    background-image: url("~@/assets/img/corn1.png");
    background-repeat: no-repeat;
    transform: rotate(90deg);
    //back
  }

  .corn3 {
    bottom: 0;
    left: 0;
    background-image: url("~@/assets/img/corn3.png");
    background-repeat: no-repeat;
  }

  .corn4 {
    bottom: 0;
    right: 0;
    background-image: url("~@/assets/img/corn3.png");
    background-repeat: no-repeat;
    transform: rotate(-90deg);
  }
}

.head {
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  //padding: 0 12px 0 12px;
  align-items: center;
  //background-image: url('~@/assets/zong_lan_ye_img/kuang_title_bg.png');
  background-repeat: no-repeat;
  background-size: 274px 42px;
  border-bottom: 1px solid #474772;

  .title {
    font-size: 18px;
    line-height: 28px;
    margin-left: 14px;
    font-weight: 600;
    height: 100%;
    width: 30%;
    background-image: url("~@/assets/img/title.png");
    background-repeat: no-repeat;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #FFFFFF;
    display: flex;
    align-items: center;
  }

  .more {
    font-size: 14px;
    line-height: 25px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;

    img {
      margin-left: 3px;
      width: 11px;
      height: 17px;
    }
  }
}

.nei {
  width: 100%;
  height: calc(100% - 42px);
  box-sizing: border-box;
  padding: 3px 16px 12px 16px;
  margin: 0 0 8px 0;

  .header_file {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px 0 16px;
    height: 35px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #ffffff;
    line-height: 17px;
  }

  .Table_Properties {
    width: 100%;
    //height: calc(100% - 35px);
    height: 45%;
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;

    .kuang:nth-child(odd) {
      background: rgba(99, 177, 245, 0.09);
      border: 1px solid rgba(84, 147, 215, 0.37);
    }

    .kuang:nth-child(even) {
      background: transparent;
    }
  }

  .kuang {
    width: 100%;
    height: 30px;
    padding: 0 19px 0 28px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }

  .changdu {
    width: 200px;
    // width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }

  .status {
    // width: 52px;
    width: auto;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .point {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-right: 3px;
  }

  .point-color-green {
    background: #19e210;
  }

  .point-color-red {
    background: #ff7362;
  }

  .call {
    height: 55%;
    width: 100%;
    //background-color: #0cafff;
    justify-content: space-around;
    align-items: center;
    position: relative;

    #callChart {
      width: 100%;
      height: 100%;
    }

    .card {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      height: 30%;
      width: 30%;

      .text {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-left: 15px;

        .num {
          font-size: 16px;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          color: #FFFFFF;
        }

        .name {
          font-size: 12px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #557899;
          margin-top: 5px;
        }
      }
    }

    .card1 {
      top: 10%;
      right: 10%;

    }

    .card2 {
      top: 55%;
      right: 10%;

      //border: 1px solid red;
      .num {
        color: #ff0000 !important;
      }
    }
  }
}
</style>
