<!-- 气象条件 -->
<template>
  <div class="meteorological_condition">
    <div class="pup_head" v-interact>
      <div class="flex">
        <div class="pup_img">
          <!-- <img src="~@/assets/zong_lan_ye_img/jinye.png" style="width: 100%; height: 100%" alt="" /> -->
        </div>
        <div class="fontOne">{{ year }}&nbsp;{{ period }}</div>
      </div>
      <div class="fontTwo" @click="pupClose">
        <img src="@/assets/img/i40.png" alt="" class="img">
      </div>
    </div>
    <div class="nei">
      <div class="kuang flex">
        <div class="same_day">
          <div class="same_day_img">
            <img :src="weatherIcon" style="width: 100%; height: 100%" alt="" />
          </div>
          <div class="flex mb16">
            <div class="fontThree">{{ weatherList[0].temp || 30 }}</div>
            <div class="font">
              <div class="fontFour">˚C</div>
              <div class="fontFive">
                {{ weatherList[0].conditionDay || "晴" }}(当天)
              </div>
            </div>
          </div>
          <div class="fontSeven">
            {{ weatherList[0].minTemp || 20 }}~{{
              weatherList[0].maxTemp || 30
            }}˚C
          </div>
          <div class="fontSeven mt5">
            {{ weatherList[0].conditionDay || "晴" }}天
          </div>
          <div class="fontSeven mt5">
            {{ weatherList[0].windDirDay || "东北风" }}
            {{ judgment(weatherList[0].windLevelDay) }}
            {{ weatherList[0].windLevelDay }}级
          </div>
          <!-- <div class="fontSeven mt5 icB">{{ "21优" }}</div> -->
        </div>
        <div class="flex ml21 pinfen">
          <!-- 模板 -->
          <div class="same" v-for="(item, index) in houFive" :key="index">
            <div class="fontEight">{{ week(futureWeek(nian, index + 1)) }}</div>
            <div class="fontEight mt5">{{ futuredate(nian, index + 1) }}</div>
            <div class="xian mt5"></div>
            <div class="img_same mt12">
              <img :src="stylefive(item)" style="width: 100%; height: 100%" />
            </div>
            <div class="fontSeven mt9">
              {{ item.minTemp || 20 }}~{{
                item.maxTemp || 30
              }}˚C
            </div>
            <div class="fontSeven mt5">
              {{ item.conditionDay || "晴" }}天
            </div>
            <div class="fontSeven mt5">
              {{ item.windDirDay || "东北风" }}
              {{ judgment(item.windLevelDay) }}
              {{ item.windLevelDay }}级
            </div>
            <!-- <div class="fontSeven mt5 icB">{{ "优" }}</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import { mapGetters } from "vuex";
export default {
  data () {
    return {
      year: null, //年
      period: null, //星期几
      weatherList: [], //天气状态
      weatherIcon: require("@/assets/images/weather/icon/day/00.png"), //天气状态图片
      houFive: [], //后5天
      five: null, //未来五天的日期
      nian: null,//当前年月日
    };
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  async mounted () {
    await this.timer();
    await this.threeWeather();
  },
  methods: {
    async timer () {
      let getDate = await API.WEATHER.getDate();
      console.log(getDate, "getDategetDategetDate");
      let a = getDate.split(" ");
      // 时分
      let b = a[1];
      let c = b.split(":");
      // 年月
      let d = a[0];
      // console.log(d,'ddddddddddddddddd');
      let e = d.split("-");
      this.nian = e[0] + '-' + e[1] + "-" + e[2];
      // console.log(this.nian,'this.nianthis.nian');
      this.year = e[1] + "月" + e[2] + "日";
      this.period = this.week(d);
    },
    // 星期几
    week (time) {
      let weekNum = new Date(time).getDay();
      let week = "";
      switch (weekNum) {
        case 0:
          week = "星期天";
          break;
        case 1:
          week = "星期一";
          break;
        case 2:
          week = "星期二";
          break;
        case 3:
          week = "星期三";
          break;
        case 4:
          week = "星期四";
          break;
        case 5:
          week = "星期五";
          break;
        case 6:
          week = "星期六";
          break;
      }
      return week;
    },
    // 天气
    // 返回6天天气
    async threeWeather () {
      let organId = { organId: this.user_info["departmentId"] };
      let res = await API.WEATHER.getNewWeather(organId);
      if (res.length > 0) {
        this.weather = res[0];
      }
      this.weatherList = res;
      res.forEach((item, index) => {
        if (index != 0) {
          this.houFive.push(item);
        }
      });
      // console.log(this.weatherList, "this.weatherListthis.weatherList");
      // console.log(this.houFive,'this.houFive');
      const date = new Date();
      let time = date.getHours();
      this.weather.conditionDay =
        time >= 18
          ? this.weather.conditionNight
          : this.weather.conditionDay;
      this.weatherIcon = require(`@/assets/images/weather/icon/iconfont/W${time >= 18 ? this.weather.nightIcon : this.weather.dayIcon
        }.png`);
    },
    // 后五天的图标样式
    stylefive (item) {
      const date = new Date();
      let time = date.getHours();
      // item.conditionDay =
      //   time >= 18
      //     ? item.conditionNight
      //     : item.conditionDay;
      return require(`@/assets/images/weather/icon/iconfont/W${time >= 18 ? item.nightIcon : item.dayIcon
        }.png`);
    },
    // 未来星期
    futureWeek (data, day) {
      var dd = new Date(data)
      dd.setDate(dd.getDate() + day)
      let y = dd.getFullYear()
      var m = dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1
      var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate()
      return y + '-' + m + '-' + d
    },
    // 未来日期
    futuredate (data, day) {
      var dd = new Date(data)
      dd.setDate(dd.getDate() + day)
      let y = dd.getFullYear()
      var m = dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1
      var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate()
      return m + '月' + d + '日'
    },
    // 判断大于或者小于三级风
    judgment (num) {
      if (num > 3) {
        return ">";
      } else if (num < 3) {
        return "<";
      } else if (num == 3) {
        return "=";
      } else {
        return ">";
      }
    },
    // 关闭弹框
    pupClose () {
      this.$emit("handleClose", "meteorologicalCondition");
    },
  },
};
</script>

<style scoped lang="scss">
.meteorological_condition {
  width: 760px;
  // height: 310px;
  // background: rgba(12, 34, 73, 0.7);
  // border-radius: 10px;
  // border: 1px solid rgba(26, 92, 246, 0.5);
  // backdrop-filter: blur(1px);
  position: fixed;
  top: 10%;
  left: 28%;
  box-sizing: border-box;
  background-color: #0A293A;
}

.flex {
  display: flex;
}

.pup_head {
  // width: 100%;
  // display: flex;
  // justify-content: space-between;
  // box-sizing: border-box;
  // padding: 0 16px 0 12px;
  // align-items: center;
  // height: 32px;
  // background: linear-gradient(180deg,
  //     #9198ff 0%,
  //     rgba(45, 81, 153, 0.45) 40%,
  //     #05091a 100%);
  // box-shadow: inset 0px 0px 10px 2px #3f9dff;
  // border-radius: 10px 10px 0px 0px;
  // border: 1px solid #427dff;
  height: 36px;
  background-color: #224D68;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 5px 10px 5px 10px;

  .pup_img {
    // display: flex;
    // width: 19px;
    // height: 25px;
    // margin: 0 4px 0 0;
  }

  .fontOne {
    // font-size: 20px;
    // font-family: YouSheBiaoTiHei;
    // color: #14faff;
    // line-height: 26px;
    // text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
    // background: linear-gradient(135deg,
    //     #e3aa77 0%,
    //     #f5cda9 38%,
    //     #f9ecd3 58%,
    //     #fcdbb1 79%,
    //     #edb07a 100%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    font-size: 16px;
    color: #ffffff;
    font-weight: bold;
  }

  .fontTwo {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #d2dfff;
    cursor: pointer;

    .img {
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
  }
}

.nei {
  width: 100%;
  height: calc(100% - 32px);
  box-sizing: border-box;
  padding: 19px 12px 19px 12px;
}

.kuang {
  width: 100%;
  // height: 240px;
  // background: rgba(8, 26, 58, 0.8);
  border-radius: 4px;
  // border: 1px solid #315ec7;
  box-sizing: border-box;
  padding: 3px 23px 10px 12px;

  .same_day {
    width: 125px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .same_day_img {
      width: 55px;
      height: 55px;
      margin: 0 0 12px 0;
    }
  }

  .pinfen {
    flex: 1;
    justify-content: space-between;
  }

  .same {
    width: 96px;
    height: 100%;
    box-sizing: border-box;
    padding: 5px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .xian {
    width: 61px;
    height: 0.5px;
    opacity: 0.5;
    border: 1px solid;
    border-image: linear-gradient(270deg,
        rgba(117, 175, 227, 0),
        rgba(117, 175, 227, 1),
        rgba(117, 175, 227, 0) 1 1);
  }

  .img_same {
    width: 62px;
    height: 62px;
  }
}

.font {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 0 3px;
}

.icB {
  width: 46px;
  height: 22px;
  background: #20b91a;
  border-radius: 4px;
  text-align: center;
  line-height: 22px;
}

.fontThree {
  font-size: 42px;
  font-family: D-DIN-Bold, D-DIN;
  font-weight: bold;
  color: #fff;
}

.fontFour {
  font-size: 16px;
  font-family: Helvetica;
  color: #fff;
}

.fontFive {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
}

.fontSeven {
  font-size: 16px;
  font-family: D-DIN D;
  color: #fff;
  font-weight: normal;
}

.fontEight {
  font-size: 14px;
  font-weight: bold;
  color: #a5d0ec;
  font-family: YouSheBiaoTiHei-Bold, MicrosoftYaHei;
}
</style>