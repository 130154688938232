<!-- 报表统计 -->
<template>
  <div class="cpt-command-flight-task">
    <div class="header" v-interact>
      <div class="left">
        <div class="icon">
          <img src="@/assets/zong_lan_ye_img/frame/icon.png" alt="" />
        </div>
        <div class="hd-box">报表统计</div>
      </div>
      <div class="right">
        <div class="icon" @click="export_list">
          <el-tooltip content="导出" placement="bottom">
            <img src="@/assets/zong_lan_ye_img/frame/export.png" alt="" />
          </el-tooltip>
        </div>
        <div class="hl-box cp mr20" @click="$emit('handleClose')">关闭</div>
      </div>
    </div>
    <div>
      <div class="search-box">
        <!-- 模糊查询 -->
        <el-date-picker
          v-model="date"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions"
          @change="handle_change"
        >
        </el-date-picker>
        <div style="display: flex">
          <div class="task_nav">
            <div
              v-for="item in nav_list"
              :key="item.id"
              @click="
                () => {
                  (active = item.id), (date = []);
                }
              "
              class="task_nav_box"
              :class="active == item.id ? 'active' : ''"
            >
              <img :src="item.img" />
              {{ item.name }}
            </div>
          </div>
        </div>
        <div @click="get_list" class="btn">搜索</div>
      </div>
      <SituationList ref="SituationList" v-if="active == 1" />
      <NormalList ref="NormalList" v-if="active == 2" />
      <UrgentList ref="UrgentList" v-if="active == 3" />
    </div>
    <!-- <div class="task_nav">
          <div
            v-for="item in nav_list"
            :key="item.id"
            @click="active = item.id"
            class="task_nav_box"
            :class="active == item.id ? 'active' : ''"
          >
            {{ item.name }}
          </div>
        </div> -->
  </div>
</template>
  
  <script>
import SituationList from "./situation";
import NormalList from "./normal";
import UrgentList from "./urgent";
import {options} from "./data/index.js"
 
export default {
  components: {
    SituationList,
    NormalList,
    UrgentList,
  },
  data() {
    return {
      roleId: JSON.parse(localStorage.getItem("user_info")).data.roles[0]
        .roleId,
      active: 1,
      nav_list: [],
      date: null,
      pickerOptions:options,
    };
  },

  methods: {
    handle_change(e) {
      if (this.active == 1) {
        this.$refs.SituationList.handle_change(e);
      } else if (this.active == 2) {
        this.$refs.NormalList.handle_change(e);
      } else if (this.active == 3) {
        this.$refs.UrgentList.handle_change(e);
      }
    },
    get_list() {
      if (this.active == 1) {
        this.$refs.SituationList.get_list();
      } else if (this.active == 2) {
        this.$refs.NormalList.get_list();
      } else if (this.active == 3) {
        this.$refs.UrgentList.get_list();
      }
    },
    export_list() {
      if (this.active == 1) {
        this.$refs.SituationList.export_list();
      } else if (this.active == 2) {
        this.$refs.NormalList.export_list();
      } else if (this.active == 3) {
        this.$refs.UrgentList.export_list();
      }
    },
    handlePosition(item) {
      this.$emit("handleLocation", item);
    },
    getnavlist() {
      if (this.roleId == 7 || this.roleId == 1) {
        this.nav_list = [
          {
            name: "报表统计",
            id: 1,
            img: require("@/assets/zong_lan_ye_img/frame/all.png"),
          },
          {
            name: "飞行检查记录",
            id: 2,
            img: require("@/assets/zong_lan_ye_img/frame/check.png"),
          },
          {
            name: "设备飞行记录",
            id: 3,
            img: require("@/assets/zong_lan_ye_img/frame/drone.png"),
          },
        ];
      } else {
        this.nav_list = [
          {
            name: "飞行检查记录",
            id: 2,
            img: require("@/assets/zong_lan_ye_img/frame/check.png"),
          },
        ];
        this.active = 2;
      }
    },
  },

  mounted() {
    this.getnavlist();
  },
};
</script>
  
  <style lang="scss" scoped>
.cpt-command-flight-task {
  @import "~@/styles/flightTask.scss";
  position: fixed;
  left: 27%;
  top: 200px;
  z-index: 0;
  width: 897px;
  height: 410px;
  box-sizing: border-box;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 13px;
  .header {
    display: flex;
    justify-content: space-between;
    height: 40px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;
    .left,
    .right {
      display: flex;
      align-items: center;
    }
  }

  .task_nav {
    padding: 0 20px;
    display: flex;
    align-items: center;
    .task_nav_box {
      // width: 140px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      height: 32px;
      cursor: pointer;
      text-align: center;
      line-height: 32px;
      font-family: MicrosoftYaHei-Bold;
      font-size: 14px;
      text-align: center;
      // font-weight: 700;
      font-size: 12px;
      color: #d2d9ff;
      background: #02173D;
box-shadow: inset 0px 0px 3px 1px rgba(38,71,238,0.7);
border-radius: 1px;
border: 1px solid #315EC7;
margin-left: 2px;

      &:hover {
        // color: rgba(8, 255, 255, 0.8);
      }
    }
    .active {
      background: url("~@/assets/zong_lan_ye_img/frame/bbbg.png") no-repeat;
      background-size: 100%;
      box-shadow: inset 0 0 10px 0 #1884d9;
      border-radius: 4px;
      color: #fff;
    }
  }
  .btn {
    cursor: pointer;
    text-align: center;
    line-height: 32px;
    background: url("~@/assets/zong_lan_ye_img/frame/btbg.png");
    width: 64px;
    height: 32px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #d2d9ff;
  }
  .cpt-command-flight-task-explain {
    display: flex;
    align-items: center;
    margin-left: 4px;
    // margin-top: 8px;
    padding: 0 20px;
    .explain_title {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #08c2d1;
    }
    .explain_box {
      display: flex;
    }
    .explain_box_detail {
      margin-right: 15px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #ffff;
    }
  }

  .hd-box {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    // color: #00ffff;
    background-image: -webkit-linear-gradient(
      right,
      #e3aa77,
      #f5cda9,
      #f9ecd3,
      #fcdbb1,
      #edb07a
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 0;
    font-weight: 700;
    margin-left: 4px;
  }
  .hl-box {
    font-size: 18px;
    font-family: MicrosoftYaHei;
    color: #d2dfff;
    margin-left: 24px;
    margin-top: -5px;
  }
}
/deep/.el-input__inner {
  background: rgba(2, 31, 51, 0.7);
  border: 1px solid #315ec7;
  border-radius: 4px;
}
/deep/ .el-date-editor .el-range-input {
  background: rgba(2, 31, 51, 0.1);
  width: 390px;
  color: #fff;
}
/deep/.el-range-separator {
  color: #fff;
}
</style>
  