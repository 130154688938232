import task from "./task"
import pilot from "./pilot"
import device from "./device"
import res from "./res"
import factor from "./factor"
import department from "./department"
import uav from "./uav"

export default {
  task,
  pilot,
  device,
  res,
  factor,
  department,
  uav,
}