<!-- 问题kuai -->
<template>
    <div class="approval">
        <div class="pup_head">
            <div class="flex">
                <div class="pup_img">
                    <img src="~@/assets/zong_lan_ye_img/jinye.png" style="width:100%;height:100%;" alt="">
                </div>
                <div class="fontOne">航线审批</div>
            </div>
            <div class="fontTwo" @click="pupClose">关闭</div>
        </div>
        <div class="nei">
            <div class="kuang">
                <div class="flex tb">
                    <div>序号</div>
                    <div class="tb_tr">航线名称</div>
                    <div>任务名称</div>
                    <div>任务类型</div>
                    <div>执行状态</div>
                    <div>预览航线</div>
                    <div>待办审批</div>
                </div>
                <div class="th">
                    <div class="th_tr flex" v-for="(item,index) in airspace_list" :key="index">
                        <div>{{ index + 1 }}</div>
                        <div class="th_td">{{ item.taskTitle }}</div>
                        <div>{{ '道路巡逻' }}</div>
                        <div>{{ item.typeName }}</div>
                        <div>{{ '待审批' }}</div>
                        <div>
                            <div class="btn_yu">预览</div>
                        </div>
                        <div>
                            <div class="btn_shen" @click="btn_app">审批</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import API from "@/api";
export default {
    data() {
        return {
            airspace_list:[],
        }
    },
    mounted() {

    },
    methods: {async get_arplist() {
      let res = await API.TASK.ListAll({
        // type:null,
        dateType: 2,
      });
      // if (res) {
        this.airspace_list = res.taskList.concat(res.lineList)
        this.airspace_list.forEach(val=>{
          if(val){}
        })
        console.log(this.airspace_list,'航线审批');
      // }
    },
        //审批弹窗
        btn_app(){
            this.$emit('pup', 102)
        },
        pupClose() {
            this.$emit('handleClose', 'approval')
        },
    }
}
</script>

<style  scoped lang="scss">
.approval {
    width: 672px;
    height: 274px;
    background: rgba(12, 34, 73, 0.7);
    border-radius: 10px;
    border: 1px solid rgba(26, 92, 246, 0.5);
    backdrop-filter: blur(1px);
}

.flex {
    display: flex;
}

.pup_head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 16px 0 12px;
    align-items: center;
    height: 32px;
    background: linear-gradient(180deg, #9198FF 0%, rgba(45, 81, 153, 0.45) 40%, #05091A 100%);
    box-shadow: inset 0px 0px 10px 2px #3F9DFF;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427DFF;

    .pup_img {
        display: flex;
        width: 19px;
        height: 25px;
        margin: 0 4px 0 0;
    }

    .fontOne {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14FAFF;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(135deg, #E3AA77 0%, #F5CDA9 38%, #F9ECD3 58%, #FCDBB1 79%, #EDB07A 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .fontTwo {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #D2DFFF;
        cursor: pointer;
    }
}

.nei {
    width: 100%;
    box-sizing: border-box;
    padding: 12px;

}

.kuang {
    width: 647px;
    height: 218px;
    background: rgba(8, 26, 58, 0.8);
    border-radius: 4px;
    border: 1px solid #315EC7;
    box-sizing: border-box;
    padding: 4px;

    .tb {
        width: 639px;
        height: 42px;
        box-sizing: border-box;
        padding: 0 24px 0 16px;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-family: YouSheBiaoTiHei;
        color: #EBF9FF;
        letter-spacing: 1px;

        .tb_tr {
            width: 146px;
            text-align: center;
        }
    }

    .th {
        width: 100%;
        height: 168px;
        overflow-y: auto;

        .th_tr:nth-child(odd) {
            background: rgba(73, 135, 210, 0.2);
        }

        .th_tr:nth-child(even) {
            background: transparent;
        }

        .th_tr {
            padding: 0 16px 0 24px;
            box-sizing: border-box;
            width: 100%;
            height: 42px;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #EBF9FF;

            .th_td {
                width: 146px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .btn_yu {
                width: 80px;
                height: 28px;
                text-align: center;
                line-height: 28px;
                font-size: 14px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #E0F6FF;
                background-image: url("~@/assets/zong_lan_ye_img/btn_lan.png");
                background-size: 100% 100%;
            }
            .btn_shen{
                width: 80px;
                height: 28px;
                text-align: center;
                line-height: 28px;
                font-size: 14px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #E0F6FF;
                background-image: url("~@/assets/zong_lan_ye_img/chakan.png");
                background-size: 100% 100%;
            }
        }
    }
}
</style>