import task from "./task"
import pilot from "./pilot"
import device from "./device"
import res from "./res"
import factor from "./factor"
import department from "./department"
import uav from "./uav"

function prefix (meta, prefix) {
  return Object.keys(meta).reduce((obj, key) => {
    obj[`${prefix}_${key}`] = meta[key]
    return obj
  }, {})
}

export default {
  ...prefix(task, "task"),
  ...prefix(pilot, "pilot"),
  ...prefix(device, "device"),
  ...prefix(res, "res"),
  ...prefix(factor, "factor"),
  ...prefix(department, "department"),
  ...prefix(uav, "uav"),
}