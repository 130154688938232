<template>
  <div class="cpt-command-task-situation">
    <div class="tb-box">
      <div class="tb-hd-box">
        <div class="tb-hd-triangle"></div>
        <div class="tb-hd">单位名称</div>
        <div class="tb-hd">无人机名称</div>
        <div class="tb-hd">在线时长</div>
        <!-- <div class="tb-hd">操作</div> -->
      </div>
      <div class="tb-bd-box">
        <div class="tb-tr" v-for="(item, index) in list" :key="index">
          <div class="td">{{ item.anotherName || "暂无" }}</div>
          <div class="td">{{ item.deviceName || "暂无" }}</div>
          <div class="td">{{ item.time || "暂无" }}</div>
        </div>
      </div>
    </div>
    <div class="tb-pagination ml20 mt8 mb10">
      <el-pagination
        small
        v-if="pageshow"
        :page-sizes="[10, 20, 30, 40]"
        :current-page.sync="pagination.pageNo"
        :page-size="pagination.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.totalCount"
        @size-change="handle_size_change"
        @current-change="handle_page_change"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { downloadData } from "@/utils/download";
import API from "@/api";
export default {
  computed: {
    ...mapGetters(["user_info"]),
    isJH() {
      return this.user_info && this.user_info.departmentId == 2;
    },
  },
  async mounted() {
    await this.get_list();
  },
  data() {
    return {
      icollapse: false,
      pageshow: true,
      curr_task: null,
      search: {
        taskTitle: null,
        orgId: null,
        type: null,
        executeStatus: null,
      },
      list: [],
      date: [],
      form: {},
      unitList: [],
      pagination: {
        pageNo: null,
        pageSize: 10,
        totalCount: 0,
      },
      timer: null,
    };
  },
  methods: {
    async get_list() {
      let { data, pageNo, pageSize, totalCount } = await API.HOME.flyrecord({
        ...this.pagination,
        ...this.form,
      });
      this.list = data;
      this.pagination.pageNo = pageNo;
      this.pagination.pageSize = pageSize;
      this.pagination.totalCount = totalCount;
    },
    export_list() {
      if (this.form.start == null && this.form.end == null) {
        this.$message.error("请选择日期");
      } else {
        let url = `${process.env.VUE_APP_BASE_URL}/hawksystemserver/organization/exportDairyWord?FLYINGSESSIONID=${this.user_info.FLYINGSESSIONID}&mmc-identity=${this.user_info["mmc-identity"]}&start=${this.form.start}&end=${this.form.end}`;
        window.open(url);
      }
    },
    handle_change(e) {
      if (e?.length) {
        this.form.start = e[0];
        this.form.end = e[1];
      } else {
        this.form.start = null;
        this.form.end = null;
      }
    },
    handle_size_change(size) {
      this.pagination.pageSize = size;
      this.get_list();
    },
    handle_page_change(page) {
      this.pagination.pageNo = page;
      this.get_list();
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-command-task-situation {
  @import "~@/styles/flightTask.scss";
  height: calc(100% - 40px);
  .btn-submit {
    margin-left: 40px;
    cursor: pointer;
  }
  .tb-box {
    padding: 0;
    margin: 0 20px;
    background: rgba(12, 34, 73, 0.7);
    border-radius: 6px;
    border: 1px solid rgba(26, 92, 246, 0.5);
    backdrop-filter: blur(1px);
    .tb-hd-box {
      background: rgba(8, 26, 58, 0.8);
      .tb-hd {
        font-size: 16px;
        font-family: YouSheBiaoTiHei;
        color: #ebf9ff;
        width: calc(100% / 3);
      }
      .last-tb-hd {
        width: 220px !important;
      }
    }
    .tb-bd-box {
      height: 210px;
      overflow: auto;
      .tb-tr {
        background: rgba(73, 135, 210, 0.2);
        .td {
          width: calc(100% / 3);
        }
        .last-td {
          width: 220px !important;
          .iconfont {
            margin-right: 7px;
          }
        }
        &:nth-of-type(2n) {
          background: #081a3a;
        }
      }
    }
  }
}
</style>
