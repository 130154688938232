<!-- 实时点调 -->
<template>
  <div class="main">
    <div class="head">
      <div class="title">任务调度</div>
      <div class="more" @click="btn_point">
        <span>更多</span>
        <img src="~@/assets/zong_lan_ye_img/jiantou_more.png" />
      </div>
    </div>
    <div class="nei">
      <div class="flex call">
        <div id="callChart"></div>
        <!-- 正常 -->
        <div class="card card1">
          <div class="text">
            <span class="num">{{ total }}</span>
            <span class="name">总单量</span>
          </div>
        </div>
        <div class="card card2">
          <div class="text">
            <span class="num">{{ dshCount }}</span>
            <span class="name">待审核量</span>
          </div>
        </div>
      </div>
      <div class="Table_Properties" id="heziliu" @mouseover="mouseOver" @mouseleave="mouseLeave">
        <!-- 模板 -->
        <div class="kuang" v-for="(item, index) in taskListForHomePageData" :key="index">
          <div class="line line1">
            <span class="n">{{ item.deliveryId || '' }}</span>
            <span class="t">{{ item.deliveryTime || '' }}</span>
          </div>
          <div class="line line2">
            <div class="i">
              <span>任务区域：{{ item.countyName || '' }}</span>
            </div>
            <div class="i">
              <span>任务来源：{{ item.fromCompany || '' }}</span>
            </div>
          </div>
          <div class="line line3">
            <div class="b">
              <div>{{ item.takeoffApronName || '' }}</div>
              <img src="@/assets/img/i149.png" alt="">
              <div>{{ item.landApronName || '' }}</div>
            </div>
            <div class="bt">
              <div v-if="item.taskStatus == -2" style="color: #FF9A0E; background: rgba(255,184,0,0.13);">待审核</div>
              <div v-else-if="item.taskStatus == -1" style="color: #FFDE6B; background: rgba(255,214,0,0.13);">待放件</div>
              <div v-else-if="item.taskStatus == 0" style="color: #19E210; background: rgba(0,255,163,0.13);">待配送</div>
              <div v-else-if="item.taskStatus == 3" style="color: #00FFC2; background: rgba(0,255,194,0.13);">配送中</div>
              <div v-else-if="item.taskStatus == 1" style="color: #4ECAFF; background: rgba(0,194,255,0.13);">已完成</div>
              <div v-else-if="item.taskStatus == 5" style="color: #E6FF4A; background: rgba(230,255,74,0.13);">待收货</div>
              <div v-else style="color: rgba(255,255,255,0.76); background: rgba(255,254,249,0.12);">已取消</div>
            </div>
          </div>
        </div>
        <!-- 模板 -->
        <p v-if="toneList.length == 0" style="text-align: center; color: #fff">
          暂无数据
        </p>
      </div>
    </div>
    <!-- 四个角标 -->
    <div class="corn corn1"></div>
    <div class="corn corn2"></div>
    <div class="corn corn3"></div>
    <div class="corn corn4"></div>
  </div>
</template>

<script>
import API from '@/api'
export default {
  data() {
    return {
      search: {
        pageNo: 1,
        pageSize: 10,
      },
      pagination: {
        pageNo: 1,
        pageSize: 10,
      },
      pickTime: null,
      toneList: [],
      times: null, //定时器
      newTop: 0, //位置
      oldTop: 0, //老位置
      total: 500,
      dshCount: 0,
      taskListForHomePageData: [],
      taskStatusList: [
        { value: -2, label: '待审核' },
        { value: -1, label: '待放件' },
        { value: 0, label: '待配送' },
        { value: 3, label: '配送中' },
        { value: 1, label: '已完成' },
        { value: 5, label: '待收货' },
        { value: 99, label: '已关闭' },
      ],
    }
  },
  mounted() {
    this.getUnitList()
    this.abc()
    this.taskListForHomePage();
    // this.initEchart();
    this.taskStatic();
  },
  methods: {
    // 滚动条滚动效果
    abc() {
      let box = document.getElementById('heziliu')
      this.times = setInterval(function () {
        this.oldTop = box.scrollTop
        //获得老位置
        this.newTop = this.oldTop + 1
        //得到新位置
        box.scrollTop = this.newTop
        //将计算的新位置进行赋值，使滚动条移动
        // 到底重来一遍
        if (this.newTop + box.clientHeight > box.scrollHeight) {
          box.scrollTop = 0
        }
      }, 50)
      //循环时间
    },
    // 鼠标悬停
    mouseOver() {
      clearInterval(this.times)
    },
    //鼠标离开
    mouseLeave() {
      this.abc()
    },
    async getUnitList() {
      if (this.pickTime) {
        this.search.start = this.pickTime[0]
        this.search.end = this.pickTime[1]
      } else {
        this.search.start = null
        this.search.end = null
      }

      let sceneConfigData = window.localStorage.getItem('sceneConfigData');
      sceneConfigData = JSON.parse(sceneConfigData);

      let res = await API.TONE.getItemInfoListById()
      if (
        sceneConfigData &&
        sceneConfigData['pointData'] &&
        sceneConfigData['pointData'].length > 0
      ) {
        this.toneList = sceneConfigData['pointData']
      } else {
        this.toneList = res.data || []
      }
    },
    // 跳向实时点调  跳转到UU跑腿飞行监控界面，先到订单任务界面
    btn_point() {
      // let { href } = this.$router.resolve({ path: '/observe_uu/home?page=1' })
      // 进入UU跑腿
      window.open('#/taskOrder_uu', '_self')
      // 进入芜湖消防
      // window.open('#/uav_whxf', '_self')
      // window.open("#/taskOrder_uu", "_self");
      this.$router.go(0);
      // // return;
      // // 跳向信息管理-实时调度-实时调度
      // let { href } = this.$router.resolve({
      //   path: '/command?page=5',
      //   query: {
      //     type: '调度中心',
      //     item: JSON.stringify({ id: 5, title: '实时调度' }),
      //   },
      // })
      // localStorage.setItem("infoPage", 5);
      // // window.open(href, '_blank')
      // window.open(href, '_self');
      // this.$router.go(0);
      // // this.$router.push();
    },
    // 绘制图表
    initEchart() {
      //
      let dom = document.getElementById('callChart')
      let chart = this.$echarts.init(dom)
      let color = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: '#02FFD3', // 0% 处的颜色
        },
        {
          offset: 1,
          color: '#0077F5', // 100% 处的颜色
        },
      ]);
      let option = {
        backgroundColor: 'rgba(0,0,0,0)',
        title: {
          zlevel: 0,
          text: `${this.total}次\r\n\r\n总数`,
          top: '38%',
          left: '39%',
          textAlign: 'center',
          textStyle: {
            color: '#ffffff',
            fontSize: 8,
          },
        },
        series: [
          //  花瓣
          {
            type: 'gauge',
            center: ['40%', '50%'],
            startAngle: 0,
            endAngle: 360,
            splitNumber: 5,
            radius: '80%',
            pointer: {
              show: false,
            },
            progress: {
              show: false,
              roundCap: true,
              width: 0,
            },
            axisLine: {
              show: false,
              lineStyle: {
                width: 0,
                color: [
                  [0.4, color],
                  [1, color],
                ],
              },
            },
            axisTick: {
              distance: -25,
              length: 8,
              splitNumber: 5,
              lineStyle: {
                width: 3,
                color: 'auto',
                cap: 'round'
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10,
              lineStyle: {
                width: 6,
                color: '#099fe4',
              },
            },
            axisLabel: {
              show: false,
            },
            anchor: {
              show: false,
            },
            title: {
              show: false,
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 100,
              }
            ]
          },
          // 外圈
          {
            data: [
              { show: false, value: "100" }
            ], //作用不清楚
            name: "", //刻度背景
            type: "gauge",
            center: ['40%', '50%'],
            radius: "55%",
            startAngle: "0",
            endAngle: "-359.99",
            axisLine: {
              // 坐标轴线
              lineStyle: {
                // 属性lineStyle控制线条样式
                color: [[1, "#415A73"]],
                width: 4,
                opacity: 1, //刻度背景宽度
              },
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            pointer: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            detail: {
              show: 0,
            },
          },
          //   实际数值
          {
            data: [
              { show: false, value: 30 }
            ], //作用不清楚
            name: "", //刻度背景
            type: "gauge",
            center: ['40%', '50%'],
            radius: "60%",
            min: 0,
            max: 360,
            startAngle: 90,
            endAngle: -269.9999,
            axisLine: {
              show: true,
              lineStyle: {
                width: 8,
                color: [
                  [0.5, color],
                  [1, 'rgba(0, 0, 0, 0)'],
                ],
              },
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            pointer: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            detail: {
              show: 0,
            },
          },
          //     最大的外圈
          {
            name: "外层细线",
            z: 0,
            type: 'custom',
            coordinateSystem: "none",
            renderItem: function (params, api) {
              return {
                type: 'arc',
                shape: {
                  cx: api.getWidth() / 2.5,
                  cy: api.getHeight() / 2,
                  r: Math.min(api.getWidth(), api.getHeight()) / 2 * 0.95,
                  startAngle: 0,
                  endAngle: 360
                },
                style: {
                  stroke: 'rgb(105,206,195)',
                  fill: "transparent",
                  lineWidth: 0.5
                },
                silent: true
              };
            },
            data: [0]
          },
          // 外圈刻度

          {
            name: "刻度间隔",
            z: 2,
            type: "gauge",
            radius: '100%',
            center: ['40%', '50%'],
            startAngle: 0,
            endAngle: 359.9,
            splitNumber: 4,
            hoverAnimation: true,
            axisTick: {
              show: true,
              splitNumber: 1,
              length: 0,
              lineStyle: {
                width: 40,
                color: '#1C4A5D'
              }
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: false
            },
            axisLine: {
              lineStyle: {
                opacity: 0,
              }
            },
            detail: {
              show: false
            },
            data: [100]
          },
        ]
      };
      chart.setOption(option)

      window.addEventListener('resize', () => {
        chart.resize()
      })
    },
    //物流任务列表
    async taskListForHomePage() {
      let res = await API.TASKORDER.taskListForHomePage();
      // console.log('物流任务列表', res);
      let sceneConfigData = window.localStorage.getItem('sceneConfigData');
      sceneConfigData = JSON.parse(sceneConfigData);
      // console.log('任务调度场景数据', sceneConfigData);
      if (sceneConfigData && sceneConfigData['pointData'] && sceneConfigData['pointData'].length > 0) {
        this.taskListForHomePageData = sceneConfigData['pointData'];
      } else {
        // this.list = res;
        this.taskListForHomePageData = res;
      }
      // !!! 
      if (this.$store.state.user.user_info.username == "dongtai") {
        let data = {};
        data.pageNo = 1;
        data.pageSize = 100;
        let res = await API.TASKORDER.taskListPage(data);
        console.log('分页任务列表首页滚动', res);
        this.taskListForHomePageData = res.records;
      }
      // console.log('this.taskListForHomePageData', this.taskListForHomePageData);
    },
    // 统计图
    async taskStatic() {
      let res = await API.TASKORDER.statusCount();
      console.log('统计首页', res);
      this.total = res.allCount;
      this.dshCount = res.waitApply;
      this.initEchart();
    },
  },
}
</script>

<style scoped lang="scss">
.flex {
  display: flex;
}

.main {
  width: 100%;
  height: 100%;
  background-color: rgba(35, 47, 79, 0.75);
  border-radius: 12px;
  color: #fff;
  font-family: PingFangSC-Semibold, PingFang SC;
  background: linear-gradient(360deg, #14203E 0%, rgba(28, 74, 93, 0.91) 100%);
  position: relative;

  // 四个角标
  .corn {
    width: 22px;
    height: 20px;
    //border: 1px solid #00F5FF;
    position: absolute;
  }

  .corn1 {
    top: 0;
    left: 0;
    background-image: url("~@/assets/img/corn1.png");
    background-repeat: no-repeat;
  }

  .corn2 {
    top: 0;
    right: 0;
    background-image: url("~@/assets/img/corn1.png");
    background-repeat: no-repeat;
    transform: rotate(90deg);
    //back
  }

  .corn3 {
    bottom: 0;
    left: 0;
    background-image: url("~@/assets/img/corn3.png");
    background-repeat: no-repeat;
  }

  .corn4 {
    bottom: 0;
    right: 0;
    background-image: url("~@/assets/img/corn3.png");
    background-repeat: no-repeat;
    transform: rotate(-90deg);
  }
}

.head {
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  //padding: 0 12px 0 12px;
  align-items: center;
  //background-image: url('~@/assets/zong_lan_ye_img/kuang_title_bg.png');
  background-repeat: no-repeat;
  background-size: 274px 42px;
  border-bottom: 1px solid #474772;

  .title {
    font-size: 18px;
    line-height: 28px;
    margin-left: 14px;
    font-weight: 600;
    height: 100%;
    width: 30%;
    background-image: url("~@/assets/img/title.png");
    background-repeat: no-repeat;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #FFFFFF;
    display: flex;
    align-items: center;
  }

  .more {
    font-size: 14px;
    line-height: 25px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;

    img {
      margin-left: 3px;
      width: 11px;
      height: 17px;
    }
  }
}

.nei {
  width: 100%;
  height: calc(100% - 42px);
  box-sizing: border-box;
  padding: 3px 16px 12px 16px;
  margin: 0 0 8px 0;

  .header_file {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px 0 16px;
    height: 35px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #ffffff;
    line-height: 17px;
  }

  .Table_Properties {
    width: 100%;
    //height: calc(100% - 35px);
    height: 55%;
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;

    .kuang:nth-child(odd) {
      // background: rgba(99, 177, 245, 0.09);
      background: rgba(133, 199, 255, 0.1);
      // border: 1px solid rgba(84, 147, 215, 0.37);
    }

    .kuang:nth-child(even) {
      // background: transparent;
      // background: rgba(99, 177, 245, 0.09);
      background: rgba(133, 199, 255, 0.1);
      // border: 1px solid rgba(84, 147, 215, 0.37);
    }
  }

  .kuang {
    width: 100%;
    height: 109px;
    // padding: 0 19px 0 28px;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: space-between;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
    margin-top: 10px;
    border-radius: 4px;
    // background-color: #223C59;
    // background-color: #076cdf;
    background: rgba(133, 199, 255, 0.1);
    padding: 10px 0;

    .line {
      box-sizing: border-box;
      padding: 0px 10px;
    }

    .line1 {
      height: 25%;
      width: 100%;
      // background-color: #19e210;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .n {
        // width: 56px;
        // height: 20px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 14px;
        color: #FFFFFF;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }

      .t {
        // width: 86px;
        // height: 17px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #FFFFFF;
        text-align: right;
        font-style: normal;
        text-transform: none;
      }

    }

    .line2 {
      height: 40%;
      width: 100%;
      // background-color: #1064e2;
      // border: 1px solid #ff0000;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .i {
        width: 100%;
        height: 50%;
        // width: 136px;
        // height: 17px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #8191A2;
        // line-height: 14px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        // border: 1px solid #ff0000;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }

    .line3 {
      height: 28%;
      width: 100%;
      // background-color: #9f10e2;
      display: flex;
      align-items: center;
      justify-content: center;

      .b {
        width: 70%;
        height: 100%;
        // border: 1px solid #ff0000;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // width: 223px;
        // height: 19px;
        background: rgba(0, 245, 255, 0.12);
        border-radius: 4px 4px 4px 4px;
        box-sizing: border-box;
        padding: 0 10px;

        div {
          width: 40%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          // border: 1px solid #ff0000;
        }

        img {
          width: 15%;
        }
      }

      .bt {
        width: 30%;
        height: 100%;
        // border: 1px solid #ff0000;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        div {
          // width: 62px;
          width: auto;
          box-sizing: border-box;
          padding: 0 10px;
          height: 28px;
          background: rgba(255, 184, 0, 0.13);
          border-radius: 23px 23px 23px 23px;
          display: flex;
          align-items: center;
          justify-content: center;
          // width: 42px;
          // height: 20px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #FF9A0E;
          line-height: 16px;
          text-align: center;
          font-style: normal;
          text-transform: none;
        }
      }
    }
  }

  .changdu {
    width: 200px;
    // width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }

  .status {
    // width: 52px;
    width: auto;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .point {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-right: 3px;
  }

  .point-color-green {
    background: #19e210;
  }

  .point-color-red {
    background: #ff7362;
  }

  .call {
    height: 45%;
    width: 100%;
    //background-color: #0cafff;
    justify-content: space-around;
    align-items: center;
    position: relative;

    #callChart {
      width: 100%;
      height: 100%;
    }

    .card {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      height: 30%;
      width: 30%;

      .text {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-left: 15px;

        .num {
          font-size: 16px;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          color: #FFFFFF;
        }

        .name {
          font-size: 12px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #557899;
          margin-top: 5px;
        }
      }
    }

    .card1 {
      top: 10%;
      right: 10%;

    }

    .card2 {
      top: 55%;
      right: 10%;

      //border: 1px solid red;
      .num {
        color: #ff0000 !important;
      }
    }
  }
}
</style>
