import API from "@/api"
import { Utils } from "@/lib/cesium";

function tree2list(tree, list = []) {
    tree.forEach(item => {
        list.push({ ...item })
        if (item.children && item.children.length) {
            list.push(...tree2list(item.children))
        }
    })
    return list
}

function findRoot(curr, list) {
    if (curr.pid <= 0) {
        return curr
    }
    while (curr.pid > 0) {
        curr = list.find(item => item.id == curr.pid)
    }
    return curr
}

export default {

    change(data, list) {
        list = tree2list(list) || []
        if (data.checked) {
            // this.factor_show(data, list)
        } else {
            this.factor_hide(data, list)
        }
    },

    init_collection(cate) {
        let viewer = this.cesium_layer.viewer()
        let scene = viewer.scene

        if (Cesium.defined(this.collection[cate])) {
            scene.primitives.remove(this.collection[cate])
        }

        switch (cate) {
            case 'zysb':
                this.collection[cate] = new Cesium.BillboardCollection({ scene })
                return this.collection[cate]
            case 'jwys':
                this.collection[cate] = new Cesium.BillboardCollection({ scene })
                return this.collection[cate]
            case 'gzsb':
                this.collection[cate] = new Cesium.BillboardCollection({ scene })
                return this.collection[cate]
            case 'kfq':
                return null
        }
    },

    show_collection(cate) {
        let viewer = this.cesium_layer.viewer()
        let scene = viewer.scene
        scene.primitives.add(this.collection[cate])
    },
    hide_collection(cate) {
        let viewer = this.cesium_layer.viewer()
        let scene = viewer.scene
        scene.primitives.remove(this.collection[cate])
    },

    async show(data, list) {
        let root = findRoot(data, list)
        let cate = root.id

        let res = await API.FACTOR.List({
            cate,
            id: [1, 2, 3, 4, 7, 8, 9, 10, 11, 12, 13, 14]
        })

        switch (cate) {
            case 1:
                this.factor_add_zysb(res);
                break;
            case 2:
                this.factor_add_jwys(res);
                break;
            case 3:
                this.factor_add_gzsb(res);
                break;
            case 5:
                this.factor_add_kfq(res);
                break;
        }
    },

    add_zysb(res) {
        let collection = this.factor_init_collection("zysb")

        let list = []
        res.forEach(item => {
            list.push(...item.firstList)
            list.push(...item.secondList)
            list.push(...item.thirdList)
            list.push(...item.fourthList)
        })

        list.forEach(item => {
            item.longitude *= 1
            item.latitude *= 1
            if (item.latitude && item.longitude) {
                collection.add({
                    position: Utils.transformWGS842Cartesian({
                        lng: item.longitude,
                        lat: item.latitude,
                        alt: item.altitude
                    }),
                    image: require("@/assets/images/poi.png"),
                    scale: 1.0,
                })
            }
        })

        !this.cesium_layer.is_plate_mode() && this.factor_show_collection("zysb")
    },

    add_jwys(res) {
        let collection = this.factor_init_collection("jwys")

        let list = []
        res.forEach(item => {
            list.push(...item.firstList)
            list.push(...item.secondList)
            list.push(...item.thirdList)
            list.push(...item.fourthList)
        })

        list.forEach(item => {
            item.longitude *= 1
            item.latitude *= 1
            if (item.latitude && item.longitude) {
                collection.add({
                    position: Utils.transformWGS842Cartesian({
                        lng: item.longitude,
                        lat: item.latitude,
                        alt: item.altitude
                    }),
                    image: require("@/assets/images/poi.png"),
                    scale: 1.0,
                })
            }
        })

        !this.cesium_layer.is_plate_mode() && this.factor_show_collection("jwys")
    },

    add_gzsb(res){},

    add_kfq(res){

        let list = res
        if (!list || !list.length) {
            return list
        }

        let features = list
        let instances = [];

        for (let i = 0; i < features.length; i++) {
            for (let j = 0; j < features[i].geometry.coordinates.length; j++) {

                let polygonArr = features[i].geometry.coordinates[j].reduce((total, item) => {
                    total.push(...item)
                    return total
                }, []);

                let polygon = new Cesium.PolygonGeometry({
                    polygonHierarchy: new Cesium.PolygonHierarchy(
                        Cesium.Cartesian3.fromDegreesArray(polygonArr)
                    ),
                    vertexFormat: Cesium.PerInstanceColorAppearance.VERTEX_FORMAT
                });

                let geometry = Cesium.PolygonGeometry.createGeometry(polygon);

                if (geometry) {
                    instances.push(new Cesium.GeometryInstance({
                        geometry: geometry,
                        attributes: {
                            color: Cesium.ColorGeometryInstanceAttribute.fromColor(Cesium.Color.fromCssColorString("#d42626").withAlpha(0.7)),
                        },
                    }));
                }
            }
        }
        this.collection["kfq"] = new Cesium.Primitive({
            geometryInstances: instances,
            appearance: new Cesium.PerInstanceColorAppearance({ // 为每个instance着色
                translucent: true,
                closed: false
            }),
            asynchronous: false
        });

        !this.cesium_layer.is_plate_mode() && this.factor_show_collection("kfq")
    },

    hide(data, list) {
        let root = findRoot(data, list)
        let cate = root.id

        switch (cate) {
            case 1:
                this.factor_hide_collection("zysb")
                break;
            case 2:
                this.factor_hide_collection("jwys")
                break;
            case 3:
                this.factor_hide_collection("gzsb")
                break;

            case 5:
                this.factor_hide_collection("kfq")
        }
    }

}