<!-- 安全预警 -->
<template>
  <div class="main">
    <div class="head">
      <div class="title">安全预警</div>
      <div class="more" @click="btn_safety">
        <span>更多</span>
        <img src="~@/assets/zong_lan_ye_img/jiantou_more.png" />
      </div>
    </div>
    <div class="nei">
      <div class="tab_ge">
        <div class="header_file">
          <div>预警内容</div>
          <div>预警单位</div>
          <div>操作</div>
        </div>
        <div class="Table_Properties" id="hezisi" @mouseover="mouseOver" @mouseleave="mouseLeave">
          <!-- 模板 -->
          <div class="kuang" v-for="(item, index) in list" :key="index">
            <el-tooltip class="item" effect="dark" :content="item.content" placement="top-start" :enterable="false">
              <div class="chang">{{ item.content || "超出限高飞行" }}</div>
            </el-tooltip>
            <div class="changdu">
              <el-tooltip class="item" effect="dark" :content="item.orgName" placement="top-start" :enterable="false">
                <span> {{ item.orgName || "盐都派出所" }} </span>
              </el-tooltip>
            </div>
            <div class="btn_inform gray" @click="btn_Warning(item)" v-show="item.isNotice == 1">
              已通知
            </div>
            <div class="btn_inform blue" @click="btn_Warning(item)" v-show="item.isNotice == 0">
              立即通知
            </div>
          </div>
          <!-- 模板 -->
          <p v-if="list.length == 0" style="text-align: center; color: #fff">
            暂无数据
          </p>
        </div>
      </div>
    </div>
    <!-- 四个角标 -->
    <div class="corn corn1"></div>
    <div class="corn corn2"></div>
    <div class="corn corn3"></div>
    <div class="corn corn4"></div>
  </div>
</template>

<script>
import Api from "@/api/modules/newOverview.js";
import Bus from "@/assets/ligature.js";
const dongtaiData = [
  {
    "waringId": 50,
    "content": "您的东台市运输1号机电量已低于40%，请注意安全，做好返航规划！",
    "orgId": 246190,
    "createdTime": "2023-06-06 17:47:04",
    "orgName": "东台市机队",
    "typeName": "电量预警",
    "pid": 64428,
    "isRead": 1,
    "isNotice": 0,
    "orgCode": "320991510000"
  },
  {
    "waringId": 51,
    "content": "您的东台市运输1号机电量已低于30%，请注意安全，做好返航规划！",
    "orgId": 246190,
    "createdTime": "2023-06-06 17:47:04",
    "orgName": "东台市机队",
    "typeName": "电量预警",
    "pid": 64427,
    "isRead": 1,
    "isNotice": 0,
    "orgCode": "320991510000"
  },
  {
    "waringId": 52,
    "content": "您即将靠近高压杆塔，请注意航线避让！",
    "orgId": 246190,
    "createdTime": "2023-06-06 17:47:04",
    "orgName": "东台市机队",
    "typeName": "电量预警",
    "pid": 64426,
    "isRead": 1,
    "isNotice": 0,
    "orgCode": "320991510000"
  },
  {
    "waringId": 53,
    "content": "您即将靠近高压杆塔，请注意航线避让！",
    "orgId": 246190,
    "createdTime": "2023-06-06 17:47:04",
    "orgName": "东台市机队",
    "typeName": "电量预警",
    "pid": 64425,
    "isRead": 1,
    "isNotice": 0,
    "orgCode": "320991510000"
  },
];
export default {
  data() {
    return {
      list: [],
      times: null, //定时器
      newTop: 0, //位置
      oldTop: 0, //老位置
    };
  },
  async mounted() {
    await this.getlist();
    this.abc();
  },
  created() {
    Bus.$on("yujings", () => {
      this.getlist();
    });
  },
  methods: {
    // 滚动条滚动效果
    abc() {
      if (this.list.length < 3) {
        return;
      }
      let box = document.getElementById("hezisi");
      this.times = setInterval(function () {
        this.oldTop = box.scrollTop;
        //获得老位置
        this.newTop = this.oldTop + 1;
        //得到新位置
        box.scrollTop = this.newTop;
        //将计算的新位置进行赋值，使滚动条移动
        // 到底重来一遍
        if (this.newTop + box.clientHeight > box.scrollHeight) {
          box.scrollTop = 0;
        }
      }, 50);
      //循环时间
    },
    // 鼠标悬停
    mouseOver() {
      clearInterval(this.times);
    },
    //鼠标离开
    mouseLeave() {
      this.abc();
    },
    async getlist() {
      let res = await Api.ist();
      let sceneConfigData = window.localStorage.getItem('sceneConfigData');
      sceneConfigData = JSON.parse(sceneConfigData);
      if (sceneConfigData && sceneConfigData['safeData'] && sceneConfigData['safeData'].length > 0) {
        this.list = sceneConfigData['safeData'];
      } else {
        this.list = res;
      }
      if (this.$store.state.user.user_info.username == "dongtai") {
        this.list = dongtaiData;
      }
    },
    btn_safety(item) {
      // return;
      this.$emit("pup", 10, item);
    },
    btn_Warning(item) {
      this.$emit("pup", 12, item);
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  height: 24.8vh;
  background-color: rgba(35, 47, 79, 0.75);
  //border-radius: 12px;
  color: #fff;
  font-family: PingFangSC-Semibold, PingFang SC;
  background: linear-gradient(360deg, #14203E 0%, rgba(28, 74, 93, 0.91) 100%);
  position: relative;

  // 四个角标
  .corn {
    width: 22px;
    height: 20px;
    //border: 1px solid #00F5FF;
    position: absolute;
  }

  .corn1 {
    top: 0;
    left: 0;
    background-image: url("~@/assets/img/corn1.png");
    background-repeat: no-repeat;
  }

  .corn2 {
    top: 0;
    right: 0;
    background-image: url("~@/assets/img/corn1.png");
    background-repeat: no-repeat;
    transform: rotate(90deg);
    //back
  }

  .corn3 {
    bottom: 0;
    left: 0;
    background-image: url("~@/assets/img/corn3.png");
    background-repeat: no-repeat;
  }

  .corn4 {
    bottom: 0;
    right: 0;
    background-image: url("~@/assets/img/corn3.png");
    background-repeat: no-repeat;
    transform: rotate(-90deg);
  }
}

.head {
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  //padding: 0 12px 0 12px;
  align-items: center;
  //background-image: url("~@/assets/zong_lan_ye_img/kuang_title_bg.png");
  background-repeat: no-repeat;
  background-size: 274px 42px;
  border-bottom: 1px solid #474772;

  .title {
    font-size: 18px;
    line-height: 28px;
    margin-left: 14px;
    font-weight: 600;
    height: 100%;
    width: 30%;
    background-image: url("~@/assets/img/title.png");
    background-repeat: no-repeat;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #FFFFFF;
    display: flex;
    align-items: center;
  }

  .more {
    font-size: 14px;
    line-height: 25px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;

    img {
      margin-left: 3px;
      width: 11px;
      height: 17px;
    }
  }
}

.nei {
  width: 100%;
  height: calc(100% - 42px);
  box-sizing: border-box;
  padding: 0px 16px 16px 16px;
}

.tab_ge {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header_file {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 40px 0 48px;
    height: 35px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #ffffff;
    line-height: 17px;
  }

  .Table_Properties {
    width: 100%;
    // height: 84px;
    flex: 1;
    overflow-y: auto;

    .kuang:nth-child(odd) {
      background: rgba(99, 177, 245, 0.09);
      border: 1px solid rgba(84, 147, 215, 0.37);
    }

    .kuang:nth-child(even) {
      background: transparent;
    }
  }

  .kuang {
    width: 100%;
    height: 30px;
    padding: 0 16px 0 15px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;

    .chang {
      width: 126px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .changdu {
      width: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.btn_inform {
  width: 60px;
  height: 21px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5),
    inset 0px 1px 0px 0px rgba(255, 255, 255, 0.29);
  border-radius: 2px;
  background-size: 100% 100%;
  text-align: center;
  line-height: 21px;
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
  border-radius: 22px;
}

.blue {
  background: rgba(1, 109, 255, 0.85);
}

.gray {
  background: #344354;
}
</style>
