<template>
  <div class="flv">
    <video controls autoplay="autoplay" muted="muted" ref="videoElement" id="videoElement">
      <source type="video/mp4" />

    </video>
  </div>
</template>

<script>
import * as flvjs from "./flv.js";
export default {
  props: {
    data: Object,
  },
  data() {
    return {
      player: null,
      flvPlayer: null,
      // videourl: ""
    };
  },
  mounted() {
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        this.ngOnDestroy()
        return;
      } else {
        this.ngOnInit()
      }
    });
    this.ngOnInit();
  },
  methods: {
    //页面初始化
    ngOnInit() {
      if (flvjs.default.isSupported()) {
        // 获取DOM对象
        this.player = this.$refs.videoElement;
        // this.player = document.getElementById("videoElement_"+this.index);
        // 创建flvjs对象
        this.flvPlayer = flvjs.default.createPlayer({
          type: "flv", // 指定视频类型
          isLive: true, // 开启直播
          hasAudio: false, // 关闭声音
          cors: true, // 开启跨域访问
          // url: "http://pzlink999bju.powzamedia.com:8000/live/flv/test/mlinkm/channel1?only-video=1", // 指定流链接
          // url: 'https://vd4.bdstatic.com/mda-jj0iure2x5z9hzqf/mda-jj0iure2x5z9hzqf.mp4?v_from_s=hkapp-haokan-nanjing&auth_key=1677311021-0-0-336038f2b76f1822cd0a27e0473a5da1&bcevod_channel=searchbox_feed&pd=1&cd=0&pt=3&logid=0821064610&vid=14135310692200314008&abtest=&klogid=0821064610'
          // this.getTarget(this.data.vUrl), // 指定流链接
          url: this.getTarget(this.data.vUrl)

        });
        // 将flvjs对象和DOM对象绑定
        this.flvPlayer.attachMediaElement(this.player);
      }
      this.play()
    },
    getTarget(url) {
      const reg = /^pzsp:\/\/(.*):7788.*\/trans\/(.*)\/mlinkm\/(.*)\?/;
      url.match(reg);
      const hostname = RegExp.$1;
      const groupname = RegExp.$2;
      const streamname = RegExp.$3;

      return `http://${hostname}:8000/live/flv/${groupname}a/mlinkm/${streamname}?only-video=1`;
    },

    // 播放视频
    play() {
      this.flvPlayer.load(); // 加载视频
      this.flvPlayer.play(); // 播放
    },

    //页面退出销毁和暂停播放
    ngOnDestroy() {
      this.flvPlayer.pause();
      this.flvPlayer.unload();
      // 卸载DOM对象
      this.flvPlayer.detachMediaElement();
      // 销毁flvjs对象
      this.flvPlayer.destroy();
    },
  },
};
</script>

<style lang="scss" scoped>
.flv {
  width: 100%;
  height: 100%;
  #videoElement {
    width: 100%;
    height: 100%;
  }
}
</style>
