<!-- 航线审批 -->
<template>
  <div class="main">
    <div class="head">
      <div class="title">飞行活动审批</div>
      <el-tooltip class="item" effect="dark" content="飞行航线网格" placement="top">
        <img class="grid-single" src="@/assets/img/i158.png" alt="" @click="handleGoToGridPage" v-if="1">
      </el-tooltip>
      <div class="grid-img" @click="handleGridFormClick">
        新建活动
      </div>
      <img class="grid-img-clear" src="@/assets/img/clear.png" alt="" @click="handleClearGridClick" v-if="0">
      <div class="more" @click="jump">
        <span>更多</span>
        <img src="~@/assets/zong_lan_ye_img/jiantou_more.png" />
      </div>
    </div>
    <div class="nei">
      <div class="flex particulars">
        <div id="particularsChart">
        </div>
        <!-- 待审批 -->
        <div class="card card1">
          <img src="~@/assets/img/card1.png" />
          <div class="text">
            <span class="num">{{ this.Nlen }}次</span>
            <span class="name">待审批</span>
          </div>
        </div>
        <div class="card card2">
          <img src="~@/assets/img/card2.png" />
          <div class="text">
            <span class="num">{{ this.Ylen }}次</span>
            <span class="name">已审批</span>
          </div>
        </div>
      </div>
      <div class="Course_approval" id="hezisan" @mouseover="mouseOver" @mouseleave="mouseLeave">
        <!-- 模板 -->
        <div class="Course_approval_template" v-for="item in airspace_list" :key="item.id">
          <div>
            <div class="fontSix">
              <span class="scroll-text1" style="padding-right: 50px">{{
                              item.flag == "task" ? item.taskTitle : item.flight_name
                              }}</span>
              <!--<span class="scroll-text1" style="padding-right: 50px">{{-->
              <!--  item.flag == "task" ? item.taskTitle : item.flight_name-->
              <!--}}</span>-->
            </div>
            <div class="flex drone_pilot" v-if="item.flag == 'task'">
              <span class="item-span-text"><span class="lable">{{ "类型" }}:</span>{{ item.typeName || "暂无" }}</span>
              <span class="item-span-text"><span class="lable">{{ "单位" }}:</span>{{ item.orgName }}</span>
            </div>
            <div class="flex unit">
              <span class="item-span-text"><span class="lable">{{ "飞手" }}:</span>{{ item.flyName || "暂无" }}</span>
              <div class="btn" v-if="item.applyStatus == 0" @click="btn_app(item, item.flag == 'task' ? 1 : 2)">
                待审批
              </div>
              <div class="btn btn_lv" v-else>已审批</div>
            </div>
          </div>
        </div>
        <!-- 模板 -->
        <p v-if="airspace_list.length == 0" class="no-data">暂无数据</p>
      </div>
    </div>
    <!-- 四个角标 -->
    <div class="corn corn1"></div>
    <div class="corn corn2"></div>
    <div class="corn corn3"></div>
    <div class="corn corn4"></div>
    <!-- 网格航线表单 -->
    <el-dialog
      title="提示"
      :visible.sync="gridFormDialogVisible"
      :modal="false"
      custom-class="grid-form-fialog-class"
      width="28%">
      <div class="text-header">
        <span>飞行活动空域申请</span>
        <img src="@/assets/img/i40.png" alt="" @click="gridFormDialogVisible = false">
      </div>
      <div class="form-c">
        <el-form ref="airlineFormRef" :model="airLineForm" label-width="150px" :label-position="labelPosition">
          <el-form-item label="操控模式：">
            <el-select v-model="airLineForm.controlMode" placeholder="请选择" size="small">
              <el-option
                v-for="item in controlModeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="飞行模式：">
            <el-select v-model="airLineForm.flyMode" placeholder="请选择" size="small">
              <el-option
                v-for="item in flightModeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="任务性质：">
            <el-select v-model="airLineForm.taskCharacter" placeholder="请选择" size="small">
              <el-option
                v-for="item in taskCharacterList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="航空航线：">
            <el-select v-model="airLineForm.lines" placeholder="请选择" size="small" multiple >
              <el-option
                v-for="item in airLineList"
                :key="item.index"
                :label="item.flightName"
                :value="item.index">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="预计开始时间：" v-if="1">
            <el-date-picker style="width: 100%;"
              v-model="airLineForm.startTime"
              type="datetime"
              size="small"
              placeholder="选择预计开始时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="预计终止时间：" v-if="1">
            <el-date-picker  style="width: 100%;"
              v-model="airLineForm.endTime"
              type="datetime"
              size="small"
              placeholder="选择预计终止时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="通信联络方法：" v-if="1">
            <el-input v-model="airLineForm.methodsOfContact" placeholder="请输入通信联络方法" size="small"></el-input>
          </el-form-item>
          <el-form-item label="最大飞行高度(m)：" v-if="1">
            <el-input v-model="airLineForm.maxFlyHeight" placeholder="请输入最大飞行高度" size="small"></el-input>
          </el-form-item>
          <el-form-item label="特殊飞行保障需求：" v-if="1">
            <el-input v-model="airLineForm.guaranteeDemand" placeholder="请输入保障需求" size="small" type="textarea" :autosize="{ minRows: 1, maxRows: 4}"></el-input>
          </el-form-item>
          <el-form-item label="航空器选择：" v-if="1">
            <el-select v-model="airLineForm.aviationVehicle" placeholder="请选择" size="small" >
              <el-option
                v-for="item in aviationVehicleList"
                :key="item.label"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="操控员选择：" v-if="1">
            <el-select v-model="airLineForm.controller" placeholder="请选择" size="small" multiple>
              <el-option
                v-for="item in controllerList"
                :key="item.label"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="gridFormDialogVisible = false" style="background-color: #344354; color: #fff; border: 0;" size="small">取 消</el-button>
        <el-button class="btn btn-submit" style="background-color: #0463E3; color: #fff; border: 0;" @click="handleGridsAriLineSubmit"  size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api";
import EventBus from "@/assets/ligature.js";
const shiyanList = ['预警单位',
'勋西县',
'勋阳区',
'丹江口市',
'张湾区',
'茅箭区',
'竹山县',
'竹溪县',
'房县']
import _ from 'lodash'
export default {
  props:{
    gridsIndex:{
      type:Number,
      default:1
    }
  },
  watch:{
    gridsIndex:{
      handler(newVal, oldVal) {
        this.gridsIndexNew = newVal;
        // this.airLineList = grids_list[newVal-1].flightLines;
        this.flightLineIds = grids_list[newVal-1].flightLineIds;
      },
      immediate: true,
    }
  },
  data () {
    return {
      airspace_list: [], //
      Nlen: 0,
      Ylen: 0,
      flightApprove: false, //航线审批权限
      taskApprove: false, //任务审批权限
      times: null, //定时器
      newTop: 0, //位置
      oldTop: 0, //老位置
      total: 0,
      percent: 100,
      gridFormDialogVisible: false,
      airLineForm:{
        controlMode:'',
        flyMode:'',
        taskCharacter:'',
        lines:[],
        linesData:[],
        startTime:'',
        endTime:'',
        methodsOfContact:'',
        maxFlyHeight:'',
        guaranteeDemand:'',
        aviationVehicle:'',
        controller:[],
      },
      controlModeList:[
        {value:'遥控飞行', label:'遥控飞行'},
        {value:'自主飞行', label:'自主飞行'},
      ],
      flightModeList:[
        {value:'超视距飞行', label:'超视距飞行'},
        {value:'视距内飞行', label:'视距内飞行'},
      ],
      taskCharacterList:[
        {value:'违法建设检查', label:'违法建设检查'},
        {value:'海事巡查', label:'海事巡查'},
        {value:'汛期地质灾害抢险排查飞行', label:'汛期地质灾害抢险排查飞行'},
        {value:'训练飞行', label:'训练飞行'},
        {value:'试飞', label:'试飞'},
        {value:'转场(调机)', label:'转场(调机)'},
        {value:'个人娱乐', label:'个人娱乐'},
        {value:'航空表演', label:'航空表演'},
        {value:'空中广告', label:'空中广告'},
        {value:'航空摄影', label:'航空摄影'},
        {value:'操控员培训', label:'操控员培训'},
        {value:'石油服务', label:'石油服务'},
        {value:'气象探测', label:'气象探测'},
        {value:'科学实验', label:'科学实验'},
      ],
      airLineList:[],
      aviationVehicleList:[
        {value:'联飞快递#11', label:'联飞快递#11'},
        {value:'联飞快递#12', label:'联飞快递#12'},
      ],
      controllerList:[
        {value:'沈纬晨', label:'沈纬晨'},
        {value:'胡启昆', label:'胡启昆'},
        {value:'王满林', label:'王满林'},
        {value:'袁森', label:'袁森'},
      ],
      gridsIndexNew:1,
      labelPosition:'left',
      flightLineIds:[],
    };
  },
  mounted () {
    this.get_arplist();
    this.abc();
    this.airLineList = grids_list[this.gridsIndexNew-1].flightLines;
  },
  methods: {
    // 滚动条滚动效果
    abc () {
      let box = document.getElementById("hezisan");
      this.times = setInterval(function () {
        this.oldTop = box.scrollTop;
        //获得老位置
        this.newTop = this.oldTop + 1;
        //得到新位置
        box.scrollTop = this.newTop;
        //将计算的新位置进行赋值，使滚动条移动
        // 到底重来一遍
        if (this.newTop + box.clientHeight > box.scrollHeight) {
          box.scrollTop = 0;
        }
      }, 50);
      //循环时间
    },
    // 鼠标悬停
    mouseOver () {
      clearInterval(this.times);
    },
    //鼠标离开
    mouseLeave () {
      this.abc();
    },
    //审批弹窗
    async btn_app (val, i) {
      // console.log(val, i, '审批弹窗1');
      if (i == 1) {
        // console.log(this.taskApprove, '审批弹窗2');
        if (this.taskApprove) {
          //任务
          let res = await API.USER.detailslist(val.id);
          if (val) {
            if (val.taskCateId == 1) {
              res.is_from_situation = true;
            }
            this.$store.commit(
              "situation/SITUATION_IS_SHOW",
              val.taskCateId == 1 ? true : false
            );
            this.$store.commit("situation/SITUATION_DETAIL", res);
          }
          this.$emit("pup", 102);
        } else {
          this.$message.error("无审批权限！");
        }
      } else {
        if (this.flightApprove) {
          //航线
          this.$store.commit("situation/SITUATION_DETAIL", val);
          this.$emit("pup", 101);
        } else {
          this.$message.error("无审批权限！");
        }
      }
    },
    //空域权限
    async get_arplist () {
      // console.log('get_arplist')
      let res = await API.TASK.ListAll({
        // type:type,
        dateType: 2,
      });
      console.log(res, '111空域权限');
      // if (res) {
      let list = [];
      list = res.taskList.concat(
        res.lineList.map((item) => {
          item.applyStatus = item.status;
          return item;
        })
      );

      //过滤出待审批与已审批的数组
      const waitAudit = list.filter((item) => {
        return item.applyStatus === 0;
      });
      const audited = list.filter((item) => {
        return item.applyStatus !== 0;
      });

      let result = waitAudit;
      const count = 20;
      //判断待审核的数量是否超过count，未超过则使用部分已审核数据进行充数
      if (result.length <= count) {
        let auditedPart = audited.slice(0, count - result.length);
        result = result.concat(auditedPart);
      }
      //这两条用于测试样式多条数据
      // result = result.concat(result);
      // result = result.concat(result);
      this.airspace_list = result;
      // console.log('airspace_list', this.airspace_list);
      let sceneConfigData = window.localStorage.getItem('sceneConfigData');
      sceneConfigData = JSON.parse(sceneConfigData);
      if (sceneConfigData && sceneConfigData['home_title'] && sceneConfigData['home_title'].indexOf('十堰市') != -1) {
        this.airspace_list.forEach((v) => {
          console.log('vvvvvvvv', v);
          if(v?.taskTitle){
            let a = v.taskTitle.split('-');
            let index = a.length - 1;
            let taskTitle = a[index];
            v.taskTitle = shiyanList[_.random(0, 8)] + '-' +  a[index];
            v.orgName = '联飞快递';
          }
          if(v?.flight_name){
            if(v.flight_name.indexOf('湾沚区') != -1){
              v.flight_name= v.flight_name.replace('湾沚区', shiyanList[_.random(0, 8)])
            }
          }
        });
      }
      // console.log('this.airspace_list', this.airspace_list)
      this.taskApprove = res.taskApprove;
      this.flightApprove = res.flightApprove;
      // console.log(this.airspace_list, "this.airspace_list");
      this.Ylen = res.aprovedTotal;
      this.Nlen = res.approvingTotal;
      this.total = parseInt(this.Ylen) + parseInt(this.Nlen);
      this.percent = (parseInt(this.Ylen) / (this.total));
      this.initEchart();

    },
    async getlist (type) {
      let res = await API.TASK.ListAll({
        type: type,
        dateType: 2,
      });
      // if (res) {
      let list = [];
      list = res.taskList.concat(res.lineList);
      this.airspace_list = list;
    },
    jump () {
      // return;
      // 定位到实时调度里面的航空航线
      let { href } = this.$router.resolve({
        path: "/command?page=5",
        query: {
          type: "调度中心",
          item: JSON.stringify({ id: 5, title: "航空航线" }),
        },
      });
      localStorage.setItem("infoPage", 5);
      // window.open(href, "_blank");
      window.open(href, "_self");
      this.$router.go(0);
      // Bus.$emit('info-sideBar-lock', 2);
    },
    btn_pup () {
      this.$emit("pup", 3);
    },
    // 绘制图表
    initEchart () {
      let dom = document.getElementById('particularsChart')
      console.log('domdomdomdomdom', dom);
      let chart = this.$echarts.init(dom)
      let color = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: '#02FFD3', // 0% 处的颜色
        },
        {
          offset: 1,
          color: '#0077F5', // 100% 处的颜色
        },
      ]);
      let option = {
        backgroundColor: 'rgba(0,0,0,0)',
        title: {
          zlevel: 0,
          text: `${this.total}次\r\n\r\n总数`,
          top: '38%',
          left: '39%',
          textAlign: 'center',
          textStyle: {
            color: '#ffffff',
            fontSize: 12,
          },
        },
        series: [
          //  花瓣
          {
            type: 'gauge',
            center: ['40%', '50%'],
            startAngle: 0,
            endAngle: 360,
            splitNumber: 5,
            radius: '80%',
            pointer: {
              show: false,
            },
            progress: {
              show: false,
              roundCap: true,
              width: 0,
            },
            axisLine: {
              show: false,
              lineStyle: {
                width: 0,
                color: [
                  [0.4, color],
                  [1, color],
                ],
              },
            },
            axisTick: {
              distance: -25,
              length: 8,
              splitNumber: 5,
              lineStyle: {
                width: 3,
                color: 'auto',
                cap: 'round'
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10,
              lineStyle: {
                width: 6,
                color: '#099fe4',
              },
            },
            axisLabel: {
              show: false,
            },
            anchor: {
              show: false,
            },
            title: {
              show: false,
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 100,
              }
            ]
          },
          // 外圈
          {
            data: [
              { show: false, value: "100" }
            ], //作用不清楚
            name: "", //刻度背景
            type: "gauge",
            center: ['40%', '50%'],
            radius: "60%",
            startAngle: "0",
            endAngle: "-359.99",
            axisLine: {
              // 坐标轴线
              lineStyle: {
                // 属性lineStyle控制线条样式
                color: [[1, "#415A73"]],
                width: 4,
                opacity: 1, //刻度背景宽度
              },
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            pointer: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            detail: {
              show: 0,
            },
          },
          // 实际数值
          {
            data: [
              { show: false, value: 30 }
            ], //作用不清楚
            name: "", //刻度背景
            type: "gauge",
            center: ['40%', '50%'],
            radius: "65%",
            min: 0,
            max: 360,
            startAngle: 90,
            endAngle: -269.9999,
            axisLine: {
              show: true,
              lineStyle: {
                width: 10,
                color: [
                  [this.percent, color],
                  [1, 'rgba(0, 0, 0, 0)'],
                ],
              },
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            pointer: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            detail: {
              show: 0,
            },
          },
          // 最大的外圈
          {
            name: "外层细线",
            z: 0,
            type: 'custom',
            coordinateSystem: "none",
            renderItem: function (params, api) {
              return {
                type: 'arc',
                shape: {
                  cx: api.getWidth() / 2.5,
                  cy: api.getHeight() / 2,
                  r: Math.min(api.getWidth(), api.getHeight()) / 2 * 0.95,
                  startAngle: 0,
                  endAngle: 360
                },
                style: {
                  stroke: 'rgb(105,206,195)',
                  fill: "transparent",
                  lineWidth: 0.5
                },
                silent: true
              };
            },
            data: [0]
          },
          // 外圈刻度
          {
            name: "刻度间隔",
            z: 2,
            type: "gauge",
            radius: '100%',
            center: ['40%', '50%'],
            startAngle: 0,
            endAngle: 359.9,
            splitNumber: 4,
            hoverAnimation: true,
            axisTick: {
              show: true,
              splitNumber: 1,
              length: 0,
              lineStyle: {
                width: 40,
                color: '#1C4A5D'
              }
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: false
            },
            axisLine: {
              lineStyle: {
                opacity: 0,
              }
            },
            detail: {
              show: false
            },
            data: [100]
          },
        ]
      };
      chart.setOption(option)
      window.addEventListener('resize', () => {
        chart.resize()
      })
    },
    /**@Description :  ******************************************* 点击网格航线表单
     **@Date: 2024-04-10 11:28:28
    */
    async handleGridFormClick(){
      this.gridFormDialogVisible = true;
      this.airLineForm.controlMode = '';
      this.airLineForm.flyMode = '';
      this.airLineForm.taskCharacter = '';
      this.airLineForm.lines = [];
      this.airLineForm.linesData = [];
      this.airLineForm.startTime = '';
      this.airLineForm.endTime = '';
      console.log('this.flightLineIds', this.flightLineIds);
      let res = await API.AIRSPACE.getAirLineByIds(this.flightLineIds.toString());
      console.log('航线返回值', res);
      this.airLineList = [];
      for(let i=0; i<res.length; i++){
        let item = {
          index: i,
          flightName: res[i].name,
          deviceName: res[i].name,
          startTime: '2024.03.24 12:00:00',
          endTime:'2024.03.24 12:30:00',
          data:res[i].data
        };
        this.airLineList.push(item);
      }


      this.airLineList = [];
      let item = {
          index: 0,
          flightName: '科达生活区-老村村卫生室',
          deviceName: '联飞芜湖Q20#001',
          startTime: '2024.03.24 12:00:00',
          endTime:'2024.03.24 12:30:00',
          crossGridColor:'rgba(223, 70, 66, 0.7)',
          data:[
            {x: 118.585242, y:31.172578, z:10},
            {x: 118.582542, y:31.175578, z:50},
            {x: 118.589942, y:31.185578, z:80},
            {x: 118.585640, y:31.202001, z:10}
          ]
      };
      let item1 = {
          index: 1,
          flightName: '蟠龙村-水坝口',
          deviceName: '联飞芜湖Q20#001',
          startTime: '2024.03.24 12:00:00',
          endTime:'2024.03.24 12:30:00',
          crossGridColor:'rgba(26, 191, 239, 0.7)',
          data:[
            {x: 118.573942, y:31.183578, z:10},
            {x: 118.574942, y:31.183278, z:300},
            {x: 118.584942, y:31.188278, z:400},
            {x: 118.614942, y:31.195501, z:110}
          ]
      };
      let item2 = {
          index: 2,
          flightName: '春城花园-松元村',
          deviceName: '联飞芜湖Q20#002',
          startTime: '2024.03.24 12:00:00',
          endTime:'2024.03.24 12:30:00',
          crossGridColor:'rgba(252, 192, 17, 0.7)',
          data:[
            {x: 118.593842, y:31.167078, z:10},
            {x: 118.613242, y:31.185801, z:110}
          ]
      };

      /**
       * 空域id 258, 100*100网格对应航线
       * **/
      let item3 = {
          index: 0,
          flightName: '山旁吴-六甲村',
          deviceName: '联飞芜湖Q20#001',
          startTime: '2024.03.24 12:00:00',
          endTime:'2024.03.24 12:30:00',
          crossGridColor:'rgba(223, 70, 66, 0.7)',
          data:[
            { "x": 118.60236954, "y": 31.25247333, "z": 10 },
            { "x": 118.62921304, "y": 31.25693241, "z": 50 },
            { "x": 118.64416649, "y": 31.26090675, "z": 80 },
            { "x": 118.64697532, "y": 31.27377892, "z": 10},
          ]
      };
      let item4 = {
          index: 1,
          flightName: '大山头-晏公庙',
          deviceName: '联飞芜湖Q20#001',
          startTime: '2024.03.24 13:00:00',
          endTime:'2024.03.24 13:30:00',
          crossGridColor:'rgba(223, 70, 66, 0.7)',
          data:[
            { "x": 118.61904863, "y": 31.23009781, "z": 10},
            { "x": 118.6237328,  "y": 31.24244229, "z": 410},
            { "x": 118.61496411, "y": 31.26158944, "z": 620},
            { "x": 118.61291932, "y": 31.28147942, "z": 10}
          ]
      };
      let item5 = {
          index: 2,
          flightName: '土地砠-研坝',
          deviceName: '联飞芜湖Q20#001',
          startTime: '2024.03.24 12:00:00',
          endTime:'2024.03.24 12:30:00',
          crossGridColor:'rgba(252, 192, 17, 0.7)',
          data:[
            { "x": 118.63358856, "y": 31.21604192, "z": 10},
            { "x": 118.64862394, "y": 31.23548634, "z": 500},
            { "x": 118.66601109, "y": 31.26087973, "z": 10}
          ]
      };
      // this.airLineList.push(item);
      // this.airLineList.push(item1);
      // this.airLineList.push(item2);
      this.airLineList.push(item3);
      this.airLineList.push(item4);
      this.airLineList.push(item5);
    },
    /**@Description :  ******************************************* 
     **@Date: 2024-04-10 13:50:48
    */
    handleGridsAriLineSubmit(){
      this.gridFormDialogVisible = false;
      this.airLineForm.linesData = [];
      for(let i=0; i<this.airLineForm.lines.length; i++){
        this.airLineForm.linesData.push(this.airLineList[i]);
      }
      console.log('this.airLineForm', this.airLineForm);
      
      EventBus.$emit('grid-airlines', this.airLineForm);
    },
    /**@Description :  ******************************************* 清除网格，航线，弹出框
     **@Date: 2024-04-10 17:03:07
    */
    handleClearGridClick(){
      EventBus.$emit('grid-airlines-clearAll');
    },
    /**@Description :  ******************************************* 跳转到网格码单页面
     **@Date: 2024-04-25 10:25:07
    */
    handleGoToGridPage(){
      const { href } = this.$router.resolve({
        path: '/grids'
      });
      window.open(href, '_blank');
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  //height: 385px;
  height: 100%;
  background-color: rgba(35, 47, 79, 0.75);
  //border-radius: 12px;
  color: #fff;
  font-family: PingFangSC-Semibold, PingFang SC;
  background: linear-gradient(360deg, #14203E 0%, rgba(28, 74, 93, 0.91) 100%);
  position: relative;

  // 四个角标
  .corn {
    width: 22px;
    height: 20px;
    //border: 1px solid #00F5FF;
    position: absolute;
  }

  .corn1 {
    top: 0;
    left: 0;
    background-image: url("~@/assets/img/corn1.png");
    background-repeat: no-repeat;
  }

  .corn2 {
    top: 0;
    right: 0;
    background-image: url("~@/assets/img/corn1.png");
    background-repeat: no-repeat;
    transform: rotate(90deg);
    //back
  }

  .corn3 {
    bottom: 0;
    left: 0;
    background-image: url("~@/assets/img/corn3.png");
    background-repeat: no-repeat;
  }

  .corn4 {
    bottom: 0;
    right: 0;
    background-image: url("~@/assets/img/corn3.png");
    background-repeat: no-repeat;
    transform: rotate(-90deg);
  }
}

.head {
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  //padding: 0 12px 0 12px;
  align-items: center;
  //background-image: url("~@/assets/zong_lan_ye_img/kuang_title_bg.png");
  background-repeat: no-repeat;
  background-size: 274px 42px;
  border-bottom: 1px solid #474772;
  position: relative;

  .title {
    font-size: 18px;
    line-height: 28px;
    margin-left: 14px;
    font-weight: 600;
    height: 100%;
    width: 30%;
    background-image: url("~@/assets/img/title.png");
    background-repeat: no-repeat;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #FFFFFF;
    display: flex;
    align-items: center;
  }
  .grid-single{
    width: 35px;
    height: 40px;
    cursor: pointer;
    position: absolute;
    left: 130px;
    margin-top: -10px;
    // background-image: url("~@/assets/img/i158.png");
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
    // display:flex;
    // align-items: flex-start;
    // justify-content: center;
    // background-color: brown;
    font-size: 14px;
    padding-top: 11px;
    box-sizing: border-box;
  }
  .grid-img{
    width: 96px;
    height: 60px;
    cursor: pointer;
    position: absolute;
    right: 85px;
    margin-top: 20px;
    background-image: url("~@/assets/img/i157.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display:flex;
    align-items: flex-start;
    justify-content: center;
    // background-color: brown;
    font-size: 14px;
    padding-top: 11px;
    box-sizing: border-box;
  }
  .grid-img-clear{
    width: 18px;
    height: 18px;
    cursor: pointer;
    position: absolute;
    right: 60px;
    margin-top: 2px;
  }
  .more {
    font-size: 14px;
    line-height: 25px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;

    img {
      margin-left: 3px;
      width: 11px;
      height: 17px;
    }
  }
}

.flex {
  display: flex;
}

.nei {
  width: 100%;
  height: calc(100% - 42px);
  //padding: 19px 10px 5px 11px;
  padding: 0px 10px 5px 11px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.particulars {
  width: 100%;
  height: 45%;
  justify-content: space-around;
  align-items: center;
  position: relative;

  #particularsChart {
    width: 100%;
    height: 100%;
  }

  .card {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 30%;
    width: 30%;

    .text {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-left: 15px;

      .num {
        font-size: 16px;
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
        color: #FFFFFF;
      }

      .name {
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        margin-top: 5px;
      }
    }
  }

  .card1 {
    top: 20%;
    right: 10%;

  }

  .card2 {
    top: 55%;
    right: 10%;
    //border: 1px solid red;
  }

  .Approval_Pending_img {
    width: 47px;
    height: 49px;
    margin: 0 20px 0 0;
  }

  .Approval_Pending_font {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.Course_approval {
  width: 100%;
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  margin: 10px 0 0 0;
  box-sizing: border-box;
  padding: 0 0 0px 0;

  .Course_approval_template {
    //width: 388px;
    // height: 78%;
    //background: #263257;
    background: rgba(133, 199, 255, 0.1);
    border-radius: 4px;
    box-sizing: border-box;
    //padding: 10px 14px 18px 14px !important;
    padding: 10px 14px 10px 14px !important;
    margin: 0 0 8px 0 !important;
  }

  .drone_pilot {
    flex-direction: column;
    margin: 8px 0 8px 0;
  }

  .item-span-text {
    font-size: 12px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 17px;
    opacity: 0.5;

    .lable {
      letter-spacing: 3px;
    }
  }

  .unit {
    justify-content: space-between;

    .btn {
      width: 53px;
      height: 24px;
      background: rgba(1, 109, 255, 0.85);
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5),
        inset 0px 1px 0px 0px rgba(255, 255, 255, 0.29);
      border-radius: 2px;
      cursor: pointer;
      font-size: 14px;
      text-align: center;
      line-height: 24px;
    }

    .btn_lv {
      background-image: url("~@/assets/images/home/btn_green.svg");
    }
  }
}

.no-data {
  text-align: center;
  color: #fff;
  min-height: 200px;
  line-height: 200px;
}

.fontThree {
  font-size: 16px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #ffffff;
  line-height: 19px;
}

.fontFour {
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 17px;
  opacity: 0.5;
}

.fontSix {
  width: 340px;
  //overflow: hidden;
  // text-overflow: ellipsis;
  // text-align: center;
  white-space: nowrap;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 20px;
  letter-spacing: 1px;
}

</style>
<style lang="scss">
.grid-form-fialog-class{
  position: fixed !important;
  top: -5% !important;
  left: 35% !important;
  // border: 1px solid rgb(97, 97, 94) !important;
  background-color: #021B30 !important;
  background-color: #0A293A !important;
  .el-dialog__body{
    flex-direction: column;
    .text-header{
      font-size: 20px;
      font-weight: 600;
      color: #ffffff;
      box-sizing: border-box;
      padding: 10px;
      display:flex;
      align-items: center;
      justify-content: space-between;
      background-color: #224D68;
      height: 36px;
      font-size: 16px;
      color: #FFFFFF;
      font-weight: 600;
      img{
        width: 24px;
        height: 24px;
        cursor: pointer;
      }

    }
    .form-c{
      width: 100%;
      margin-top: 20px;
      box-sizing: border-box;
      // padding: 0 25px 0 15px;
      padding: 0 5% 0 5%;
      .el-form-item__label{
        color: #ffffff;
      }
      .el-textarea__inner,.el-input__inner{
        background-color: #133242;
        border: #868686;
        color: #ffffff;
      }
      .el-tag--light{
        background-color: cadetblue;
        color: #ffffff;
      }
      .el-form-item{
        margin-bottom: 14px;
      }
    }
  }
  .footer{
      .btn-submit{
        background-color: #0463E3 !important;
        color: #ffffff;
      }
    }
  
}
</style>
