<template>
  <div class="cpt-home-popup">
    <div class="message-box">
      <img
        src="~@/assets/images/accident/close.png"
        class="img-close"
        @click="handle_close"
      />
      <div class="msg-header" v-interact>{{ title[active] }}</div>
      <div class="table" v-show="active == 1">
        <div class="tb-hd">
          <div class="td">事件内容</div>
          <div class="td">警情类型</div>
          <div class="td">执行状态</div>
          <div class="td">责任单位</div>
          <div class="td">飞手</div>
        </div>
        <div class="tb-bd">
          <div v-for="item in list_situation" :key="item.id" class="tr">
            <div class="td">{{ item.name || '暂无' }}</div>
            <div class="td">{{ item.typeTitle || '暂无' }}</div>
            <div class="td">{{ get_status(item) || '未知' }}</div>
            <div class="td">{{ item.policeUnitTitle || '暂无' }}</div>
            <div class="td">
              {{
                item.team && item.team.length ? item.team.join('、') : '暂无'
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="table" v-show="active == 2">
        <div class="tb-hd">
          <div class="td">任务名称</div>
          <div class="td">任务类型</div>
          <div class="td">执行状态</div>
          <div class="td">责任单位</div>
          <div class="td">飞手</div>
        </div>
        <div class="tb-bd">
          <div v-for="item in list_normal" :key="item.id" class="tr">
            <div class="td">{{ item.taskTitle || '暂无' }}</div>
            <div class="td">{{ item.taskCateTitle || '暂无' }}</div>
            <div class="td">{{ get_status(item) || '未知' }}</div>
            <div class="td">{{ item.organizationTitle || '暂无' }}</div>
            <div class="td">
              {{
                item.team && item.team.length ? item.team.join('、') : '暂无'
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="table" v-show="active == 3">
        <div class="tb-hd">
          <div class="td">任务名称</div>
          <div class="td">任务类型</div>
          <div class="td">执行状态</div>
          <div class="td">责任单位</div>
          <div class="td">飞手</div>
        </div>
        <div class="tb-bd">
          <div v-for="item in list_urgent" :key="item.id" class="tr">
            <div class="td">{{ item.taskTitle || '暂无' }}</div>
            <div class="td">{{ item.taskCateTitle || '暂无' }}</div>
            <div class="td">{{ get_status(item) || '未知' }}</div>
            <div class="td">{{ item.organizationTitle || '暂无' }}</div>
            <div class="td">
              {{
                item.team && item.team.length ? item.team.join('、') : '暂无'
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="table" v-show="active == 4">
        <div class="tb-hd">
          <div class="td">任务名称</div>
          <div class="td">任务类型</div>
          <div class="td">责任单位</div>
          <div class="td">飞手</div>
        </div>
        <div class="tb-bd">
          <div v-for="item in list_audit" :key="item.id" class="tr">
            <div class="td">{{ item.taskTitle || '暂无' }}</div>
            <div class="td">{{ item.taskTypeName || '暂无' }}</div>
            <div class="td">{{ item.organizationName || '暂无' }}</div>
            <div class="td">{{ item.flyName || '暂无' }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api'

export default {
  data() {
    return {
      title: {
        1: '警情事件',
        2: '常态任务巡查',
        3: '临时紧急任务',
        4: '待审批',
      },
      active: 1,
      list_situation: [],
      list_normal: [],
      list_urgent: [],
      list_audit: [],
    }
  },
  mounted() {
    this.get_list_situation()
    this.get_list_normal()
    this.get_list_urgent()
    this.get_list_audit()
  },
  methods: {
    handle_change(item) {
      this.$emit('handleShow', true)
      this.active = item
    },
    handle_close() {
      this.$emit('handleShow', false)
    },
    async get_list_situation() {
      let res = await API.TASK.List({ pageSize: 10000, cate: 4 })
      this.list_situation = res.data || []
    },
    async get_list_normal() {
      let res = await API.TASK.List({ pageSize: 10000, cate: 3, type: 0 })
      this.list_normal = res.data || []
    },
    async get_list_urgent() {
      let res = await API.TASK.List({ pageSize: 10000, cate: 3, type: 1 })
      this.list_urgent = res.data || []
    },
    async get_list_audit() {
      let res = await API.TASK.ListAudit()
      this.list_audit = res || []
    },
    get_status(item) {
      if (item.taskSituation == -1) {
        item.taskSituation = 5
      }
      const obj = {
        0: '待审批',
        1: '审批不通过',
        2: '待执行',
        3: '执行中',
        4: '已完成',
        5: '待派发',
      }
      return obj[item.taskSituation]
    },
  },
}
</script>

<style lang="scss" scoped>
.cpt-home-popup {
  position: absolute;
  top: 115px;
  left: 500px;
  z-index: 1000;
}
.message-box {
  width: 547px;
  height: 414px;
  background-color: #161d2d;
  box-sizing: border-box;
  border: 1px solid #00ffff;
  border-radius: 4px;
  padding-bottom: 10px;
  .img-close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  .msg-header {
    font-family: MicrosoftYaHei-Bold;
    color: #00ffff;
    text-align: center;
    font-weight: 700;
    height: 48px;
    line-height: 48px;
  }
  .tabs {
    display: flex;
    justify-content: space-between;
    .tab-box {
      height: 47px;
      flex: 1;
      text-align: center;
      line-height: 47px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #129c9c;
      cursor: pointer;
      border-radius: 4px 0px 0px 0px;
      &:hover {
        color: #00ffff;
      }
    }
    .tab-box.active {
      background: #123f4e;
      font-weight: bold;
      color: #00ffff;
    }
  }
  .table {
    height: calc(100% - 50px);
    padding: 0 10px;
  }
  .tb-hd {
    display: flex;
    .td {
      flex: 1;
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #43c7f0;
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;
      text-align: center;
      line-height: 30px;
    }
  }
  .tb-bd {
    width: 100%;
    overflow: auto;
    height: calc(100% - 30px);
    .tr {
      display: flex;
      min-height: 59px;
      align-items: center;
      &:nth-child(odd) {
        background: #2b3857;
      }
      &:nth-child(even) {
        background: #1e2a45;
      }
      .td {
        flex: 1;
        text-align: center;
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #9bffff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        word-break: break-all;
        &.ope {
          color: #6aff64;
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
</style>
