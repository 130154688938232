<!-- 值班信息 -->
<template>
  <div class="beOnDuty">
    <div class="pup_head">
      <div class="flex">
        <div class="pup_img">
          <img
            src="~@/assets/zong_lan_ye_img/jinye.png"
            style="width: 100%; height: 100%"
            alt
          />
        </div>
        <div class="fontOne">无人机值班领导及责任民警一览表</div>
      </div>
      <div class="flex">
        <div class="flex"></div>
        <div class="fontTwo" @click="pupClose">关闭</div>
      </div>
    </div>
    <div class="nei">
      <div class="kuang">
        <div class="tr">
          <div class="center">辖区</div>
          <div class="center">驻点名称</div>
          <div class="center">责任领导</div>
          <div class="center">责任民警</div>
          <!-- <div class=" center">值班辅警</div> -->
        </div>
        <div class="contain" v-infinite-scroll="load">
          <div
            class="dutybox mt10 addInput"
            v-for="(item, index) in dutyInfo"
            :key="index"
          >
            <div>{{ item.xq }}</div>
            <div>{{ item.address }}</div>
            <div>{{ item.ld }}</div>
            <div>{{ item.mj }}</div>
            <!-- <div >{{ item.fj }}</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dutyInfo: [
        {
          xq: "盐南高新区分局",
          address: "新都派出所",
          ld: "杨红明18205100079",
          mj: "柏旸15298586032",
          fj: "暂无",
        },
        {
          xq: "盐南高新区分局",
          address: "科城派出所",
          ld: "冯臣阁18305100522",
          mj: "陈馨缘19532466756",
          fj: "暂无",
        },
        {
          xq: "盐南高新区分局",
          address: "新河派出所",
          ld: "周健13961902266",
          mj: "刘育辉15161989180",
          fj: "暂无",
        },
        {
          xq: "盐南高新区分局",
          address: "情报指挥室",
          ld: "周毅15862041117",
          mj: "陶广龙15195526110",
          fj: "暂无",
        },
        {
          xq: "盐南高新区分局",
          address: "黄海派出所",
          ld: "曹学存15261995166",
          mj: "　　　孙思宇15050669917　　　　祁庆庆18261900009",
          fj: "暂无",
        },
        {
          xq: "盐南高新区分局",
          address: "新河警务站",
          ld: "刘中存19850983903",
          mj: "王冽15950220528",
          fj: "暂无",
        },
        {
          xq: "盐南高新区分局",
          address: "聚龙湖警务站",
          ld: "陆敢15905108809",
          mj: "　　　刘文才13626217292　　　　 曾凯15326296355",
          fj: "暂无",
        },
        {
          xq: "盐南高新区分局",
          address: "伍佑派出所",
          ld: "董飞13770025398",
          mj: "吴坚18888100786",
          fj: "暂无",
        },
        {
          xq: "盐南高新区分局",
          address: "猎豹摩托队",
          ld: "周毅15862041117",
          mj: "姚海荣18305100272",
          fj: "暂无",
        },
        {
          xq: "亭湖分局",
          address: "巡特警大队",
          ld: "郑相国18351289302",
          mj: "　　　　徐荣13914642119　　　　　赵宇晨18361064598",
          fj: "暂无",
        },
        {
          xq: "亭湖分局",
          address: "文峰派出所",
          ld: "施辉东18205106380",
          mj: "张中杰19851555560",
          fj: "暂无",
        },
        {
          xq: "亭湖分局",
          address: "先锋派出所",
          ld: "薛荣林17205157738",
          mj: "钱啸18205106602",
          fj: "暂无",
        },
        {
          xq: "亭湖分局",
          address: "毓龙派出所",
          ld: "姜海洋18851559993",
          mj: "曹伟峰18862019009",
          fj: "暂无",
        },
        {
          xq: "亭湖分局",
          address: "五星派出所",
          ld: "孙宗财15251107205",
          mj: "李德成15895188828",
          fj: "暂无",
        },
        {
          xq: "亭湖分局",
          address: "大洋派出所",
          ld: "姚广军15195144166",
          mj: "李嘉15851058625",
          fj: "暂无",
        },
        {
          xq: "亭湖分局",
          address: "新洋派出所",
          ld: "郭凯18861995542",
          mj: "张文卓18205106925",
          fj: "暂无",
        },
        {
          xq: "亭湖分局",
          address: "东城派出所",
          ld: "单爱华15295367771",
          mj: "李健13814383110",
          fj: "暂无",
        },
        {
          xq: "亭湖分局",
          address: "便仓派出所",
          ld: "陶海清13961976906",
          mj: "黄鑫18352009156",
          fj: "暂无",
        },
        {
          xq: "亭湖分局",
          address: "南洋派出所",
          ld: "张健15205106919",
          mj: "王昕15950292963",
          fj: "暂无",
        },
        {
          xq: "亭湖分局",
          address: "盐东派出所",
          ld: "吕益标18860822385",
          mj: "吕益标18860822385",
          fj: "暂无",
        },
        {
          xq: "亭湖分局",
          address: "黄尖派出所",
          ld: "徐林18936290988",
          mj: "徐林18936290988",
          fj: "暂无",
        },
        {
          xq: "亭湖分局",
          address: "青墩派出所",
          ld: "征志伟19827069570",
          mj: "征志伟19827069570",
          fj: "暂无",
        },
        {
          xq: "亭湖分局",
          address: "环科城派出所",
          ld: "郑盛15205100686",
          mj: "顾宇成13962366756",
          fj: "暂无",
        },
        {
          xq: "亭湖分局",
          address: "永丰派出所",
          ld: "张会会19851582066",
          mj: "杨瑞杰13770231782",
          fj: "暂无",
        },
        {
          xq: "亭湖分局",
          address: "新兴派出所",
          ld: "陈兆阳15051085916",
          mj: "陈兆阳15051085916",
          fj: "暂无",
        },
        {
          xq: "亭湖分局",
          address: "新洋港派出所",
          ld: "李前飞13912555695",
          mj: "任太育13814339523",
          fj: "暂无",
        },
        {
          xq: "亭湖分局",
          address: "中路港派出所",
          ld: "贺庭18248777928",
          mj: "陈伟伟18262351536",
          fj: "暂无",
        },
        {
          xq: "亭湖分局",
          address: "铜马警务站",
          ld: "曾鹏18205100581",
          mj: "熊瑛566493",
          fj: "暂无",
        },
        {
          xq: "亭湖分局",
          address: "鹤翔警务站",
          ld: "路伟民15151070765",
          mj: "周亚婷566134",
          fj: "暂无",
        },
        {
          xq: "亭湖分局",
          address: "新洋港警务站",
          ld: "陆红俊18751467020",
          mj: "喻发566430",
          fj: "暂无",
        },
        {
          xq: "经开区分局",
          address: "治安大队",
          ld: "陈井元18205106921",
          mj: "陈伯军18862035811",
          fj: "暂无",
        },
        {
          xq: "经开区分局",
          address: "新城派出所",
          ld: "金鑫15962077127",
          mj: "陈旦一15862011304",
          fj: "暂无",
        },
        {
          xq: "经开区分局",
          address: "步凤派出所",
          ld: "殷红亮18861985678",
          mj: "杨奕伟18862007212",
          fj: "暂无",
        },
        {
          xq: "经开区分局",
          address: "未来科技城警务工作站",
          ld: "李大勇18205107999",
          mj: "朱正夫18305105818",
          fj: "暂无",
        },
        {
          xq: "经开区分局",
          address: "巡特警大队（绿叶警务室）",
          ld: "李大勇18205107999",
          mj: "刘伟18252212619",
          fj: "暂无",
        },
        {
          xq: "经开区分局",
          address: "情报指挥室（办公室）",
          ld: "何建15005110911",
          mj: "周星宇18851559661",
          fj: "暂无",
        },
        {
          xq: "经开区分局",
          address: "刑警大队",
          ld: "孙源源15950232223",
          mj: "蔡林祥15251109802",
          fj: "暂无",
        },

        {
          xq: "盐都区分局",
          address: "巡特警大队",
          ld: "吴建荣286353",
          mj: "曾志兵286302",
          fj: "暂无",
        },
        {
          xq: "盐都区分局",
          address: "北京试飞8组1队",
          ld: "李立超286076",
          mj: "曾亚286403",
          fj: "暂无",
        },
        {
          xq: "盐都区分局",
          address: "盐渎派出所吾悦警务站",
          ld: "曹剑286453",
          mj: "李亚男286212",
          fj: "暂无",
        },
        {
          xq: "盐都区分局",
          address: "盐龙派出所高新区警务站",
          ld: "马力286385",
          mj: "殷昌瓯286072",
          fj: "暂无",
        },
        {
          xq: "盐都区分局",
          address: "龙冈派出所",
          ld: "陈华杰286029",
          mj: "吴亮亮286204",
          fj: "暂无",
        },
        {
          xq: "盐都区分局",
          address: "大冈派出所",
          ld: "陈连锋286028",
          mj: "王玉东286145",
          fj: "暂无",
        },
        {
          xq: "盐都区分局",
          address: "张庄派出所",
          ld: "陈连红286370",
          mj: "于静286283",
          fj: "暂无",
        },
        {
          xq: "盐都区分局",
          address: "郭猛派出所",
          ld: "刘荣海286363",
          mj: "刘荣海286363",
          fj: "暂无",
        },
        {
          xq: "盐都区分局",
          address: "楼王派出所",
          ld: "胥辉286321",
          mj: "孙彬彬286379",
          fj: "暂无",
        },
        {
          xq: "盐都区分局",
          address: "大纵湖派出所",
          ld: "孙云才286355",
          mj: "孙云才286355",
          fj: "暂无",
        },
        {
          xq: "盐都区分局",
          address: "学富派出所",
          ld: "皱玉键286365",
          mj: "徐剑286457",
          fj: "暂无",
        },
        {
          xq: "盐都区分局",
          address: "秦南派出所",
          ld: "曹德鑫286103",
          mj: "赵中杰286136",
          fj: "暂无",
        },
        {
          xq: "盐都区分局",
          address: "尚庄派出所",
          ld: "徐焕286348",
          mj: "徐焕286348",
          fj: "暂无",
        },
        {
          xq: "盐都区分局",
          address: "大纵湖旅游度假区派出所",
          ld: "徐学军286319",
          mj: "徐学军286319",
          fj: "暂无",
        },
        {
          xq: "盐都区分局",
          address: "葛武派出所",
          ld: "陆磊286489",
          mj: "王国华286172",
          fj: "暂无",
        },
        {
          xq: "盐都区分局",
          address: "情报指挥室",
          ld: "智玉超286150",
          mj: "智玉超286150",
          fj: "暂无",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  mounted() {},
  methods: {
    pupClose() {
      this.$emit("handleClose");
    },
  },
};
</script>

<style scoped lang="scss">
.beOnDuty {
  width: 850px;
  height: 574px;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);
}

.WorkLog_img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.flex {
  display: flex;
}

.pup_head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 16px 0 12px;
  align-items: center;
  height: 32px;
  background: linear-gradient(
    180deg,
    #9198ff 0%,
    rgba(45, 81, 153, 0.45) 40%,
    #05091a 100%
  );
  box-shadow: inset 0px 0px 10px 2px #3f9dff;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #427dff;

  .pup_img {
    display: flex;
    width: 19px;
    height: 25px;
    margin: 0 4px 0 0;
  }

  .fontOne {
    font-size: 20px;
    font-family: YouSheBiaoTiHei;
    color: #14faff;
    line-height: 26px;
    text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
    background: linear-gradient(
      135deg,
      #e3aa77 0%,
      #f5cda9 38%,
      #f9ecd3 58%,
      #fcdbb1 79%,
      #edb07a 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .fontTwo {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #d2dfff;
    cursor: pointer;
    line-height: 20px;
  }
}

.nei {
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
}

.kuang {
  width: 830px;
  height: 518px;
  background: rgba(8, 26, 58, 0.8);
  border-radius: 4px;
  border: 1px solid #315ec7;
  box-sizing: border-box;
  padding: 4px 4px 4px 10px;
}

.tr {
  width: 100%;
  height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-family: YouSheBiaoTiHei;
  color: #ebf9ff;
  line-height: 22px;
  div {
    width: calc(100% / 4);
  }
}

.th_tt {
  width: 100px;
}

.td {
  /deep/.el-input__suffix {
    display: none;
  }
}

.icon-baocun {
  font-size: 23px;
  color: #00ffff;
}

.focus {
  pointer-events: none;
  color: #92d9ff;
}

.contain {
  height: 462px;
  overflow: hidden;
  overflow-y: scroll;

  .dutybox:nth-child(odd) {
    background: rgba(73, 135, 210, 0.2);
  }

  .dutybox:nth-child(even) {
    background: transparent;
  }
}

.dutybox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    text-align: center;
    font-size: 12px;
    line-height: 30px;
    min-height: 30px;
    color: #92d9ff;
    width: calc(100% / 4);
  }
}

.infobox {
  position: fixed;
  left: 35%;
  top: 200px;
  width: 683px;
  height: 348px;
  background: rgba(22, 29, 45, 0.9);
  border: 1px solid #009fff;
  box-shadow: inset 0 0 7px 0 #008fff;
  border-radius: 10px;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #009fff;
}

/deep/.el-input__prefix {
  top: -6px;
}

/deep/ .el-input__suffix {
  top: -6px;
}

::v-deep {
  .timeDate_input {
    border: 1px solid #00b6ff;
    box-shadow: inset 0 0 3px 0 #00b6ff;
    border-radius: 4px;
    width: 184px;
    height: 27px;

    .el-input__inner {
      height: 27px;
    }
  }

  .el-input .el-input__clear {
    line-height: 30px;
  }
}
/deep/ .el-select-dropdown {
  left: auto !important;
  top: auto !important;
}
</style>
