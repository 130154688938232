<!-- 勤务等级 -->
<template>
    <div class="grade">
        <div class="pup_head">
            <div class="flex">
                <div class="pup_img">
                    <img src="~@/assets/zong_lan_ye_img/jinye.png" style="width:100%;height:100%;" alt="">
                </div>
                <div class="fontOne">勤务等级</div>
            </div>
            <div class="fontTwo" @click="pupClose">关闭</div>
        </div>
        <div class="nei">
            <div class="font_Three">{{this.$store.state.home.startGrade == 1 ? '平台是否进入战时状态？' : '平台是否退出战时状态？'}}</div>
            <div class="flex btn">
                <div class="btn_lv mr62" @click="pupClose">取消</div>
                <div class="btn_lan" @click="setPoliceWorkLevel">确认</div>
            </div>
        </div>
    </div>
</template>

<script>
import API from "@/api";
import { mapActions, mapGetters } from "vuex";
export default {
    data() {
        return {
            type: 1,
            id:null,
        }
    },
    computed: {
        ...mapGetters(["user_info"])
    },
    mounted() {
        this.id = this.user_info.departmentId;
    },
    methods: {
        // 勤务等级
        async setPoliceWorkLevel() {
            // console.log(this.id);
              if (this.$store.state.home.startGrade == 1) {
                    this.type = 2;
                } else {
                    console.log(this.type,'this.typethis.typethis.type');
                    this.type = 1;
                }
                let res = await API.HOME.setPoliceWorkLevel({
                    id: 1,
                    type: this.type
                });
           this.pupClose()
        },
        pupClose(item) {
            this.$emit('handleClose', 'grade')
        },
    }
}
</script>

<style  scoped lang="scss">
.grade {
    width: 468px;
    height: 196px;
    background: rgba(12, 34, 73, 0.7);
    border-radius: 10px;
    border: 1px solid rgba(26, 92, 246, 0.5);
    backdrop-filter: blur(1px);
}

.flex {
    display: flex;
}

.pup_head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 16px 0 12px;
    align-items: center;
    height: 32px;
    background: linear-gradient(180deg, #9198FF 0%, rgba(45, 81, 153, 0.45) 40%, #05091A 100%);
    box-shadow: inset 0px 0px 10px 2px #3F9DFF;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427DFF;

    .pup_img {
        display: flex;
        width: 19px;
        height: 25px;
        margin: 0 4px 0 0;
    }

    .fontOne {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14FAFF;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(135deg, #E3AA77 0%, #F5CDA9 38%, #F9ECD3 58%, #FCDBB1 79%, #EDB07A 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .fontTwo {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #D2DFFF;
        cursor: pointer;
        line-height: 20px;
    }
}

.nei {
    width: 100%;
    box-sizing: border-box;
    padding: 36px 0 28px 0;
    height: calc(100% - 32px);

}

.btn {
    margin: 44px 0 0 0;
    justify-content: center;
}

.btn_lv {
    width: 90px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #E0F6FF;
    background-image: url("~@/assets/zong_lan_ye_img/btn_lv.png");
    background-size: 100% 100%;
    cursor: pointer;
}

.btn_lan {
    width: 90px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #E0F6FF;
    background-image: url("~@/assets/zong_lan_ye_img/btn_lan.png");
    background-size: 100% 100%;
    cursor: pointer;
}

.font_Three {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;

}
</style>