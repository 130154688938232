import API from "@/api"

export default {

    /**
     * 待办事项
     * @param {*} params 
     */
    async stat_all(params) {
        let data = await API.DEVICE.Stat(params)

        data.forEach(item => {
            if(item.type == 1){
                item.count = 120
            }
            if(item.type == 2){
                item.count = 11
            }
            if(item.type == 3){
                item.count = 0
            }
            if(item.type == 4){
                item.count = 15
            }
        })
        this.device.stat.all = data || []
    },
}