<!-- 安全预警 -->
<template>
  <div class="early_warning">
    <div class="pup_head">
      <div class="flex">
        <!-- <div class="pup_img"> -->
        <!-- <img src="~@/assets/zong_lan_ye_img/jinye.png" style="width:100%;height:100%;" alt=""> -->
        <!-- </div> -->
        <div class="fontOne">安全预警</div>
      </div>
      <div class="fontTwo" @click="pupClose">
        <img src="@/assets/img/i75.png" alt="" class="img">
      </div>
    </div>
    <div class="nei">
      <!-- 这里占时隐藏 -->
      <div class="mb12 flex warning_btn" style="height:0;overflow: hidden;">
        <div class="early_warning_btn">批量通知</div>
      </div>
      <div class="kuang">
        <div class="flex tb kuangItem ">
          <div>预警类型</div>
          <div class="th_td">预警内容</div>
          <div class="w145">预警时间</div>
          <div>所属单位</div>
          <div class="line_width">操作</div>
        </div>
        <div class="th">
          <div class="th_tr flex" v-for="(item, index) in list" :key="index">
            <div>{{ item.typeName || '暂无' }}</div>
            <el-tooltip class="item" effect="dark" :content="item.content" placement="top-start" :enterable="false">
              <div class="th_td">{{ item.content || '暂无' }}</div>
            </el-tooltip>
            <div class="w145">{{ item.createdTime || '暂无' }}</div>
            <div>{{ item.orgName || '暂无' }}</div>
            <div class="line_width ">
              <div class="btn_shen ml11 btn_shen1" @click="btn_WarningNotice(item)" v-show="item.isNotice == 0">通知</div>
              <div class="btn_shen ml11 btn_shen2" v-show="item.isNotice == 1">已通知</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹框 -->
    <el-dialog :visible.sync="dialogVisible" :modal="false" :destroy-on-close="true">
      <div class="pup_style">
        <WarningNotice @WarningNoticeClose="WarningNoticeClose" :modifyData="modifyData" @getlist='getlist'>
        </WarningNotice>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Api from "@/api/modules/newOverview.js";
// 预警通知
import WarningNotice from './Warning_notice/index.vue'
export default {
  components: {
    WarningNotice
  },
  data () {
    return {
      checked: null,
      dialogVisible: false,
      list: [],
      modifyData: null,//修改数据
    }
  },
  mounted () {
    this.getlist()
  },
  methods: {
    async getlist () {
      let res = await Api.ist()
      this.list = res
      // console.log(res, 'istist');
    },
    WarningNoticeClose () {
      this.dialogVisible = false
    },
    btn_WarningNotice (item) {
      this.modifyData = item
      this.dialogVisible = true
    },
    pupClose () {
      this.$emit('handleClose', 'early_warning')
    },
  }
}
</script>

<style  scoped lang="scss">
.early_warning {
  width: 700px;
  height: 318px;
  height: auto;
  // background: rgba(12, 34, 73, 0.7);
  // border-radius: 10px;
  // border: 1px solid rgba(26, 92, 246, 0.5);
  // backdrop-filter: blur(1px);
  position: fixed;
  top: 20%;
  left: 30%;
  box-sizing: border-box;
  background-color: #0A293A !important;
}

.flex {
  display: flex;
}

.pup_head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  // padding: 0 16px 0 12px;
  // align-items: center;
  // height: 32px;
  // background: linear-gradient(180deg, #9198FF 0%, rgba(45, 81, 153, 0.45) 40%, #05091A 100%);
  // box-shadow: inset 0px 0px 10px 2px #3F9DFF;
  // border-radius: 10px 10px 0px 0px;
  // border: 1px solid #427DFF;
  height: 36px;
  background-color: #224D68;
  box-sizing: border-box;
  padding: 0 5px;

  .pup_img {
    display: flex;
    width: 19px;
    height: 25px;
    margin: 0 4px 0 0;
  }

  .fontOne {
    font-size: 16px;
    color: #FFFFFF;
    font-weight: 600;
    // font-family: YouSheBiaoTiHei;
    // color: #14FAFF;
    // line-height: 26px;
    // text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
    // background: linear-gradient(135deg, #E3AA77 0%, #F5CDA9 38%, #F9ECD3 58%, #FCDBB1 79%, #EDB07A 100%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }

  .fontTwo {
    // font-size: 14px;
    // font-family: MicrosoftYaHei;
    // color: #D2DFFF;
    // cursor: pointer;
    // line-height: 20px;
    cursor: pointer;

    .img {
      width: 30px;
      height: 30px;
    }
  }
}

.nei {
  width: 100%;
  box-sizing: border-box;
  // padding: 12px;
  padding: 0 10px;
}

.kuang {
  width: 675px;
  height: 218px;
  height: auto;
  // background: rgba(8, 26, 58, 0.8);
  // border-radius: 4px;
  // border: 1px solid #315EC7;
  box-sizing: border-box;
  padding: 10px 0;


  .kuangItem {
    div {
      width: calc(100% / 5);
    }
  }

  .tb {
    width: 663px;
    height: 42px;
    box-sizing: border-box;
    // justify-content: space-between;
    align-items: center;
    font-size: 16px;
    text-align: center;
    font-family: YouSheBiaoTiHei;
    color: #EBF9FF;
    letter-spacing: 1px;

    .tb_tr {
      width: 120px;
      text-align: center;
    }
  }

  .th {
    width: 100%;
    // height: 168px;
    max-height: 200px;
    overflow-y: auto;

    .th_tr:nth-child(odd) {
      background: rgba(73, 135, 210, 0.2);
    }

    .th_tr:nth-child(even) {
      background: transparent;
    }

    .th_tr {
      box-sizing: border-box;
      width: 100%;
      text-align: center;
      height: 42px;
      align-items: center;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #EBF9FF;

      div {
        width: calc(100% / 5);
      }

      .th_td {
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .btn_yu {
        width: 80px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        font-size: 14px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #E0F6FF;
        background-image: url("~@/assets/zong_lan_ye_img/btn_lan.png");
        background-size: 100% 100%;
      }

      .btn_shen {
        width: 80px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        font-size: 14px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #E0F6FF;
        // background-image: url("~@/assets/zong_lan_ye_img/btn_lan.png");
        background-size: 100% 100%;
        // background-color: #4E87AC;
        border-radius: 4px;
        cursor: pointer;
      }

      .btn_shen1 {
        background-color: #344354;
      }

      .btn_shen2 {
        background-color: #0463E3;
      }
    }
  }

  .th::-webkit-scrollbar {
    height: 4px !important;
  }
}

.warning_btn {
  justify-content: end;
  width: 100%;

  .early_warning_btn {
    width: 92px;
    height: 32px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    line-height: 32px;
    text-align: center;
    // background-image: url("~@/assets/zong_lan_ye_img/btn_lan.png");
    background-size: 100% 100%;
    cursor: pointer;
    background-color: #4E87AC;
  }
}

.line_width {
  text-align: center;
  box-sizing: border-box;
  padding-left: 18px;
}

/deep/ .el-dialog {
  margin-top: 1vh !important;
}
</style>