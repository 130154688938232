<!-- 预警通知 -->
<template>
  <div class="Warning_notice">

    <div class="pup_head">
      <div class="flex">
        <!-- <div class="pup_img">
          <img src="~@/assets/zong_lan_ye_img/jinye.png" style="width:100%;height:100%;" alt />
        </div> -->
        <div class="fontOne">预警通知</div>
      </div>
      <div class="fontTwo" @click="WarningNoticeClose(modifyData)">
        <img src="@/assets/img/i75.png" alt="" class="img">
      </div>
    </div>
    <div class="nei">
      <div class="flex Warning_notice_text">
        <div class="fontThree">{{ modifyData.typeName }}:</div>
        <div class="fontThree">{{ modifyData.orgName }}</div>
      </div>
      <div class="richText">
        <div class="timer">{{ timer(modifyData.createdTime) }}</div>
        <el-input type="textarea" v-model="desc" :placeholder="modifyData.content" :disabled="true"></el-input>
      </div>
      <div class="WarningNotice_btn flex">
        <div class="btn" @click="ensure">立即通知</div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api/modules/newOverview.js";
export default {
  props: {
    modifyData: {
      type: Object,
      default: false
    }
  },
  data () {
    return {
      checked: null,
      desc: null,
      list: null
    };
  },
  watch: {
    modifyData: {
      handler (val, old) {
        this.list = val;
      },
      deep: true,
      immediate: true
    }
  },
  mounted () { },
  methods: {
    async ensure (item) {
      let res = await Api.update({
        waringId: item.waringId || this.list.waringId,
        isNotice: 1
      });
      if (res.code == 200) {
        let data = {
          waringId: item.waringId || this.list.waringId,
          content: item.content || this.list.content,
          orgId: item.orgId || this.list.orgId
        };
        let res2 = await Api.message(data);
        this.$message.success(res)
        this.WarningNoticeClose();
        this.$emit("getlist");
      }
    },
    timer (item) {
      let a = item.split(" ");
      return a[0];
    },
    WarningNoticeClose () {
      this.$emit("WarningNoticeClose");
    }
  }
};
</script>

<style  scoped lang="scss">
.Warning_notice {
  width: 468px;
  // height: 250px;
  background-color: #0A293A !important;
  // background: rgba(12, 34, 73, 0.7);
  // border-radius: 10px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  // backdrop-filter: blur(1px);
}

.flex {
  display: flex;
}

.pup_head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  // padding: 0 16px 0 12px;
  align-items: center;
  // height: 32px;
  // background: linear-gradient(
  //   180deg,
  //   #9198ff 0%,
  //   rgba(45, 81, 153, 0.45) 40%,
  //   #05091a 100%
  // );
  // box-shadow: inset 0px 0px 10px 2px #3f9dff;
  // border-radius: 10px 10px 0px 0px;
  // border: 1px solid #427dff;
  height: 36px;
  background-color: #224D68;
  box-sizing: border-box;
  padding: 0 5px;

  // .pup_img {
  //   display: flex;
  //   width: 19px;
  //   height: 25px;
  //   margin: 0 4px 0 0;
  // }

  .fontOne {
    // font-size: 20px;
    // font-family: YouSheBiaoTiHei;
    // color: #ffffff;
    font-size: 16px;
    color: #FFFFFF;
    font-weight: 600;
    // line-height: 26px;
    // text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
    // background: linear-gradient(135deg,
    //     #e3aa77 0%,
    //     #f5cda9 38%,
    //     #f9ecd3 58%,
    //     #fcdbb1 79%,
    //     #edb07a 100%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }

  .fontTwo {
    // font-size: 14px;
    // font-family: MicrosoftYaHei;
    // color: #d2dfff;
    // cursor: pointer;
    // line-height: 20px;
    cursor: pointer;

    .img {
      width: 30px;
      height: 30px;
    }
  }
}

.nei {
  width: 100%;
  box-sizing: border-box;
  padding: 12px 12px 16px 12px;
}

.Warning_notice_text {
  width: 100%;
  justify-content: space-between;
  margin: 0 0 10px 0;
}

.richText {
  position: relative;
  margin: 0 0 32px 0;

  /deep/ .el-textarea__inner {
    width: 444px;
    height: 96px;
    background: #02173d;
    border-radius: 2px;
    border: 1px solid #315ec7;
  }

  .timer {
    position: absolute;
    right: 13px;
    bottom: 9px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #ffffff;
    z-index: 1;
  }
}

.WarningNotice_btn {
  justify-content: center;
  width: 100%;

  .btn {
    width: 90px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #e0f6ff;
    // background-image: url("~@/assets/zong_lan_ye_img/btn_lan.png");
    background-size: 100% 100%;
    cursor: pointer;
    background-color: #0463E3;
    border-radius: 4px;
  }
}

.fontThree {
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #ffffff;
}
</style>