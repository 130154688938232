<!-- 问题反馈 -->
<template>
  <div class="feedback">
    <div class="pup_head" v-interact>
      <div class="flex">
        <div class="pup_img">
          <!-- <img src="~@/assets/zong_lan_ye_img/jinye.png" style="width:100%;height:100%;" alt=""> -->
        </div>
        <div class="fontOne">问题反馈</div>
      </div>
      <div class="fontTwo" @click="pupClose">
        <img src="@/assets/img/i40.png" alt="" class="img">
      </div>
    </div>
    <div class="nei">
      <div class="flex">
        <div class="fontThree">问题类型:</div>
        <div class="description">
          <el-select v-model="value" placeholder="请选择问题类型">
            <el-option v-for="item in options" :key="item.id" :label="item.typeName" :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="flex jb">
        <div class="fontThree">问题描述:</div>
        <div class="rich_text">
          <el-input type="textarea" v-model="desc" placeholder="描述内容..."></el-input>
        </div>
      </div>

      <div class="btn">
        <div class="btn_lv mr63" @click="pupClose">取消</div>
        <div class="btn_lan" @click="newly">确认</div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import Api from "@/api/modules/newOverview.js";
export default {
  data () {
    return {
      desc: null,//富文本里的内容
      options: [], //级联选择器
      filter1: {
        organizationId: null,
      },
      value: ''
    }
  },
  mounted () {
    this.typeL()
  },
  methods: {
    pupClose () {
      this.$emit('handleClose', 'distribute')
    },
    // 问题类型列表
    async typeL () {
      let res = await Api.typeList()
      this.options = res;
      console.log(res, 'typeListtypeListtypeList');
    },
    async newly () {
      let res = await Api.add({
        questionType: this.value,
        questionDescribe: this.desc,
        questionTime: this.$moment().format("YYYY-MM-DD HH:mm:ss")
      })
      this.$message.success(res)
      this.pupClose()
    }
  },
}
</script>

<style scoped lang="scss">
.feedback {
  width: 468px;
  height: 268px;
  // background: rgba(12, 34, 73, 0.7);
  // border-radius: 10px;
  // border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);
  position: fixed;
  top: 10%;
  left: 36%;
  box-sizing: border-box;
  background-color: #0A293A;
}

.flex {
  display: flex;
}

.pup_head {
  // width: 100%;
  // display: flex;
  // justify-content: space-between;
  // box-sizing: border-box;
  // padding: 0 16px 0 12px;
  // align-items: center;
  // height: 32px;
  // background: linear-gradient(180deg, #9198FF 0%, rgba(45, 81, 153, 0.45) 40%, #05091A 100%);
  // box-shadow: inset 0px 0px 10px 2px #3F9DFF;
  // border-radius: 10px 10px 0px 0px;
  // border: 1px solid #427DFF;

  height: 36px;
  background-color: #224D68;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 5px 10px 5px 10px;

  // .pup_img {
  //   display: flex;
  //   width: 19px;
  //   height: 25px;
  //   margin: 0 4px 0 0;
  // }

  .fontOne {
    font-size: 16px;
    color: #ffffff;
    // color: #ffffff;
    font-weight: bold;
    // font-family: YouSheBiaoTiHei;
    // color: #14FAFF;
    // line-height: 26px;
    // text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
    // background: linear-gradient(135deg, #E3AA77 0%, #F5CDA9 38%, #F9ECD3 58%, #FCDBB1 79%, #EDB07A 100%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }

  .fontTwo {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #D2DFFF;
    cursor: pointer;

    .img {
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
  }
}

.nei {
  width: 100%;
  height: calc(100% - 32px);
  box-sizing: border-box;
  padding: 12px 12px 16px 12px;
}

.rich_text {
  width: 362px;
  height: 96px;
  background: #02173D;
  border-radius: 2px;
  border: 1px solid #315EC7;

  /deep/.el-textarea {
    width: 100%;
    height: 100%;
  }

  /deep/ .el-textarea__inner {
    width: 100%;
    height: 100%;
    background: transparent;
    border: 0px solid transparent;
  }
}

.description {
  margin: 0 0 16px 21px;
  background: #02173D;
  border-radius: 2px;
  border: 1px solid #315EC7;
}

/deep/ .el-select {
  width: 160px;
  height: 32px;
  line-height: 32px;

  .el-input--suffix {
    height: 32px;
  }

  .el-input__inner {
    height: 32px;
    background: #02173D;
    border: 0px
  }
}

.btn {
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: #e0f6ff;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  margin: 32px 0 0 0;

  .btn_lv {
    width: 90px;
    height: 32px;
    line-height: 32px;
    // background-image: url('~@/assets/zong_lan_ye_img/btn_lv.png');
    background-size: 100% 100%;
    text-align: center;
    cursor: pointer;
    background-color: #224D68;
  }

  .btn_lv:hover {
    background-color: #315EC7;
  }

  .btn_lan {
    width: 90px;
    height: 32px;
    line-height: 32px;
    // background-image: url('~@/assets/zong_lan_ye_img/btn_lan.png');
    background-size: 100% 100%;
    text-align: center;
    cursor: pointer;
    background-color: #224D68;
  }

  .btn_lan:hover {
    background-color: #315EC7;
  }
}

.fontThree {
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #FFFFFF;
  margin: 6px 0 0 0;
}

.jb {
  justify-content: space-between;
}
</style>