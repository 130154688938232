<!-- 勤务报备 -->
<template>
  <div class="main">
    <div class="head">
      <div class="title">飞行报备</div>
      <div class="more" @click="changeType">
        <span>{{ signType == 0 ? "勤务报备" : "应急报备" }}</span>
        <!-- <span>更多</span> -->
        <!-- <img src="~@/assets/zong_lan_ye_img/jiantou_more.png" /> -->
      </div>

    </div>
    <div class="nei" id="fxbb_nei_id" @mouseover="mouseOver" @mouseleave="mouseLeave">
      <div class="cp fxbb_nei_item" v-for="(item, index) in list" :key="index" @click="btn_report(list[index])">
        <div class="m_12 csty">
          <div class="fontThree">
            {{ (item && item.orgName && String(item.orgName)) }}
          </div>
          <div class="fontThree">
            <span class="fontG">{{ item && item.cardCount }}</span>/<span class="fontW">{{ item && item.orgCount }}</span>
          </div>
        </div>
        <div class="peipe">
          <div class="peipe_fill" :style="{ width: percentage(item) || '0%' }">
            <div class="dot"></div>
          </div>
        </div>
      </div>
      <!-- 类目1 -->
      <div v-if="false" class="cp" @click="btn_report(list[0])">
        <div class="m_12 csty">
          <!-- <div class="fontThree">{{ String(list[0].orgName).replace('芜湖', '成都').replace('合肥', '黑龙江') || "亭湖分局" }}</div> -->
          <div class="fontThree">
            <!-- {{ String(list[0].orgName) }} -->
            {{ (list[0] && list[0].orgName && String(list[0].orgName)) }}
          </div>
          <div class="fontThree">
            <span class="fontG">{{ list[0] && list[0].cardCount }}</span>/<span class="fontW">{{ list[0] &&
              list[0].orgCount }}</span>
          </div>
        </div>
        <div class="peipe">
          <div class="peipe_fill" :style="{ width: '50%' || '0%' }">
            <div class="dot"></div>
          </div>
        </div>
      </div>
      <!-- 类目2 -->
      <div v-if="false" class="cp" @click="btn_report(list[1])">
        <div class="m_12 csty">
          <div class="fontThree">
            <!-- {{ (list[1] && list[1].orgName) || "盐都分局" }} -->
            <!-- {{ (list[1] && String(list[1].orgName).replace('芜湖', '成都').replace('合肥', '黑龙江')) || "盐都分局" }} -->
            {{ (list[1] && list[1].orgName && String(list[1].orgName)) }}
          </div>
          <div class="fontThree">
            <span class="fontG">{{ list[1] && list[1].cardCount }}</span>/<span class="fontW">{{ list[1] &&
              list[1].orgCount }}</span>
          </div>
        </div>
        <div class="peipe">
          <div class="peipe_fill ash" :style="{ width: ash || '0%' }">
            <div class="dot"></div>
          </div>
        </div>
      </div>
      <!-- 类目3 -->
      <div v-if="false" class="cp" @click="btn_report(list[2])">
        <div class="m_12 csty">
          <div class="fontThree">
            <!-- {{ (list[2] && list[2].orgName) || "经开区分局" }} -->
            <!-- {{ (list[2] && String(list[2].orgName).replace('芜湖', '成都').replace('合肥', '黑龙江')) || "经开区分局" }} -->
            {{ (list[2] && list[2].orgName && String(list[2].orgName)) }}
          </div>
          <div class="fontThree">
            <span class="fontG">{{ list[2] && list[2].cardCount }}</span>/<span class="fontW">{{ list[2] &&
              list[2].orgCount }}</span>
          </div>
        </div>
        <div class="peipe">
          <div class="peipe_fill orge" :style="{ width: orge || '0%' }">
            <div class="dot"></div>
          </div>
        </div>
      </div>
      <!-- 类目4 -->
      <div v-if="false" class="cp" @click="btn_report(list[3])">
        <div class="m_12 csty">
          <div class="fontThree">
            <!-- {{ (list[3] && list[3].orgName) || "盐南分局" }} -->
            <!-- {{ (list[3] && String(list[3].orgName).replace('芜湖', '成都').replace('合肥', '黑龙江')) || "盐南分局" }} -->
            {{ (list[3] && list[3].orgName && String(list[3].orgName)) }}
          </div>
          <div class="fontThree">
            <span class="fontG">{{ list[3] && list[3].cardCount }}</span>/<span class="fontW">{{ list[3] &&
              list[3].orgCount }}</span>
          </div>
        </div>
        <div class="peipe">
          <div class="peipe_fill blur" :style="{ width: blur || '0%' }">
            <div class="dot"></div>
          </div>
        </div>
      </div>
      <!-- 类目5 -->
      <div v-if="false" class="cp" @click="btn_report(list[4])">
        <div class="m_12 csty">
          <div class="fontThree">
            <!-- {{ list[4].orgName || "市局警种部门" }} -->
            <!-- {{ list[4].orgName && String(list[4].orgName).replace('芜湖', '成都').replace('合肥', '黑龙江') || "市局警种部门" }} -->
            {{ list[4] && list[4].orgName && String(list[4].orgName) }}
          </div>
          <div class="fontThree">
            <span class="fontG">{{ list[4] && list[4].cardCount }}</span>/<span class="fontW">{{ list[4] &&
              list[4].orgCount }}</span>
          </div>
        </div>
        <div class="peipe">
          <div class="peipe_fill green" :style="{ width: green || '0%' }">
            <div class="dot"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 四个角标 -->
    <div class="corn corn1"></div>
    <div class="corn corn2"></div>
    <div class="corn corn3"></div>
    <div class="corn corn4"></div>
  </div>
</template>

<script>
import Api from "@/api/modules/newOverview.js";
import API from "@/api";
const dongtaiData = [
  {
    "orgName": "东台市机队",
    "cardCount": 7,
    "id": 60615,
    "orgCount": 340
  },
  {
    "orgName": "安丰镇机组",
    "cardCount": 1,
    "id": 60614,
    "orgCount": 15
  },
  {
    "orgName": "许河镇机组",
    "cardCount": 2,
    "id": 60613,
    "orgCount": 17
  },
  {
    "orgName": "时堰镇机组",
    "cardCount": 1,
    "id": 60612,
    "orgCount": 24
  },
  {
    "orgName": "梁垛镇机组",
    "cardCount": 2,
    "id": 60611,
    "orgCount": 30
  },
];
export default {
  props: {
    signType: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      list: [], //数组列表
      phoneCardToOrgList: [],
      categoryOne: "50%",
      ash: "40%",
      orge: "80%",
      blur: "90%",
      green: "0%",
      times: null, //定时器
      newTop: 0, //位置
      oldTop: 0, //老位置
    };
  },
  watch: {
    signType: {
      handler(val) {
        this.getList();
      },
    },
  },
  mounted() {
    this.getList();
    this.abc();
  },
  methods: {
    changeType() {
      // return;
      let type = null;
      if (this.signType == 0) {
        type = 1;
      } else {
        type = 0;
      }
      // debugger
      this.$emit("changetype", type);
    },
    async getList() {
      let res = await Api.orgOrder({ signType: this.signType });
      // if (this.$configData['dutyData'] && this.$configData['dutyData'].length > 0) {
      //   this.list = this.$configData['dutyData'];
      // } else {
      //   this.list = res;
      // }
      // console.log(window.sceneConfigData, 'ddddddddddddduty');

      let sceneConfigData = window.localStorage.getItem('sceneConfigData');
      sceneConfigData = JSON.parse(sceneConfigData);
      if (sceneConfigData && sceneConfigData['dutyData'] && sceneConfigData['dutyData'] != '') {
        // this.headerTitle = window.sceneConfigData['dutyData'];
        this.list = sceneConfigData['dutyData'];
      } else {
        this.list = res;
      }
      if (this.$store.state.user.user_info.username == "dongtai") {
        this.list = dongtaiData;
      }
      this.categoryOne = Number((res[0].cardCount / res[0].orgCount) * 100).toFixed(1) + "%";
      this.ash = Number((res[1].cardCount / res[1].orgCount) * 100).toFixed(1) + "%";
      this.orge = Number((res[2].cardCount / res[2].orgCount) * 100).toFixed(1) + "%";
      this.blur = Number((res[3].cardCount / res[3].orgCount) * 100).toFixed(1) + "%";
      this.green = Number((res[4].cardCount / res[4].orgCount) * 100).toFixed(1) + "%";

    },
    async btn_baobei(item, num) {
      this.phoneCardToOrgList = [];
      this.phoneCardToOrgList.push(item.id);
      let res = await Api.phoneCardToOrg(this.phoneCardToOrgList);
      if (res == "通知成功") {
        // console.log('1111111111111111111111111111111');
        this.list.forEach((item, index) => {
          if (index == num) {
            item.lasStatus = 1;
          }
        });
        this.$message({
          type: "success",
          message: "已短信通知该单位报备！",
        });
      }
    },
    btn_report(item) {
      // debugger
      this.$emit("pup", 11, item);
    },
    // 滚动条滚动效果
    abc() {
      let box = document.getElementById("fxbb_nei_id");
      this.times = setInterval(function () {
        this.oldTop = box.scrollTop;
        //获得老位置
        this.newTop = this.oldTop + 1;
        //得到新位置
        box.scrollTop = this.newTop;
        //将计算的新位置进行赋值，使滚动条移动
        // 到底重来一遍
        if (this.newTop + box.clientHeight > box.scrollHeight) {
          box.scrollTop = 0;
        }
      }, 50);
      //循环时间
    },
    // 鼠标悬停
    mouseOver() {
      clearInterval(this.times);
    },
    //鼠标离开
    mouseLeave() {
      this.abc();
    },
    percentage(item) {
      let a = (item.cardCount / item.orgCount) * 100 + '%';
      console.log('a', a)
      return a || '0%';
    }
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  height: 24.4vh;
  background-color: rgba(35, 47, 79, 0.75);
  //border-radius: 12px;
  color: #fff;
  font-family: PingFangSC-Semibold, PingFang SC;
  // margin-bottom: 12px;
  background: linear-gradient(360deg, #14203E 0%, rgba(28, 74, 93, 0.91) 100%);
  position: relative;

  // 四个角标
  .corn {
    width: 22px;
    height: 20px;
    //border: 1px solid #00F5FF;
    position: absolute;
  }

  .corn1 {
    top: 0;
    left: 0;
    background-image: url("~@/assets/img/corn1.png");
    background-repeat: no-repeat;
  }

  .corn2 {
    top: 0;
    right: 0;
    background-image: url("~@/assets/img/corn1.png");
    background-repeat: no-repeat;
    transform: rotate(90deg);
    //back
  }

  .corn3 {
    bottom: 0;
    left: 0;
    background-image: url("~@/assets/img/corn3.png");
    background-repeat: no-repeat;
  }

  .corn4 {
    bottom: 0;
    right: 0;
    background-image: url("~@/assets/img/corn3.png");
    background-repeat: no-repeat;
    transform: rotate(-90deg);
  }
}

.head {
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  //padding: 0 12px 0 12px;
  align-items: center;
  //background-image: url("~@/assets/zong_lan_ye_img/kuang_title_bg.png");
  background-repeat: no-repeat;
  background-size: 274px 42px;
  border-bottom: 1px solid #474772;

  .title {
    line-height: 28px;
    margin-left: 14px;
    font-weight: 600;
    height: 100%;
    width: 30%;
    background-image: url("~@/assets/img/title.png");
    background-repeat: no-repeat;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #FFFFFF;
    display: flex;
    align-items: center;
  }

  .more {
    font-size: 14px;
    line-height: 25px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;

    img {
      margin-left: 3px;
      width: 11px;
      height: 17px;
    }
  }
}

.nei {
  width: 100%;
  height: calc(100% - 42px);
  box-sizing: border-box;
  // padding: 10px 26px 16px 26px;
  padding: 10px 26px;
  // border:1px solid #ff0000;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-around;
  overflow-y: auto;
  flex: 1;

  .fxbb_nei_item {
    height: 30px;
  }
}

.peipe {
  width: 100%;
  height: 3px;
  background: rgba(208, 222, 238, 0.1);
}

.csty {
  display: flex;
  justify-content: space-between;
}

.peipe_fill {
  height: 3px;
  background: linear-gradient(135deg, #0f62d5 0%, #38deff 100%);
  position: relative;

  .dot {
    position: absolute;
    right: -9px;
    width: 19px;
    height: 19px;
    top: -8px;
    background-image: url("~@/assets/zong_lan_ye_img/dian.png");
    background-size: 100% 100%;
  }
}

.fontThree {
  // font-size: 8px;
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCn;
  color: #ffffff;
  font-weight: 500;

  .fontG {
    color: #00f5ff;
  }
}

.m_12 {
  margin: 0 0 1% 0;
}
</style>
