import API from "@/api"

export default {

    /**
     * 待办事项
     * @param {*} params 
     */
    async stat_class_hours(params) {
        let data = await API.PILOT.StatClassHours(params)
        this.pilot.stat.class_hours = data || []
    },
}