import API from "@/api"

function formatSeconds(value) {
    let result = parseInt(value)
    let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600)
    let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60))
    let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60))
    result = `${h}:${m}:${s}`
    return result
}

export default {

    /**
     * 常态任务
     * @param {*} params 
     */
    async list(params) {
        let data = await API.TASK.List({
            cate: 2,
            ...params
        })
        this.task.list = data || []
    },

    /**
     * 待办事项
     * @param {*} params 
     */
    async stat_doto(params) {
        let data = await API.TASK.StatTodo(params)
        this.task.stat.todo = data || []
    },

    /**
     * 数据监控
     * @param {*} params 
     */
    async stat_monitor(params) {
        let data = await API.TASK.StatMonitor(params)
        data.forEach(item => {
            if (item.type == 2) {
                item.data = (item.data / 1000).toFixed(2)
            }
            if (item.type == 3) {
                item.data = formatSeconds(item.data)
            }
        })
        this.task.stat.monitor = data || []
    },

    /**
     * 识别抓拍
     * @param {*} params 
     */
    async stat_snap(params) {
        let data = await API.TASK.StatSnap(params)
        this.task.stat.snap = data || []
    },

    /**
     * 警务处办情况
     * @param {*} params 
     */
    // async stat_handle(params) {
    //     let data = await API.TASK.StatHandle(params)
    //     console.log("handle_--data-->", data);
    //     this.task.stat.handle = data || []
    // },
    /**
     * 任务执行情况
     * @param {*} params 
     */
    async stat_handle(params) {
        let data = await API.TASK.StatHandle(params)
        // console.log("handle_--data-->", data);
        this.task.stat.handle = data || []
    },


    /**
     * 警务处办排名
     * @param {*} params 
     */
    async stat_handle_rank(params) {
        let data = await API.TASK.StatHandleRank(params)
        this.task.stat.handle_rank = data || []
    }

}
