<!-- 勤务报备 -->
<template>
  <div class="report">
    <div class="pup_head" v-interact>
      <div class="flex">
        <!-- <div class="pup_img"> -->
        <!-- <img src="~@/assets/zong_lan_ye_img/jinye.png" style="width: 100%; height: 100%" alt="" /> -->
        <!-- </div> -->
        <div class="fontOne">{{ signType == 0 ? "勤务报备" : "应急报备" }}</div>
      </div>
      <div class="fontTwo" @click="pupClose">
        <img src="@/assets/img/i40.png" alt="" class="img">
      </div>
    </div>
    <div class="nei">
      <div class="head">
        <div class="name">{{ saveData.orgName }}</div>
        <div class="btn">
          <div class="btn_select mr8">
            <el-select v-model="value" placeholder="请选择" @change="sunmit">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="report_btn" @click="btn_pi">批量通知</div>
        </div>
      </div>
      <div class="kuang">
        <div class="flex tb">
          <div class="check">
            <el-checkbox v-model="checked" @change="Multi"></el-checkbox>
          </div>
          <div class="kuan">单位名称</div>
          <div class="line_width1 line-width-header">经度</div>
          <div class="line_width2 line-width-header">纬度</div>
          <div class="line_width3 line-width-header">状态</div>
          <div class="line_width4 line-width-header">操作</div>
        </div>
        <div class="th">
          <div class="th_tr flex" v-for="(item, index) in list" :key="index">
            <div>
              <el-checkbox @change="btn_check(item, index)" v-model="checkedfLag[index].staty"></el-checkbox>
            </div>
            <el-tooltip effect="dark" :content="item.anotherName" placement="top">
              <div class="kuan">{{ item.anotherName }}</div>
            </el-tooltip>
            <div class="line_width">{{ parseFloat(String(item.geography).split(',')[0]).toFixed(5) }}</div>
            <div class="line_width">{{ parseFloat(String(item.geography).split(',')[1]).toFixed(5) }}</div>
            <div v-if="item.lasStatus == 1" class="lv">{{ "已报备" }}</div>
            <div v-else class="hong">{{ "未报备" }}</div>
            <div class="line_width" v-if="item.isNotice == 0">
              <div class="btn_shen" @click="btn_WarningNotice(item, index)">
                {{ "通知" }}
              </div>
            </div>
            <div class="line_width" v-else>
              <div class="btn_shen">{{ "已通知" }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api/modules/newOverview.js";
import API from "@/api";
import { FormItem } from "element-ui";
export default {
  props: {
    saveData: {
      type: Object,
      default: () => { }
    },
    signType: {
      type: Number,
      default: () => 0
    }
  },
  data () {
    return {
      options: [
        {
          value: 0,
          label: "未报备",
        },
        {
          value: 1,
          label: "已报备",
        },
        {
          value: 2,
          label: "全部",
        },
      ],
      value: 2,
      filter1: {
        organizationId: null,
      },
      list: [], //数组列表
      checked: false,
      phoneCardToOrgList: [], //通知数组
      form: [], //批量通知
      formIndex: [],
      checkedfLag: [],
    };
  },
  mounted () {
    // this.getOptions();
    this.getList();
  },
  methods: {
    // 地区选择框
    async getOptions () {
      let res = await API.DEPARTMENT.Drd();
      this.options = [res] || [];
    },
    async getList () {
      this.checkedfLag = [];
      let a = this.value
      if (this.value == 2) {
        a = ''
      }
      let res = await API.WEATHER.getCardInfosOrg({
        isCard: a,
        orgId: this.saveData.id,
        signType: this.signType
      });
      this.list = res;
      console.log(res, '勤务报备二级页面数据')
      this.list.forEach((item, index) => {
        this.checkedfLag.push({ staty: false });
      });
    },
    // 机构选择
    sunmit () {
      this.getList();
    },
    // 通知
    async btn_WarningNotice (item, num) {
      this.phoneCardToOrgList = [];
      if (item.lasStatus == 1) {
        this.$message({
          type: "info",
          message: "该单位已报备！",
        });
        return
      }
      this.phoneCardToOrgList.push(item.id);
      let res = await Api.phoneCardToOrg(this.phoneCardToOrgList);
      if (res == "通知成功") {
        this.list.forEach((item, index) => {
          if (index == num) {
            item.lasStatus = 1;
          }
        });
        this.getList()
        this.$message({
          type: "success",
          message: "已短信通知该单位报备！",
        });
      }
      // this.list = this.list[num].lasStatus = 1
      // console.log(res, 'idididid');
    },
    // 批量通知
    async btn_pi () {
      if (this.form.length < 1) {
        return this.$message.warning("请选择通知单位")
      }
      let res = await Api.phoneCardToOrg(this.form);
      //  console.log(res,'11111111111111');
      if (res == "通知成功") {
        let a = this.formIndex;
        let b = this.list
        // console.log('1111111111111111111111111111111');
        a.forEach((item, index) => {
          b[item].lasStatus = '1'
        });
        // this.list = b
        this.getList()
        // console.log(this.list,'this.listthis.listthis.listthis.list');
        this.$message({
          type: "success",
          message: "已短信通知该单位报备！",
        });
      }

      // console.log(this.form, 'this.form');
    },
    // 复选框全部通知
    btn_check (item, num) {
      let a = item.id;
      // console.log(item, 'this.formthis.formthis.form');
      let b;
      if (this.form.length == 0) {
        this.form.push(a);
        this.formIndex.push(num);
      } else {
        b = this.form.indexOf(a);
        if (b == -1) {
          this.form.push(a);
          this.formIndex.push(num);
        } else {
          this.form.splice(b, 1);
          this.formIndex.splice(b, 1);
        }
      }
      console.log(this.formIndex, "this.formIndexthis.formIndex");
    },
    Multi () {
      // console.log(this.list,'this.formthis.form');
      this.form = []
      if (this.checked == true) {
        this.checkedfLag.forEach((item, index) => {
          item.staty = true;
        });
        this.list.forEach((item) => {
          this.form.push(item.id);
        });
      } else {
        this.checkedfLag.forEach((item, index) => {
          item.staty = false;
        });
        this.form = [];
      }
      console.log(this.form, 'this.formthis.formthis.form');
    },
    pupClose () {
      this.$emit("handleClose", "report");
    },
  },
};
</script>

<style  scoped lang="scss">
.line-width-header {
  text-align: center;
  width: 76px;
}

.report {
  width: 664px;
  height: 318px;
  // background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  // border: 1px solid rgba(26, 92, 246, 0.5);
  // backdrop-filter: blur(1px);
  position: fixed;
  top: 20%;
  left: 35%;
  box-sizing: border-box;
  background-color: #0A293A;
}

.flex {
  display: flex;
}

.pup_head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 16px 0 12px;
  align-items: center;
  height: 32px;
  // background: linear-gradient(180deg,
  //     #9198ff 0%,
  //     rgba(45, 81, 153, 0.45) 40%,
  //     #05091a 100%);
  // box-shadow: inset 0px 0px 10px 2px #3f9dff;
  // border-radius: 10px 10px 0px 0px;
  // border: 1px solid #427dff;
  height: 36px;
  background-color: #224D68;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 5px 10px 5px 10px;

  .pup_img {
    display: flex;
    width: 19px;
    height: 25px;
    margin: 0 4px 0 0;
  }

  .fontOne {
    font-size: 20px;
    // font-family: YouSheBiaoTiHei;
    // color: #14faff;
    // line-height: 26px;
    // text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
    // background: linear-gradient(135deg,
    //     #e3aa77 0%,
    //     #f5cda9 38%,
    //     #f9ecd3 58%,
    //     #fcdbb1 79%,
    //     #edb07a 100%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    color: #ffffff;
    font-weight: bold;
  }

  .fontTwo {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #d2dfff;
    cursor: pointer;
    line-height: 20px;
    cursor: pointer;

    .img {
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
  }

  .head_left {
    .header-title {
      color: #ffffff;
      font-weight: bold;
    }
  }

  .header-close {
    cursor: pointer;

    .img {
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
  }
}

.nei {
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  height: calc(100% - 32px);

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .name {
      margin-bottom: 12px;
      font-size: 16px;
      font-family: YouSheBiaoTiHei;
      color: #EBF9FF;
    }
  }
}

.btn_select {
  width: 160px;
}

/deep/ .el-input__inner {
  height: 32px !important;
  background: #02173d;
  border: 1px solid #315ec7;
}

/deep/ .el-range-editor {
  padding: 0;

  .el-range__icon {
    line-height: 32px;
  }

  .el-range-input {
    background: #02173d;
    margin: 0 0 0 10px;
    width: 80px;
  }
}

.btn {
  // width: 100%;
  display: flex;
  justify-content: end;
  margin: 0 0 12px 0;
  align-items: center;
}

.report_btn {
  width: 92px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  background-color: #4E87AC;
  border-radius: 4px;
  cursor: pointer;
  // background-image: url("~@/assets/zong_lan_ye_img/btn_lan.png");
  // background-size: 100% 100%;
}

.kuang {
  height: 218px;
  background: rgba(8, 26, 58, 0.8);
  border-radius: 4px;
  border: 1px solid #315ec7;
  box-sizing: border-box;
  padding: 4px 4px 4px 10px;

  .tb {
    width: 100%;
    height: 42px;
    box-sizing: border-box;
    padding: 0 12px 0 16px;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-family: YouSheBiaoTiHei;
    color: #ebf9ff;
    letter-spacing: 1px;

    .tb_tr {
      width: 120px;
      text-align: center;
    }
  }

  .th {
    width: 100%;
    height: 168px;
    overflow-y: auto;

    .th_tr:nth-child(odd) {
      background: rgba(73, 135, 210, 0.2);
    }

    .th_tr:nth-child(even) {
      background: transparent;
    }

    .th_tr {
      padding: 0 16px 0 16px;
      box-sizing: border-box;
      width: 100%;
      height: 42px;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ebf9ff;

      .th_td {
        width: 120px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .btn_yu {
        width: 80px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        font-size: 14px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #e0f6ff;
        background-image: url("~@/assets/zong_lan_ye_img/btn_lan.png");
        background-size: 100% 100%;
      }

      .btn_shen {
        width: 80px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        font-size: 14px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #e0f6ff;
        // background-image: url("~@/assets/zong_lan_ye_img/btn_lan.png");
        // background-size: 100% 100%;
        background-color: #4E87AC;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
}

.warning_btn {
  justify-content: end;
  width: 100%;

  .early_warning_btn {
    width: 92px;
    height: 32px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #ffffff;
    line-height: 32px;
    text-align: center;
    background-image: url("~@/assets/zong_lan_ye_img/btn_lan.png");
    background-size: 100% 100%;
    cursor: pointer;
  }
}

.line_width {
  width: 80px;
  text-align: center;
}

.hong {
  color: #d22a2a;
}

.lv {
  color: #1dc14d;
}

.kuan {
  width: 76px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
}
</style>