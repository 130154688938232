<!-- 空域管理 -->
<template>
  <div class="airspace">
    <div class="head">
      <div class="title">安全管制</div>
      <div class="more" @click="btn_controlled">
        <span>更多</span>
        <img src="~@/assets/zong_lan_ye_img/jiantou_more.png" />
      </div>
    </div>
    <div class="nei" id="hezi" @mouseover="mouseOver" @mouseleave="mouseLeave">
      <!-- 模板 -->
      <div class="kuang" v-for="(item, index) in list" :key="index">
        <div class="fontThree">{{ index + 1 }}</div>
        <div class="fontThree two">{{ item.typeName }}</div>
        <div class="changdu fontThree">
          {{ item.name }}
        </div>
        <div class="chakan" @click="view(item)">查看</div>
        <!-- <div class="chakan" @click="handleHide(item)" v-show="!item.visible">
          <img src="~@/assets/zong_lan_ye_img/btn_hong.png" class="btn" alt="" />
          关闭
        </div> -->
      </div>
      <!-- 模板 -->
      <p v-if="list.length == 0" style="text-align: center; color: #fff">
        暂无数据
      </p>
    </div>
    <!-- 四个角标 -->
    <div class="corn corn1"></div>
    <div class="corn corn2"></div>
    <div class="corn corn3"></div>
    <div class="corn corn4"></div>
  </div>
</template>

<script>
import API from "@/api";
import data from "./data";
import methods from "./methods";
export default {
  data () {
    return {
      list: [], //数组
      pagination: {
        // 页码
        pageNo: 1,
        pageSize: 10,
        totalCount: 0,
      },
      times: null, //定时器
      newTop: 0, //位置
      oldTop: 0, //老位置
      dataLength: 0, //数组长度
      hang: null, //上一个航线
    };
  },
  async mounted () {
    await this.getList();
    // this.abc();
    this.load();
  },
  inject: ["g_cesium_toolbar", "g_cesium_layer"],
  methods: {
    ...methods,
    async getList () {
      let { data, pageNo, pageSize, totalCount } = await API.AIRSPACE.List({
        ...this.pagination,
      });
      this.dataLength = data.length;
      this.list =
        data.map(
          (item) =>
          (item = {
            ...item,
            visible: true,
          })
        ) || [];

      // if (this.$store.state.user.user_info.username == "dongtai") {
      //   this.list = [];
      //   pageNo = 0;
      //   pageSize = 0;
      //   totalCount = 0;
      // }

      this.pagination.pageNo = pageNo;
      this.pagination.pageSize = pageSize;
      this.pagination.totalCount = totalCount;
      // console.log(this.g_cesium_layer(), "this.listthis.list");
    },
    // 滚动条滚动效果
    abc () {
      let box = document.getElementById("hezi");
      this.times = setInterval(function () {
        this.oldTop = box.scrollTop;
        //获得老位置
        this.newTop = this.oldTop + 1;
        //得到新位置
        box.scrollTop = this.newTop;
        //将计算的新位置进行赋值，使滚动条移动
        // 到底重来一遍
        if (this.newTop + box.clientHeight > box.scrollHeight) {
          box.scrollTop = 0;
        }
      }, 50);
      //循环时间
    },
    // 鼠标悬停
    mouseOver () {
      // clearInterval(this.times);
    },
    //鼠标离开
    mouseLeave () {
      // this.abc();
    },
    // 多次加载
    async load () {
      if (this.dataLength > 9) {
        this.pagination.pageNo++;
        let { data, pageNo, pageSize, totalCount } = await API.AIRSPACE.List({
          ...this.pagination,
        });
        this.dataLength = data.length;
        data.forEach((item) => {
          (item.visible = true), this.list.push(item);
        });
        this.pagination.pageNo = pageNo;
        this.pagination.pageSize = pageSize;
        this.pagination.totalCount = totalCount;
        this.load();
      }
    },
    // 查看航线
    view (item) {
      // console.log(item);
      // item.visible = false;
      // this.hang = null
      this.airspace_handle_show(item);
      this.handleHide(this.hang);
      this.hang = item;
    },
    // 隐藏航线
    handleHide (item) {
      // item.visible = true;
      this.airspace_handle_hide(item);
    },
    // 跳向空域管制
    btn_controlled () {
      // return;
      // let { href } = this.$router.resolve({
      //   path: "/command?page=3",
      //   query: {
      //     type: "调度中心",
      //     item: JSON.stringify({ id: 3, title: "空域管理" }),
      //   },
      // });

      let { href } = this.$router.resolve({ path: "/command?page=5", query: { item: '安全管制' } });
      localStorage.setItem("infoPage", 5);
      // localStorage.setItem("infoPage", 3);
      // window.open(href, "_blank");
      window.open(href, "_self");
      this.$router.go(0);
    },
  },
};
</script>

<style scoped lang="scss">
.airspace {
  width: 100%;
  height: 19.5vh;
  background-color: rgba(35, 47, 79, 0.75);
  //border-radius: 12px;
  color: #fff;
  font-family: PingFangSC-Semibold, PingFang SC;
  margin-bottom: 12px;
  padding-bottom: 10px;
  //width: 419px;
  //height: 239px;
  background: linear-gradient(360deg, #14203E 0%, rgba(28, 74, 93, 0.91) 100%);
  position: relative;

  // 四个角标
  .corn {
    width: 22px;
    height: 20px;
    //border: 1px solid #00F5FF;
    position: absolute;
  }

  .corn1 {
    top: 0;
    left: 0;
    background-image: url("~@/assets/img/corn1.png");
    background-repeat: no-repeat;
  }

  .corn2 {
    top: 0;
    right: 0;
    background-image: url("~@/assets/img/corn1.png");
    background-repeat: no-repeat;
    transform: rotate(90deg);
    //back
  }

  .corn3 {
    bottom: 0;
    left: 0;
    background-image: url("~@/assets/img/corn3.png");
    background-repeat: no-repeat;
  }

  .corn4 {
    bottom: 0;
    right: 0;
    background-image: url("~@/assets/img/corn3.png");
    background-repeat: no-repeat;
    transform: rotate(-90deg);
  }

}

.head {
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  //padding: 0 12px 0 12px;
  align-items: center;
  //background-image: url("~@/assets/img/title.png");
  background-repeat: no-repeat;
  background-size: 274px 42px;
  border-bottom: 1px solid #474772;

  .title {
    //background-color: #0bd9d8;
    font-size: 14px;
    //line-height: 28px;
    margin-left: 14px;
    display: flex;
    align-items: center;
    font-weight: 600;
    height: 100%;
    width: 30%;
    background-image: url("~@/assets/img/title.png");
    background-repeat: no-repeat;
    //width: 247px;
    //height: 20px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    //line-height: 20px;
  }

  .more {
    font-size: 14px;
    line-height: 25px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;

    img {
      margin-left: 3px;
      width: 11px;
      height: 17px;
    }
  }
}

.nei {
  //width: 100%;
  height: calc(100% - 42px);
  box-sizing: border-box;
  //margin-left: 12px;
  //margin-right: 12px;
  overflow-y: auto;
  padding: 0px 16px 16px 16px;

  .kuang:nth-child(odd) {
    background: rgba(99, 177, 245, 0.09);
    border: 1px solid rgba(84, 147, 215, 0.37);
  }

  //奇数行

  .kuang:nth-child(even) {
    background: transparent;
  }
}

.nei::-webkit-scrollbar {
  width: 4px !important;
}

.kuang {
  width: 100%;
  height: 30px;
  padding: 3px 2px 3px 12px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.two {
  width: 42px;
  margin: 0 24px 0 24px;
  white-space: nowrap;
}

.changdu {
  width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.chakan {
  width: 48px;
  height: 21px;
  position: relative;
  text-align: center;
  line-height: 21px;
  cursor: pointer;
  font-size: 12px;
  background: rgba(1, 109, 255, 0.85);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5),
    inset 0px 1px 0px 0px rgba(255, 255, 255, 0.29);
  border-radius: 2px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ffffff;
  z-index: 1;
  border-radius: 22px;

  .btn {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: 12px;
  }
}

.fontThree {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
</style>
