<!-- 视频巡逻 -->
<template>
  <div class="main">
    <div class="head">
      <div class="title">视频巡逻</div>
      <div class="more" @click="btn_video">
        <span>更多</span>
        <img src="~@/assets/zong_lan_ye_img/jiantou_more.png" />
      </div>
    </div>
    <template>
      <div class="cpt-observe-video-box">
        <div class="video-box" v-for="index in 2" :key="index">
          <template v-if="videoList.length">
            <div class="title">
              {{ videos[index].deviceName }}
              <span
                class="iconfont icon-gengxin pointer"
                @click="refresh(index)"
              ></span>
            </div>
            <components
              v-if="showVideo[index]"
              :is="playerCom(videos[index])"
              :data="videos[index]"
              :flag="flag"
            />
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Livenvrlayer from "@/components/player/livenvr";
import QingLiuPlayer from "@/components/player/flv";
// import QingLiuPlayer from "@/components/player/qingliu";
import API from "@/api";
export default {
  components: { Livenvrlayer, QingLiuPlayer },
  data() {
    return {
      active: 1,
      videoList: [],
      timer: null,
      flag: true,
      uavindex: 3,
      videoIndex1: 0, //视屏1索引
      videoIndex2: 0, //视屏2索引
      showVideo: {
        //显示视频1,2 用于刷新同类型视频组件实例
        1: true,
        2: true,
      },
    };
  },
  computed: {
    videos() {
      if (this.videoList.length === 0) {
        return {
          1: null,
          2: null,
        };
      }
      return {
        1: this.videoList[this.videoIndex1],
        2: this.videoList[this.videoIndex2],
      };
    },
  },
  watch: {
    videos(newVal, oldVal) {
      if (newVal[1]?.vUrl !== oldVal[1]?.vUrl) {
        this.showVideo[1] = false;
        this.$nextTick(() => {
          this.showVideo[1] = true;
        });
      }

      if (newVal[2]?.vUrl !== oldVal[2]?.vUrl) {
        this.showVideo[2] = false;
        this.$nextTick(() => {
          this.showVideo[2] = true;
        });
      }
    },
  },
  async mounted() {
    document.addEventListener("visibilitychange", () => {
      // console.info("visibilitychange");
      if (document.hidden) {
        this.flag = false;
        return;
      } else {
        this.flag = true;
      }
    });
    this.timer = setInterval(this.init, 60000);
    await this.init();
    if (this.videoList.length > 1) {
      this.videoIndex2 = 1;
    }
  },
  methods: {
    async init() {
      let res = (await API.HOME.getUavVideo()) || [];
      this.videoList = res;
    },
    playerCom(item) {
      // console.log(item,"videoitem");
      if (item.vUrl.indexOf("pzsp://") > -1) {
        return "QingLiuPlayer";
      }
      return "Livenvrlayer";
    },
    btn_video() {
      let { href } = this.$router.resolve({ path: "/fckernelVideos" });
      window.open(href, "_blank");
    },
    refresh(index) {
      let videoItem1 = this.videos[1];
      let videoItem2 = this.videos[2];
      let curIndex = index;
      let otherIndex = index === 1 ? 1 : 2;

      this.videoList.some((item, i) => {
        if (item.vUrl !== videoItem1.vUrl && item.vUrl !== videoItem2.vUrl) {
          if (
            i > this["videoIndex" + curIndex] &&
            i !== this["videoIndex" + otherIndex]
          ) {
            this["videoIndex" + index] = i;
            return true;
          } else if (
            [this.videoList.length - 1, this.videoList.length - 2].includes(
              this.videoIndex1
            ) &&
            [this.videoList.length - 1, this.videoList.length - 2].includes(
              this.videoIndex2
            )
          ) {
            this["videoIndex" + index] = 0;
            return true;
          } else {
            if (this["videoIndex" + curIndex] === this.videoList.length - 1) {
              if (this["videoIndex" + otherIndex] === 0) {
                if (this.videoList.length > 1) {
                  this["videoIndex" + curIndex] = 1;
                } else {
                  this["videoIndex" + curIndex] = 0;
                }
              } else {
                this["videoIndex" + curIndex] = 0;
              }
            }
          }
        }
      });
    },
    updateVideoList() {
      this.init();
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>

<style scoped lang="scss">
.flex {
  display: flex;
}
.main {
  width: 23vw;
  height: 16vh;
  background-color: rgba(35, 47, 79, 0.75);
  border-radius: 12px;
  color: #fff;
  font-family: PingFangSC-Semibold, PingFang SC;
  display: flex;
  flex-direction: column;
}

.head {
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 12px 0 12px;
  align-items: center;
  background-image: url("~@/assets/zong_lan_ye_img/kuang_title_bg.png");
  background-repeat: no-repeat;
  background-size: 274px 42px;
  // border-bottom: 1px solid #474772;
  .title {
    font-size: 18px;
    line-height: 28px;
    margin-left: 14px;
    font-weight: 600;
  }
  .more {
    font-size: 14px;
    line-height: 25px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    img {
      margin-left: 3px;
      width: 11px;
      height: 17px;
    }
  }
}

.cpt-observe-video-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
  .update_btn {
    width: 100px;
    height: 30px;
    position: absolute;
    top: -32px;
    left: 25px;
  }

  .video-box {
    position: relative;
    width: 185px;
    //height: 96px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;

    .title {
      font-family: MicrosoftYaHei-Bold;
      font-size: 12px;
      color: #ffffff;
      letter-spacing: 1px;
      font-weight: 700;
      position: relative;
      box-sizing: border-box;
      position: absolute;
      padding-left: 5px;
      padding-right: 5px;
      width: 100%;
      height: 32px;
      background: rgba(0, 0, 0, 0.6);
      line-height: 32px;
      z-index: 99;
      .icon-gengxin {
        float: right;
      }
    }
  }
}
</style>
