<!-- 通讯联络 -->
<template>
  <div class="securityFlyDialog">
    <div class="security_head">
      <div class="left">
        <div class="icon">
          <img src="@/assets/zong_lan_ye_img/frame/icon.png" alt="" />
        </div>
        <div class="security_head_left">通讯联络</div>
      </div>
      <div class="security_head_right" @click="$emit('handleClose')">关闭</div>
    </div>
    <div class="danwei">
      <el-cascader
        v-model="pagination.organizationId"
        :options="options"
        clearable
        filterable
        :showAllLevels="false"
        placeholder="单位名称"
        ref="elcascader4"
        :props="{
          value: 'id',
          label: 'orgName',
          children: 'children',
          emitPath: false,
          checkStrictly: true,
        }"
        @change="Change"
      ></el-cascader>
    </div>
    <div class="cpt-command-task-situation">
      <div class="tb-box">
        <div class="tb-hd-box">
          <!-- <div class="tb-hd-triangle"></div> -->
          <div class="tb-hd">账号名称</div>
          <div class="tb-hd">姓名</div>
          <div class="tb-hd">联系方式</div>
        </div>
        <div class="tb-bd-box">
          <div v-for="(item, index) in list" :key="index" class="hangse">
            <div class="tb-tr">
              <div class="td">{{ item.anotherName || "暂无" }}</div>
              <div class="td">{{ item.name || "暂无" }}</div>
              <div class="td">{{ item.phone || "暂无" }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tb-pagination">
      <el-pagination
        small
        v-if="pageshow"
        :page-sizes="[10, 20, 30, 40]"
        :current-page.sync="pagination.pageNo"
        :page-size="pagination.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.totalCount"
        @size-change="handle_size_change"
        @current-change="handle_page_change"
      >
      </el-pagination>
    </div>
  </div>
</template>
  <script>
import API from "@/api";
export default {
  data() {
    return {
      list: [],
      cengji: null,
      pageshow: true,
      pagination: {
        pageNo: null,
        pageSize: 10,
        totalCount: 0,
        organizationId: null,
      },
      options: [],
    };
  },
  mounted() {
    this.getlist();
    this.getOptions();
    // 传2代表安全飞行层级提到99
    // this.cengji = this.$store.state.cengji.Zindex;
  },
  methods: {
    /*机构 */
    async getOptions() {
      let res = await API.DEPARTMENT.Drd();
      // each(res);
      console.log(res, "resrews");
      this.options = [res] || [];
    },
    Change(e) {
      this.pagination.organizationId = e;
      this.$refs.elcascader4.dropDownVisible = false;
      this.getlist();
    },
    async getlist() {
      let { data, pageNo, pageSize, totalCount } = await API.USER.getUser({
        ...this.pagination,
      });
      this.list = data;
      this.pagination.pageNo = pageNo;
      this.pagination.pageSize = pageSize;
      this.pagination.totalCount = totalCount;
      // this.List = res;
    },
    handle_size_change(size) {
      this.pagination.pageSize = size;
      this.getlist();
    },
    handle_page_change(page) {
      this.pagination.pageNo = page;
      this.getlist();
    },
  },
};
</script>
  <style lang="scss" scoped>
.securityFlyDialog {
  position: fixed;
  top: 20%;
  left: 31%;
  z-index: 0;
  width: 672px;
  // height: 318px;
  display: flex;
  flex-direction: column;
  // background: rgba(22, 29, 45, 0.8);
  // box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);
  .security_head {
    padding: 0 10px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    // line-height: 44px;
    .left {
      display: flex;
      align-items: center;
      .security_head_left {
        font-family: MicrosoftYaHei-Bold;
        font-size: 18px;
        // color: #00ffff;
        background-image: -webkit-linear-gradient(
          right,
          #e3aa77,
          #f5cda9,
          #f9ecd3,
          #fcdbb1,
          #edb07a
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        letter-spacing: 0;
        font-weight: 700;
        margin-left: 4px;
      }
    }
    .security_head_right {
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      cursor: pointer;
    }
  }
}
.z_index {
  z-index: 99;
}
</style>
  <style lang="scss" scoped>
.cpt-command-task-situation {
  @import "~@/styles/flightTask.scss";

  height: calc(100% - 40px);
  padding:  0 14px 0 14px;
  .btn-submit {
    margin-left: 40px;
    cursor: pointer;
  }
  .tb-box {
    width: 100%;
    height: 218px;
    background: rgba(8, 26, 58, 0.8);
    border-radius: 4px;
    border: 1px solid #315ec7;
    .tb-hd-box {
      justify-content: space-between !important;
      padding: 0 12px 0 12px;
      .tb-hd {
        font-size: 16px;
        font-family: YouSheBiaoTiHei;
        color: #ebf9ff;
        width: calc(100% / 3);
      }
      .last-tb-hd {
        width: 220px !important;
      }
    }
    .tb-bd-box {
      height: 164px;
      overflow: auto;
      .hangse:nth-child(odd) {
        background: rgba(73,135,210,0.2) !important;
      } 
      .hangse:nth-child(even){

      }
      .tb-tr {
        padding: 0 12px 0 12px;
        display: flex;
        justify-content: space-between;
        .td {
          // width: calc(100% / 3);
          flex: 1;
        }
        .last-td {
          width: 220px !important;
          .iconfont {
            margin-right: 7px;
          }
        }
      }
    }
  }
}
.el-cascader {
  width: 170px;
  height: 36px;
}
/deep/ .el-cascader .el-input__inner {
  margin: 12px;
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid rgba(14, 123, 117, 0.63);
  width: 160px;
  height: 32px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #43deff;
  letter-spacing: 0;
  font-weight: 400;
}
.danwei {
  margin: 0 0 16px 0;
}
/deep/ .el-pagination__total{
  margin: 5px 0 0 0;
}
.tb-pagination{
  margin: 12px 0 12px 12px;
}
/deep/ .el-input__inner{
  background: transparent;
  border: 0px solid transparent;
  // margin: 0 0 5px 0;
}
/deep/ .el-pagination button{
  background: transparent;
}
/deep/ .number{
  background: transparent;
}
/deep/ .el-icon-more{
  background: transparent;
}
</style>
  <style>
.el-cascader__dropdown {
  background: #02173d;
}
</style>