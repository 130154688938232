<template>
  <div class="cpt-command-situation-detail">
    <div class="header" v-interact>
      <div class="left">
        <div class="icon">
          <img src="@/assets/zong_lan_ye_img/frame/icon.png" alt="" />
        </div>
      <div class="hd-box">警情详情</div>
      </div>
      <div class="hd-box-r cp mr20" @click="pupClose()">关闭</div>
    </div>

    <div class="flight_detail_box">
      <div class="flight_detail_list  textarea">
        <span>警情事件内容</span>
        <div>{{ situation_detail.content || '暂无' }}</div>
      </div>
      <div class="flight_detail_list textarea">
        <span>内容描述</span>
        <div>{{ situation_detail.remark || '暂无' }}</div>
      </div>
      <div class="flight_detail_list">
        <span>航线名称</span>
        <div>{{ situation_detail.flight_line_name || '暂无' }}</div>
      </div>
      <div class="flight_detail_list">
        <span>接警员(分机)</span>
        <div>{{ situation_detail.receiveNumber || '暂无' }}</div>
      </div>
      <div class="flight_detail_list">
        <span>飞手</span>
        <div>
          {{
            situation_detail.team && situation_detail.team.length
              ? situation_detail.team.join('、')
              : '暂无'
          }}
        </div>
      </div>
      <div class="flight_detail_list">
        <span>更新时间</span>
        <div>{{ situation_detail.edittime || '暂无' }}</div>
      </div>
      <div class="flight_detail_list">
        <span>警情执行状态</span>
        <div>{{ get_status(status) }}</div>
      </div>
      <div class="flight_detail_list">
        <span>调度状态</span>
        <div>{{ situation_detail.approvalStatus || '暂无' }}</div>
      </div>
      <div class="flight_detail_list">
        <span>添加时间</span>
        <div>{{ situation_detail.addTime || '暂无' }}</div>
      </div>
      <div class="flight_detail_list">
        <span>责任单位</span>
        <div>{{ situation_detail.policeUnitTitle || '暂无' }}</div>
      </div>
      <div class="flight_detail_list ">
        <span>协助单位</span>
        <div>{{ situation_detail.assistUnitTitle || '暂无' }}</div>
      </div>
      <div class="flight_detail_list ">
        <span>报警时间</span>
        <div>{{ situation_detail.receiveTime || '暂无' }}</div>
      </div>
    </div>
    <div class="btn" @click="pupClose(1)">关闭</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {}
  },
  // inject: ['flight_task_chage_view'],
  computed: {
    ...mapGetters(['situation_detail']),
  },
  methods: {
    get_status(item) {
      if (item == -1) {
        item = 5
      }
      const obj = {
        0: '待审批',
        1: '审批不通过',
        2: '待执行',
        3: '执行中',
        4: '已完成',
        5: '待派发',
      }
      return obj[item]
    },
    pupClose(){
      this.$emit('handleClose', 'podetail')
    }
  },
}
</script>

<style lang="scss" scoped>
.cpt-command-situation-detail {
  position: absolute;
  width: 700px;
  min-height: 420px;
  box-sizing: border-box;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 13px;
  left: calc(100% - 850px);
  top: 50px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 32px;
  background: rgba(16, 65, 215, 0.2);
  box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
  border-radius: 10px 10px 0 0;
.left{
  margin-left: 12px;
  display: flex;
  align-items: center;
}
  }
  .hd-box-r{
    font-size: 16px;
font-family: MicrosoftYaHei;
color: #D2DFFF;
      cursor: pointer;
  }
.hd-box {
  font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    // color: #00ffff;
    background-image: -webkit-linear-gradient(right, #E3AA77, #F5CDA9,#F9ECD3,#FCDBB1,#EDB07A);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 0;
    font-weight: 700;
    margin-left: 4px;
}
.el-button {
  border: 0;
  opacity: 0.8;
  font-family: PangMenZhengDao;
  font-size: 22px;
  color: #00ffff;
  text-align: center;
  font-weight: 400;
  width: 114px;
  height: 40px;
  padding: 10px 20px;
  background: rgba(0, 34, 140, 0.2);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 15px 0 #00b6ff;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  bottom: 20px;
}
.flight_detail_box {
  box-sizing: border-box;
  margin-top: 12px;
  padding-bottom: 32px;
  // padding-top: 12px;
  font-family: MicrosoftYaHeiUI;
  font-size: 14px;
  color: #fff;
  width: 676px;
  height: 340px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .flight_detail_list {
    width: 46%;
    height: 32px;
    font-size: 14px;
    display: flex;
    align-items: center;
    line-height: 20px;
    margin-bottom: 16px;
    > span {
      margin-right: 16px;
      display: inline-block;
      width: 180px;
      text-align: right;
      // white-space: nowrap;
    }
    > div {
      // line-height: 32px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      width: 396px;
      min-height: 32px;
      padding: 10px;
      font-family: MicrosoftYaHeiUI;
      font-size: 15px;
      color: #00DFFF;

      background: rgba(2, 31, 51, 0.7);
      border: 1px solid #315EC7;
      padding: 0 15px;
    }
  }
  .textarea {
    height: 80px;
    div {
      height: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      line-height: normal;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
.btn{
  margin-top: 32px;
  position: absolute;
  left: 45%;
  bottom: 10px;
  width: 90px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
font-family: SourceHanSansCN-Medium, SourceHanSansCN;
font-weight: 500;
color: #E0F6FF;
  background: url("~@/assets/zong_lan_ye_img/btn_lan.png") no-repeat;
  background-size: 100% 100%;
}
</style>
