<template>
  <div class="cpt-command-task-check-popup">
    <div class="header" v-interact>
      <div class="left">
        <div class="icon">
          <img src="@/assets/zong_lan_ye_img/frame/icon.png" alt="" />
        </div>
        <div class="hd-box">空域审批</div>
      </div>
      <div class="hd-box-r cp mr20" @click="pupClose()">关闭</div>
    </div>

    <div class="popup_content">
      <div id="page-command-container"></div>
    </div>
    <!-- <div class="task_detail_box">
      <span>审批意见</span>
      <el-input v-model="remark" placeholder="请输入内容描述"> </el-input>
    </div> -->
    <div class="task_detail">
      <div class="task_detail_box">
        <span>申请单位</span>
        <div>{{ situation_detail.orgName || '暂无' }}</div>
      </div>
      <div class="task_detail_box">
        <span>申请时间</span>
        <div>{{ situation_detail.addTime || '暂无' }}</div>
      </div>
    </div>
    <div class="btns">
      <el-button class="btn_second" @click="handle_click(1)">审批通过</el-button>
      <el-button class="btn_first" @click="handle_click(0)">审批不通过</el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import API from '@/api'
import { Utils } from '@/lib/cesium'
import bus from '@/utils/bus'

let viewer = null
let LAYERS = null
let air_line_preview = []
let isSwtxDev = process.env.VUE_APP_SWTX_ENV === 'development'
if (isSwtxDev) {
  LAYERS = Object.freeze({
    MINE_MAP: {
      BASIC: {
        url:
          'https://datahive.minedata.cn/2d/raster/q4/mercator/5873/raster/{z}/{x}/{y}',
      },
      TEXT: {
        url:
          'https://datahive.minedata.cn/2d/raster/q4/mercator/5873/text/{z}/{x}/{y}',
      },
    },
  })
} else {
  LAYERS = Object.freeze({
    MINE_MAP: {
      BASIC: {
        url: 'http://32.128.12.23:58088/minemap-data/data/raster/{sz}/{x}/{y}',
        tilingScheme: new Cesium.GeographicTilingScheme(),
        customTags: {
          sz: function (imageryProvider, x, y, level) {
            return level + 1
          },
        },
      },
      TEXT: {
        url: 'http://32.128.12.23:58088/minemap-data/data/text/{sz}/{x}/{y}',
        tilingScheme: new Cesium.GeographicTilingScheme(),
        customTags: {
          sz: function (imageryProvider, x, y, level) {
            return level + 1
          },
        },
      },
    },
  })
}

export default {
  data () {
    return {
      remark: '',
    }
  },
  async mounted () {
    await this.init_viewer()
    this.show_xfq()
    console.log('this.situation_detail', this.situation_detail)
    if (this.situation_detail?.flight_name) {
      await this.handleAirLinePreview(this.situation_detail)
    }
  },
  computed: {
    ...mapGetters(['situation_is_show', 'situation_detail']),
  },
  methods: {
    async show_xfq () {
      // 禁飞区
      let data1 = await API.AIRSPACE.GetNotFlyAreas()
      console.log("禁飞区2");

      // 电子围栏
      let data2 = await API.AIRSPACE.GetAreaList()
      // 当前机构自己的电子围栏
      let data3 = await API.AIRSPACE.GetCurrentAreaList()
      // 限高区
      let data4 = await API.AIRSPACE.GetLimitHeightAreas()
      data3.forEach((item) => {
        item.typeId = 4
      })
      let _this = this
      let data = [...data1, ...data2, ...data3, ...data4] || []

      console.log('---------data1', data1)
      console.log('---------data4', data4)

      data.forEach((item) => {
        _this.init_area(item.regionType, item.regionList, item)
      })
    },
    init_area (regionType, positions, item) {
      if (regionType == 'CIRCLE') {
        viewer.entities.add({
          position: Cesium.Cartesian3.fromDegrees(positions[0], positions[1]),
          ellipse: {
            semiMajorAxis: positions[2],
            semiMinorAxis: positions[2],
            height: 8,
            material: Cesium.Color.fromCssColorString(
              item.color || 'red'
            ).withAlpha(0.3),
            outline: true,
            outlineColor: Cesium.Color.fromCssColorString(item.color || 'red'),
            zIndex: item.tier,
          },
          show: true,
          id: JSON.stringify(item),
          name: 'area',
        })
      }
      if (regionType == 'POLYGON') {
        viewer.entities.add({
          polygon: {
            hierarchy: {
              positions: Cesium.Cartesian3.fromDegreesArray(positions),
            },
            // height: 50000,
            outline: true,
            outlineColor: Cesium.Color.fromCssColorString(item.color || 'red'),
            outlineWidth: 2,
            material: Cesium.Color.fromCssColorString(
              item.color || 'red'
            ).withAlpha(0.3),
            zIndex: item.tier,
          },
          show: true,
          id: JSON.stringify(item),
          name: 'area',
        })
      }
    },
    async handle_click (status) {
      let { situation_detail } = this
      let res = await API.USER.approval(situation_detail.id, status)
      bus.$emit('refreshArilist')
      this.$el_message('审批成功')
      this.pupClose();
    },
    // 航线预览
    async handleAirLinePreview (item) {
      try {
        let res = {}
        res.flightCourseJson = item.flightCourseJson
        if (res?.flightCourseJson) {
          let positions = []
          let polyline = JSON.parse(res.flightCourseJson)
          let label_arr = []
          let _this = this
          polyline.points.forEach((item, index) => {
            positions.push(item.lon, item.lat, item.alt)
            label_arr.push(
              Cesium.Cartesian3.fromDegrees(item.lon, item.lat, item.alt)
            )
            let point_entity = viewer.entities.add({
              position: Cesium.Cartesian3.fromDegrees(
                item.lon,
                item.lat,
                item.alt
              ),
              name: 'show_airline_point',
              point: {
                pixelSize: 10,
                // color: Cesium.Color.RED,
                color: Cesium.Color.fromCssColorString('#00A9A9'),
                // fillColor: Cesium.Color.RED,
                // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
              },
              label: {
                text: String(item.alt) + 'm',
                scale: 0.6,
                font: 'bold 28px Microsoft YaHei',
                // fillColor: Cesium.Color.BLUE,
                fillColor: Cesium.Color.fromCssColorString('#FF7F09'),
                horizontalOrigin: Cesium.VerticalOrigin.CENTER,
                verticalOrigin: Cesium.VerticalOrigin.CENTER,
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
                showBackground: false,
                outlineWidth: 0,
                pixelOffset: new Cesium.Cartesian2(25, -10),
              },
            })
            if (label_arr.length > 1) {
              let before = label_arr[label_arr.length - 2]
              let after = label_arr[label_arr.length - 1]
              _this.create_label(before, after)
            }
          })
          positions = Cesium.Cartesian3.fromDegreesArrayHeights(positions)

          let redLine = viewer.entities.add({
            name: 'Red line on terrain',
            polyline: {
              positions: new Cesium.CallbackProperty(() => {
                return positions
              }, false),
              width: 4,
              // material: Cesium.Color.RED,
              material: new Cesium.PolylineArrowMaterialProperty(
                Cesium.Color.fromCssColorString("#00A9A9"),
              ),
              // clampToGround: true,
            },
          })
          viewer.flyTo(redLine)
        } else {
          this.$el_message('暂无航线', () => { }, 'error')
        }
      } catch (error) {
        this.$el_message('暂无航线', () => { }, 'error')
      }
    },

    create_label (before, after) {
      let before_wgs84 = Utils.transformCartesian2WGS84(before)
      let after_wgs84 = Utils.transformCartesian2WGS84(after)

      let center_lng = (before_wgs84.lng + after_wgs84.lng) / 2
      let cetner_lat = (before_wgs84.lat + after_wgs84.lat) / 2
      let alt = (after_wgs84.alt + before_wgs84.alt) / 2

      let position = Utils.transformWGS842Cartesian({
        lng: center_lng,
        lat: cetner_lat,
        alt: alt + 10,
      })

      let text = `${Cesium.Cartesian3.distance(before, after).toFixed(2)} m`

      let label_entity = viewer.entities.add({
        id: `label_${before}`,
        position: position,
        label: {
          text: text,
          scale: 0.5,
          font: 'bold 30px Microsoft YaHei',
          fillColor: Cesium.Color.fromCssColorString('#fff'),
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          showBackground: true,
          backgroundPadding: new Cesium.Cartesian2(10, 10),
        },
      })
    },

    async init_viewer () {
      // 解决航线被三维模型遮盖
      var oldPolylineUpdate = Cesium.PolylineCollection.prototype.update
      Cesium.PolylineCollection.prototype.update = function (frameState) {
        var oldMorphTime = frameState.morphTime
        frameState.morphTime = 0.0
        oldPolylineUpdate.call(this, frameState)
        frameState.morphTime = oldMorphTime
      }

      let options = {
        layers: [LAYERS.MINE_MAP.BASIC, LAYERS.MINE_MAP.TEXT],
      }
      // viewer = new Viewer("layer-container");
      viewer = new Cesium.Viewer('page-command-container', {
        animation: false, //是否显示动画控件
        shouldAnimate: true,
        homeButton: false, //是否显示Home按钮
        fullscreenButton: false, //是否显示全屏按钮
        baseLayerPicker: false, //是否显示图层选择控件
        geocoder: true, //是否显示地名查找控件
        timeline: false, //是否显示时间线控件
        sceneModePicker: true, //是否显示投影方式控件
        navigationHelpButton: false, //是否显示帮助信息控件
        infoBox: false, //是否显示点击要素之后显示的信息
        requestRenderMode: true, //启用请求渲染模式
        scene3DOnly: false, //每个几何实例将只能以3D渲染以节省GPU内存
        sceneMode: 3, //初始场景模式 1 2D模式 2 2D循环模式 3 3D模式  Cesium.SceneMode
        fullscreenElement: document.body, //全屏时渲染的HTML元素 暂时没发现用处
        selectionIndicator: false,
        animation: false, //是否显示动画控件
        // imageryProvider: false,
        // imageryProvider: new Cesium.ArcGisMapServerImageryProvider({
        //   url: "https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer",
        // }),
        imageryProvider: new Cesium.UrlTemplateImageryProvider(
          options.layers[0]
        ),
      })

      let services = [
        '3D-1-1',
        '3D-1-2',
        '3D-1-3',

        '3D-3',

        '3D-4-1',
        '3D-4-2',
        '3D-4-3',
        '3D-4-4',

        '3D-6-1',
        '3D-6-2',
        '3D-6-3',

        '3D-7-1',
        '3D-7-2',
        '3D-7-3',

        '3D-10-1',
        '3D-10-2-1',
        '3D-10-2-2',
        '3D-10-3',

        '3D-11-1',
        '3D-11-2',
        '3D-11-3',

        '3D-12',

        '3D-13-1',
        '3D-13-2',
        '3D-13-3',

        '3D-15-1',
        '3D-15-2',

        '3D-16-1',
        '3D-16-2',
        '3D-16-3',

        '3D-18-1',
        '3D-18-2',
        '3D-18-3',
        '3D-18-4',
        '3D-18-5',
        '3D-18-6',

        '3D-19-1',
        '3D-19-2',
        '3D-19-3',
        '3D-19-4',

        '3D-shizhengfu',
      ]

      if (options.layers && options.layers.length) {
        options.layers.forEach((layerOptions) => {
          viewer.imageryLayers.addImageryProvider(
            new Cesium.UrlTemplateImageryProvider(layerOptions)
          )
        })
      }

      services.forEach((item) => {
        viewer.scene.addS3MTilesLayerByScp(
          `http://32.128.6.46:8090/iserver/services/${item}/rest/realspace/datas/Config/config`,
          {
            name: item,
          }
        )
      })

      viewer.scene.globe.depthTestAgainstTerrain = false
    },
    pupClose () {
      this.$emit('handleClose', 'app')
    }
  },
}
</script>

<style lang="scss" scoped>
.cpt-command-task-check-popup {
  width: 650px;
  height: 482px;
  position: absolute;
  // padding: 0 50px 50px 50px;
  top: 150px;
  left: 50%;
  margin-left: -289px;
  background: rgba(22, 29, 45, 0.90);
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.50);
  border-radius: 10px;
  background-size: 100% 100%;
  z-index: 999;

  .task_detail_box {
    padding-top: 10px;
    display: flex;

    &>span {
      line-height: 40px;
      display: inline-block;
      width: 100px;
      text-align: right;
      margin-right: 20px;
      font-size: 16px;
      color: #ccedff;
    }
  }

  ::v-deep {
    .el-input {
      width: 180px;
      background: #000000;
      border: 1px solid #08c2d1;

      .el-input__inner {
        background: transparent;
        border: none;
        color: #08c2d1;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 44px;
    background: rgba(0, 34, 140, 0.20);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.50);
    border-radius: 10px 10px 0 0;

    .left {
      margin-left: 12px;
      display: flex;
      align-items: center;
    }
  }

  .hd-box-r {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #D2DFFF;
    cursor: pointer;
  }

  .hd-box {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    // color: #00ffff;
    background-image: -webkit-linear-gradient(right, #E3AA77, #F5CDA9, #F9ECD3, #FCDBB1, #EDB07A);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 0;
    font-weight: 700;
    margin-left: 4px;
  }

  .task_detail {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;

    .task_detail_box {
      display: flex;
      margin-bottom: 10px;

      &>span {
        line-height: 40px;
        display: inline-block;
        width: 65px;
        text-align: right;
        margin-right: 20px;
        font-size: 16px;
        color: #ccedff;
      }

      &>div {
        width: 184px;
        height: 40px;
        background: #000000;
        border: 1px solid #315EC7;
        font-family: MicrosoftYaHeiUI;
        font-size: 16px;
        color: #ccedff;
        line-height: 40px;
        padding-left: 15px;
        box-sizing: border-box;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
    }
  }

  .popup_content {
    height: 330px;

    #page-command-container {
      width: 100%;
      height: 100%;
    }
  }

  .btns {
    display: flex;
    // width: 430px;
    justify-content: center;
    align-items: center;

    // margin-left: 54px;
    .el-button {
      width: 90px;
      height: 32px;
      line-height: 32px;
      padding: 0;
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #E0F6FF;
      border: none;
      border-radius: 0;

      &:hover {
        opacity: 0.7;
      }
    }

    .btn_first {
      background: url("~@/assets/zong_lan_ye_img/btn_lv.png");
      background-size: 100%;
      //       background: rgba(201,6,6,0.30);
      // box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50), inset 0 0 10px 0 #FF5C00;
      border-radius: 6px;
    }

    .btn_second {
      background: url("~@/assets/zong_lan_ye_img/btn_lan.png");
      background-size: 100%;
      //       background: rgba(0,34,140,0.20);
      // box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50), inset 0 0 15px 0 #00B6FF;
      border-radius: 6px;
    }
  }
}
</style>
