<!-- 飞行统计 -->
<template>
  <div class="main">
    <div class="card card1">
      <div class="content">
        <img src="~@/assets/img/sum.png" />
        <div class="text">
          <span class="num">{{ data[0] && data[0].data || "196" }}</span>
          <span class="name">累计飞行架次(次)</span>
        </div>
      </div>
    </div>
    <div class="card card2">
      <div class="content">
        <img src="~@/assets/img/sum.png" />
        <div class="text">
          <span class="num">{{ parseFloat(data[1] && Number(data[1].data / 1000).toFixed(2)) || "0" }}</span>
          <span class="name">累计飞行距离(km)</span>
        </div>
      </div>
    </div>
    <div class="card card3">
      <div class="content">
        <img src="~@/assets/img/sum.png" />
        <div class="text">
          <span class="num">{{ parseFloat(data[2] && Number(data[2].data).toFixed(3)) || "0" }}</span>
          <span class="name">累计飞行时长(h)</span>
        </div>
      </div>
    </div>
    <div class="card card4">
      <div class="content">
        <img src="~@/assets/img/sum.png" />
        <div class="text">
          <span class="num">{{ data[3] && data[3].data || "196" }}</span>
          <span class="name">累计任务数量(pcs)</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 接口文件
import API from "@/api";
import Api from "@/api/modules/newOverview.js";
export default {
  data () {
    return {
      filter1: {
        organizationId: null,
      },
      options: [], //级联选择器
      pickTime: null, //日期
      pickerOptions: {
        // 日期规则
        disabledDate (time) {
          return time.getTime() >= Date.now();
        },
      },
      data: [], //飞行统计
      search: {
        //查询条件
        begain: "2023-01-03",
        end: "2023-05-28",
      },
      type: 1, //查询条件
      searchData: [], //搜索的值
      arraylegth: 20, //最后传过来的的数组长度,小于20则为没有下一页
      list: [], //前十排名
      orderBy: null, //查询什么(默认飞行次数)
      ranking: "", //什么排名
      columnData: [],
    };
  },
  mounted () {
    this.stat_monitor();
    this.getOptions();
    // this.getFlight()
  },
  watch: {
    "filter1.organizationId": function (vla, old) {
      this.$refs.elcascader4.dropDownVisible = false;
    },
  },
  methods: {
    // 飞行统计
    async getAllAlarmNum (title, num) {
      let type = null;
      if (!this.search.begain) {
        type = 1;
      }
      let res = await API.HOME.getAllAlarmNum({
        orgId: this.filter1.organizationId,
        begain: this.search.begain,
        end: this.search.end,
        type,
        pageNum: 1,
        pageSize: 9999,
      });
      this.columnData = [
        "单位名称",
        "任务名称",
        "航线名称",
        "任务类型",
        "执行状态",
      ];
      this.searchData = res;
      this.arraylegth = this.searchData.length;
      this.orderBy = true;
      this.ranking = title;
    },
    // 任务数量
    async getFlightdetail (title, num) {
      let type = null;
      if (!this.search.begain) {
        type = 1;
      }
      let res = await API.HOME.getFlightdetail({
        orgId: this.filter1.organizationId,
        begain: this.search.begain,
        end: this.search.end,
        type,
        pageNum: 1,
        pageSize: 9999,
      });
      this.searchData = res;
      this.arraylegth = this.searchData.length;
      this.ranking = title;
      this.orderBy = true;
      if (num == 1) {
        this.columnData = ["单位名称", "无人机名称", "航线名称", "飞行次数"];
      } else if (num == 2) {
        this.columnData = [
          "单位名称",
          "无人机名称",
          "任务名称",
          "航线名称",
          "飞行距离(m)",
        ];
      } else if (num == 3) {
        this.columnData = [
          "单位名称",
          "无人机名称",
          "任务名称",
          "航线名称",
          "飞行时长(h)",
        ];
      }
    },
    // 飞行统计
    async stat_monitor (val) {
      if (!this.search.begain || !this.search.end) {
        this.search = {
          type: 1,
        };
      } else {
        this.search.type = null;
      }
      let res = await API.TASK.StatMonitor({
        type: this.type,
        ...this.search,
        organId: this.filter1.organizationId,
      });
      // console.log(res, '飞行统计');
      this.data = res;
      if (this.$store.state.user.user_info.username == "dongtai") {
        this.data = [];
        this.data[0] = {data:72};
        this.data[1] = {data:964800};
        this.data[2] = {data:21.6};
        this.data[3] = {data:55};
      }
    },
    async getFlight () {
      if (!this.search.begain || !this.search.end) {
        this.search = {
          type: 1,
        };
      } else {
        this.search.type = null;
      }
      let res = await Api.getAllFlightKm({
        ...this.search,
        organId: this.filter1.organizationId,
        pageNum: 1,
        pageSize: 10000,
        orderBy: this.orderBy,
      });
      this.list = res;
      // console.log(res, 'flyflyflyflyflyflyfly');
    },
    // 地区选择框
    async getOptions () {
      let res = await API.DEPARTMENT.Drd();
      this.options = [res] || [];
    },
    // 飞行架次
    flyNum () {
      this.orderBy = "flyNum";
      this.ranking = "飞行架次(次)";
      this.getFlight();
    },
    // 飞行距离
    // distance() {
    //   this.orderBy = "distance";
    //   this.ranking = "飞行里程(km)";
    //   this.getFlight();
    // },
    // // 飞行时长
    // duration() {
    //   this.orderBy = "duration";
    //   this.ranking = "飞行时长(h)";
    //   this.getFlight();
    // },
    // // 任务数量
    // async getAllAlarm() {
    //   if (!this.search.begain || !this.search.end) {
    //     this.search = {
    //       type: 1,
    //     };
    //   } else {
    //     this.search.type = null;
    //   }
    //   let data = {
    //     ...this.search,
    //     orgId: this.filter1.organizationId,
    //   }
    //   console.log(data,"data");
    //   let res = await Api.getAllAlarmNumOrderBy(data);
    //   this.orderBy = "task";
    //   this.ranking = "任务数量(pcs)";
    //   this.list = res;
    //   console.log(this.list, "this.listthis.listthis.list");
    // },
    async handle_change (e) {
      if (e?.length) {
        this.search.begain = e[0];
        this.search.end = e[1];
        this.time = [e[0], e[1]];
        if (e[0] == e[1]) {
          this.search.end = null;
        }
      } else {
        this.search.begain = null;
        this.search.end = null;
      }
      this.type = null;
      this.stat_monitor();
    },
  },
};
</script>

<style scoped lang="scss">
.flex {
  display: flex;
}

.main {
  width: 36vw;
  height: 12vh;
  background-color: rgba(35, 47, 79, 0.0);
  //border-radius: 12px;
  color: #fff;
  font-family: PingFangSC-Semibold, PingFang SC;
  display: flex;
  flex-direction: row;
  //border: 1px solid yellow;
  align-items: center;
  justify-content: space-between;

  .card {
    width: 24%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //border: 1px solid pink;
    background: linear-gradient(360deg, #14203E 0%, rgba(28, 74, 93, 0.91) 100%);

    .content {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .text {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-left: 10px;

        .num {
          font-size: 20px;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          color: #00F5FF;
        }

        .name {
          font-size: 12px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #5F7799;
          margin-top: 5px;
        }
      }
    }
  }

  .card1 {
    .content {
      background-image: url("~@/assets/img/b-card.png");
    }
  }

  .card2 {
    .content {
      background-image: url("~@/assets/img/b-card.png");
    }
  }

  .card3 {
    .content {
      background-image: url("~@/assets/img/b-card.png");
    }
  }

  .card4 {
    .content {
      background-image: url("~@/assets/img/b-card.png");
    }
  }
}

.head {
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 12px 0 12px;
  align-items: center;
  background-image: url("~@/assets/zong_lan_ye_img/kuang_title_bg.png");
  background-repeat: no-repeat;
  background-size: 274px 42px;

  // border-bottom: 1px solid #474772;
  .title {
    font-size: 18px;
    line-height: 28px;
    margin-left: 14px;
    font-weight: 600;
  }

  .more {
    font-size: 14px;
    line-height: 25px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    img {
      margin-left: 3px;
      width: 11px;
      height: 17px;
    }
  }

  .biaoti2 {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }

  .region {
    width: 132px;
    height: 24px;
    border: 1px solid rgba(255, 255, 255, 0.21);
    box-sizing: border-box;
    padding: 0 0 0 11px;
    align-items: center;
    margin-top: 5px;

    .ico {
      width: 11px;
      height: 10px;
      display: flex;
      justify-content: center;
    }
  }

  .over_hidder {
    overflow: hidden;
  }

  /deep/.el-date-editor {
    width: 100%;

    .el-range__close-icon {
      left: 95px;
      top: -4px;
      position: absolute;
    }
  }

  /deep/ .el-input__inner {
    height: 22px !important;
    background: transparent;
    border: 0px solid transparent;
  }

  .mr_10 {
    margin: 0 0 0 10px;
  }

  /deep/ .el-range-editor {
    padding: 0;

    .el-range__icon {
      line-height: 22px;
    }

    .el-range-input {
      background: transparent;
      margin: 0 0 0 10px;
      width: 80px;
    }
  }
}

.nei {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .fly_statistics {
    width: 100%;
    height: 100%;
    justify-content: center;
    gap: 20px;
    align-items: center;

    .fly_report {
      width: 81px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .report_img {
    width: 69px;
    height: 61px;
    cursor: pointer;
  }
}

.tab_ge {
  background: rgba(12, 34, 73, 1);
  border-radius: 10px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);
  position: fixed;
  width: 700px;
  top: 40%;
  left: calc(50% - 355px);
  box-sizing: border-box;
  margin: 0px 0 0 0;
  height: 300px;
  // height: calc(100% - 124px);
  display: flex;
  flex-direction: column;
  // 关键样式
  overflow: hidden;

  .header {
    display: flex;
    justify-content: space-between;
    height: 40px;
    background: linear-gradient(180deg,
        #9198ff 0%,
        rgba(45, 81, 153, 0.45) 40%,
        #05091a 100%);
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;

    .left,
    .right {
      color: #fff;
      display: flex;
      align-items: center;
    }
  }

  .Table_Properties {
    display: flex;
    flex-direction: column;

    .header_file {
      background: rgba(73, 135, 210, 0.2);
      width: 100%;
      align-items: center;
      box-sizing: border-box;
      justify-content: space-around;
      padding: 0 15px 0 15px;
      height: 40px;
      font-size: 16px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
      line-height: 22px;
    }
  }

  .areaItem {
    .list:nth-child(even) {
      background: rgba(73, 135, 210, 0.2);
    }

    .list:nth-child(odd) {
      background: transparent;
    }

    .areaItemRow {
      div {
        width: calc(100% / 4);
        overflow: hidden; //溢出隐藏
        white-space: nowrap; //禁止换行
        text-overflow: ellipsis; //...
        padding: 0 5px;
      }
    }

    .areaItemRowFly {
      div {
        width: calc(100% / 5);
        overflow: hidden; //溢出隐藏
        white-space: nowrap; //禁止换行
        text-overflow: ellipsis; //...
        padding: 0 5px;
      }
    }
  }

  .Table_Properties::-webkit-scrollbar {
    width: 0 !important;
  }

  .kuang {
    width: 100%;
    height: 50px;
    padding: 0 22px 0 30px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #ebf9ff;
  }
}

.fontTwo {
  font-size: 20px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  text-align: center;
}

.fontThree {
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  opacity: 0.5;
  font-weight: 400;
  color: #ffffff;
  line-height: 17px;
  text-align: center;
  white-space: nowrap;
}

.fly_chang {
  width: 70px;
  text-align: center;
  background: transparent !important;
  white-space: nowrap;
}

.fontimg {}</style>
