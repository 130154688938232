<!-- 总览页安全飞行注意事项 -->
<template>
    <div class="safetyFly">
        <div class="pup_head">
            <div class="flex">
                <div class="pup_img">
                    <img src="~@/assets/zong_lan_ye_img/jinye.png" style="width:100%;height:100%;" alt="">
                </div>
                <div class="fontOne">安全飞行注意事项</div>
            </div>
            <div class="fontTwo" @click="pupClose">关闭</div>
        </div>
        <div class="nei">
            <div class="kuang">
                <div class="mb18" v-for="item in securityList" :key="item.lightInfoId">{{ item.detail }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import API from "@/api";
export default {
    props: {
        securityFlyVisible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            securityList: [],
        };
    },
    mounted() {
        this.securityFly()
    },
    methods: {
        async securityFly() {
            let res = await API.SECURITYFLY.Security(3);
            this.securityList = res;
        },
        pupClose() {
            this.$emit('handleClose', 'safetyFly')
        },
    },
};
</script>
<style lang="scss" scoped>
.safetyFly {
    width: 672px;
    height: 287px;
    background: rgba(12, 34, 73, 0.7);
    border-radius: 10px;
    border: 1px solid rgba(26, 92, 246, 0.5);
    backdrop-filter: blur(1px);
}

.flex {
    display: flex;
}

.pup_head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 16px 0 12px;
    align-items: center;
    height: 32px;
    background: linear-gradient(180deg, #9198FF 0%, rgba(45, 81, 153, 0.45) 40%, #05091A 100%);
    box-shadow: inset 0px 0px 10px 2px #3F9DFF;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427DFF;

    .pup_img {
        display: flex;
        width: 19px;
        height: 25px;
        margin: 0 4px 0 0;
    }

    .fontOne {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14FAFF;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(135deg, #E3AA77 0%, #F5CDA9 38%, #F9ECD3 58%, #FCDBB1 79%, #EDB07A 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .fontTwo {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #D2DFFF;
        cursor: pointer;
    }
}

.nei {
    width: 100%;
    height: calc(100% - 32px);
    box-sizing: border-box;
    padding: 18px 16px 16px 16px;
}

.kuang {
    width: 640px;
    height: 224px;
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
}
</style>