<template>
  <div class="cpt-command-task-check-popup">

    <div class="header" v-interact>
      <div class="left">
        <div class="icon">
          <!-- <img src="@/assets/zong_lan_ye_img/frame/icon.png" alt="" /> -->
        </div>
        <div class="hd-box">任务审批</div>
      </div>
      <div class="hd-box-r cp" @click="pupClose()">
        <img src="@/assets/img/i40.png" alt="" class="img">
      </div>
    </div>
    <div class="popup_content">
      <!-- <div class="task_detail" v-if="!situation_is_show">
        <div class="task_detail_box">
          <span>警情事件内容</span>
          <div>{{ situation_detail.taskTitle || "暂无" }}</div>
        </div>
        <div class="task_detail_box">
          <span>航线名称</span>
          <div>{{ situation_detail.flightName || "暂无" }}</div>
        </div>
        <div class="task_detail_box">
          <span>警情类型</span>
          <div>{{ situation_detail.taskCateName || "暂无" }}</div>
        </div>
        <div class="task_detail_box">
          <span>执行状态</span>
          <div>待审批</div>
        </div>
        <div class="task_detail_box">
          <span>调度状态</span>
          <div>{{ get_approvalStatus(situation_detail.approvalStatus) }}</div>
        </div>
        <div class="task_detail_box">
          <span>责任单位</span>
          <div>{{ situation_detail.organizationName || "暂无" }}</div>
        </div>
        <div class="task_detail_box">
          <span>协助单位</span>
          <div>{{ situation_detail.assistUnit || "暂无" }}</div>
        </div>
        <div class="task_detail_box">
          <span>飞手</span>
          <div>
            {{ situation_detail.flyNames || "暂无" }}
          </div>
        </div>
        <div class="task_detail_box">
          <span>审批意见</span>
          <el-input v-model="remark" placeholder="请输入内容描述"> </el-input>
        </div>
      </div> -->

      <div class="task_detail">
        <div class="task_detail_box">
          <span>任务名称</span>
          <div>{{ situation_detail.taskTitle || "暂无" }}</div>
        </div>
        <div class="task_detail_box">
          <span>航线名称</span>
          <div>{{ situation_detail.flightName || "暂无" }}</div>
        </div>
        <div class="task_detail_box">
          <span>任务类型</span>
          <div>{{ situation_detail.taskTypeName || "暂无" }}</div>
        </div>
        <div class="task_detail_box">
          <span>执行状态</span>
          <div>待审批</div>
        </div>
        <div class="task_detail_box">
          <span>责任单位</span>
          <div>{{ situation_detail.organizationName || "暂无" }}</div>
        </div>
        <div class="task_detail_box">
          <span>飞手</span>
          <div>
            {{ situation_detail.flyNames || "暂无" }}
          </div>
        </div>
        <div class="task_detail_box">
          <span>预计开始时间</span>
          <div>{{ situation_detail.startedAt || "暂无" }}</div>
        </div>
        <div class="task_detail_box">
          <span>审批意见</span>
          <el-input v-model="remark" placeholder="请输入内容描述"> </el-input>
        </div>
      </div>
      <div id="page-command-container"></div>
    </div>
    <div class="btns">
      <el-button class="btn_first" @click="handle_click(0)">拒绝</el-button>
      <el-button class="btn_second" @click="handle_click(1)">通过</el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import API from "@/api";
import { Utils } from "@/lib/cesium";
import bus from "@/utils/bus";

let viewer = null;
let LAYERS = null;
let air_line_preview = [];
let isSwtxDev = process.env.VUE_APP_SWTX_ENV === "development";
import worldImg from '@/assets/images/world.jpg';

if (isSwtxDev) {
  LAYERS = Object.freeze({
    MINE_MAP: {
      BASIC: {
        url: "https://datahive.minedata.cn/2d/raster/q4/mercator/5873/raster/{z}/{x}/{y}",
        // url: "https://webrd01.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scale=1&style=8",
      },
      TEXT: {
        url: "https://datahive.minedata.cn/2d/raster/q4/mercator/5873/text/{z}/{x}/{y}",
      },
    },
  });
} else {
  LAYERS = Object.freeze({
    MINE_MAP: {
      BASIC: {
        url: "http://32.128.12.23:58088/minemap-data/data/raster/{sz}/{x}/{y}",
        // url: "https://webrd01.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scale=1&style=8",
        tilingScheme: new Cesium.GeographicTilingScheme(),
        customTags: {
          sz: function (imageryProvider, x, y, level) {
            return level + 1;
          },
        },
      },
      TEXT: {
        url: "http://32.128.12.23:58088/minemap-data/data/text/{sz}/{x}/{y}",
        tilingScheme: new Cesium.GeographicTilingScheme(),
        customTags: {
          sz: function (imageryProvider, x, y, level) {
            return level + 1;
          },
        },
      },
    },
  });
}

export default {
  data () {
    return {
      remark: "",
    };
  },
  inject: ["handle_check_popup_show"],
  async mounted () {
    console.log(this.situation_detail);
    await this.init_viewer();
    this.show_xfq()
    if (this.situation_detail?.taskTitle) {
      await this.handleAirLinePreview(this.situation_detail);
    }
  },
  computed: {
    ...mapGetters(["situation_is_show", "situation_detail"]),
  },
  methods: {
    async show_xfq () {
      // 禁飞区
      let data1 = await API.AIRSPACE.GetNotFlyAreas()
      console.log("禁飞区4");

      // 电子围栏
      let data2 = await API.AIRSPACE.GetAreaList()
      // 当前机构自己的电子围栏
      let data3 = await API.AIRSPACE.GetCurrentAreaList()
      // 限高区
      let data4 = await API.AIRSPACE.GetLimitHeightAreas()
      data3.forEach((item) => {
        item.typeId = 4
      })
      let _this = this
      let data = [...data1, ...data2, ...data3, ...data4] || []

      console.log('---------data1', data1)
      console.log('---------data4', data4)

      data.forEach((item) => {
        _this.init_area(item.regionType, item.regionList, item)
      })
    },
    init_area (regionType, positions, item) {
      if (regionType == 'CIRCLE') {
        viewer.entities.add({
          position: Cesium.Cartesian3.fromDegrees(positions[0], positions[1]),
          ellipse: {
            semiMajorAxis: positions[2],
            semiMinorAxis: positions[2],
            height: 8,
            material: Cesium.Color.fromCssColorString(
              item.color || 'red'
            ).withAlpha(0.3),
            outline: true,
            outlineColor: Cesium.Color.fromCssColorString(item.color || 'red'),
            zIndex: item.tier,
          },
          show: true,
          id: JSON.stringify(item),
          name: 'area',
        })
      }
      if (regionType == 'POLYGON') {
        viewer.entities.add({
          polygon: {
            hierarchy: {
              positions: Cesium.Cartesian3.fromDegreesArray(positions),
            },
            // height: 50000,
            outline: true,
            outlineColor: Cesium.Color.fromCssColorString(item.color || 'red'),
            outlineWidth: 2,
            material: Cesium.Color.fromCssColorString(
              item.color || 'red'
            ).withAlpha(0.3),
            zIndex: item.tier,
          },
          show: true,
          id: JSON.stringify(item),
          name: 'area',
        })
      }
    },
    async handle_click (status) {
      let { situation_detail, remark } = this;
      let res = await API.TASK.Edit({
        id: situation_detail.id,
        taskCateId: situation_detail.taskCateId,
        status,
        approvalRemark: remark,
      });
      bus.$emit("refreshArilist");

      this.$el_message("审批成功");
      // this.$emit("closeFn");
      this.pupClose()
      // this.handle_check_popup_show(0);
    },
    get_status_normal (item) {
      let val = item;
      let text = "";
      if (val.applyStatus == 1 && val.papplyStatus == 1) {
        text = "审批通过";

        if (val.executeStatus == 0) {
          text = "待执行";
        } else if (val.executeStatus == 1) {
          text = "已完成";
        } else {
          text = "执行中";
        }
      } else if (val.applyStatus == 2 || val.papplyStatus == 2) {
        text = "审批不通过";
      } else {
        text = "待审批";
      }
      return text;
    },
    get_status (item) {
      if (item.taskSituation == -1) {
        item.taskSituation = 5;
      }
      const obj = {
        0: "待审批",
        1: "审批不通过",
        2: "待执行",
        3: "执行中",
        4: "已完成",
        5: "待派发",
      };
      return obj[item.taskSituation];
    },
    get_approvalStatus (item) {
      if (item == 0) {
        return "待审批";
      } else if (item == 1) {
        return "通过";
      } else if (item == 2) {
        return "驳回";
      } else {
        return "暂无";
      }
    },

    // 航线预览
    async handleAirLinePreview (item) {
      try {
        let res = {};
        res.flightCourseJson = item.flightCourseJson;
        if (res?.flightCourseJson) {
          let positions = [];
          let polyline = JSON.parse(res.flightCourseJson);
          let label_arr = [];
          let _this = this;
          polyline.points.forEach((item, index) => {
            positions.push(item.lon, item.lat, item.alt);
            label_arr.push(
              Cesium.Cartesian3.fromDegrees(item.lon, item.lat, item.alt)
            );
            let point_entity = viewer.entities.add({
              position: Cesium.Cartesian3.fromDegrees(
                item.lon,
                item.lat,
                item.alt
              ),
              name: "show_airline_point",
              point: {
                pixelSize: 10,
                // color: Cesium.Color.RED,
                color: Cesium.Color.fromCssColorString("#00A9A9"),
                // fillColor: Cesium.Color.RED,
                // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
              },
              label: {
                text: String(item.alt) + "m",
                scale: 0.6,
                font: "bold 28px Microsoft YaHei",
                // fillColor: Cesium.Color.BLUE,
                fillColor: Cesium.Color.fromCssColorString("#FF7F09"),
                horizontalOrigin: Cesium.VerticalOrigin.CENTER,
                verticalOrigin: Cesium.VerticalOrigin.CENTER,
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
                showBackground: false,
                outlineWidth: 0,
                pixelOffset: new Cesium.Cartesian2(25, -10),
              },
            });
            if (label_arr.length > 1) {
              let before = label_arr[label_arr.length - 2];
              let after = label_arr[label_arr.length - 1];
              _this.create_label(before, after);
            }
          });
          positions = Cesium.Cartesian3.fromDegreesArrayHeights(positions);

          let redLine = viewer.entities.add({
            name: "Red line on terrain",
            polyline: {
              positions: new Cesium.CallbackProperty(() => {
                return positions;
              }, false),
              width: 4,
              // material: Cesium.Color.RED,
              material: new Cesium.PolylineArrowMaterialProperty(
                Cesium.Color.fromCssColorString("#00A9A9"),
              ),
              // clampToGround: true,
            },
          });
          viewer.flyTo(redLine);
        } else {
          this.$el_message("暂无航线", () => { }, "error");
        }
      } catch (error) {
        this.$el_message("暂无航线", () => { }, "error");
      }
    },

    create_label (before, after) {
      let before_wgs84 = Utils.transformCartesian2WGS84(before);
      let after_wgs84 = Utils.transformCartesian2WGS84(after);

      let center_lng = (before_wgs84.lng + after_wgs84.lng) / 2;
      let cetner_lat = (before_wgs84.lat + after_wgs84.lat) / 2;
      let alt = (after_wgs84.alt + before_wgs84.alt) / 2;

      let position = Utils.transformWGS842Cartesian({
        lng: center_lng,
        lat: cetner_lat,
        alt: alt + 10,
      });

      let text = `${Cesium.Cartesian3.distance(before, after).toFixed(2)} m`;

      let label_entity = viewer.entities.add({
        id: `label_${before}`,
        position: position,
        label: {
          text: text,
          scale: 0.5,
          font: "bold 30px Microsoft YaHei",
          fillColor: Cesium.Color.fromCssColorString("#fff"),
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          showBackground: true,
          backgroundPadding: new Cesium.Cartesian2(10, 10),
        },
      });
    },

    async init_viewer () {
      // 解决航线被三维模型遮盖
      // var oldPolylineUpdate = Cesium.PolylineCollection.prototype.update;
      // Cesium.PolylineCollection.prototype.update = function (frameState) {
      //   var oldMorphTime = frameState.morphTime;
      //   frameState.morphTime = 0.0;
      //   oldPolylineUpdate.call(this, frameState);
      //   frameState.morphTime = oldMorphTime;
      // };

      let options = {
        layers: [LAYERS.MINE_MAP.BASIC, LAYERS.MINE_MAP.TEXT],
      };
      // viewer = new Viewer("layer-container");
      viewer = new Cesium.Viewer("page-command-container", {
        animation: false, //是否显示动画控件
        shouldAnimate: true,
        homeButton: false, //是否显示Home按钮
        fullscreenButton: false, //是否显示全屏按钮
        baseLayerPicker: false, //是否显示图层选择控件
        geocoder: false, //是否显示地名查找控件
        timeline: false, //是否显示时间线控件
        sceneModePicker: false, //是否显示投影方式控件
        navigationHelpButton: false, //是否显示帮助信息控件
        infoBox: false, //是否显示点击要素之后显示的信息
        requestRenderMode: true, //启用请求渲染模式
        scene3DOnly: false, //每个几何实例将只能以3D渲染以节省GPU内存
        sceneMode: 3, //初始场景模式 1 2D模式 2 2D循环模式 3 3D模式  Cesium.SceneMode
        fullscreenElement: document.body, //全屏时渲染的HTML元素 暂时没发现用处
        selectionIndicator: false,
        animation: false, //是否显示动画控件
        skybox: false, //
        // imageryProvider: false,
        // imageryProvider: new Cesium.ArcGisMapServerImageryProvider({
        //   url: "https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer",
        // }),
        // imageryProvider: new Cesium.UrlTemplateImageryProvider(
        //   options.layers[0]
        // ),
        // imageryProvider: new Cesium.SingleTileImageryProvider({
        //   url: worldImg
        // }),
      });
      viewer.cesiumWidget.creditContainer.style.display = 'none';
      viewer.scene.skyBox.show = false //关闭天空盒，否则会显示天空颜色
      viewer.scene.backgroundColor = Cesium.Color.fromCssColorString("rgba(0, 0, 0, 0)")
      // 添加天地图注记
      let tiandituImageLabelLayer = new Cesium.ImageryLayer(new Cesium.WebMapTileServiceImageryProvider({
        url: "https://{s}.tianditu.com/cia_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=cia&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default.jpg&tk=094247775c509d9f6f2856f8fd660b33",
        layer: "tdtAnnoLayer",
        style: "default",
        format: "image/jpeg",
        tileMatrixSetID: "GoogleMapsCompatible",
        subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
        crossorigin: 'anonymous',
      }), {
        show: true,
        alpha: 1
      });

      let imageLayer1 = new Cesium.ImageryLayer(new Cesium.WebMapTileServiceImageryProvider({
        url: "https://{s}.tianditu.gov.cn/img_c/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE={style}&TILEMATRIXSET={TileMatrixSet}&FORMAT=tiles&TILEMATRIX={TileMatrix}&TILEROW={TileRow}&TILECOL={TileCol}&tk=094247775c509d9f6f2856f8fd660b33",
        layer: "img",
        style: "default",
        format: "tiles",
        tileMatrixSetID: "c",
        credit: new Cesium.Credit("天地图全球影像服务"),
        tilingScheme: new Cesium.GeographicTilingScheme(), //采用经纬度投影
        subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
        tileMatrixLabels: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
        ],
        maximumLevel: 19,
      }));
      let ngp1 = new Cesium.UrlTemplateImageryProvider({
        url: "https://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetPurplishBlue/MapServer/tile/{z}/{y}/{x}",
      });
      // viewer.imageryLayers.add(imageLayer1);
      viewer.imageryLayers.addImageryProvider(ngp1);
      // viewer.imageryLayers.add(tiandituImageLabelLayer);
      let services = [
        "3D-1-1",
        "3D-1-2",
        "3D-1-3",

        "3D-3",

        "3D-4-1",
        "3D-4-2",
        "3D-4-3",
        "3D-4-4",

        "3D-6-1",
        "3D-6-2",
        "3D-6-3",

        "3D-7-1",
        "3D-7-2",
        "3D-7-3",

        "3D-10-1",
        "3D-10-2-1",
        "3D-10-2-2",
        "3D-10-3",

        "3D-11-1",
        "3D-11-2",
        "3D-11-3",

        "3D-12",

        "3D-13-1",
        "3D-13-2",
        "3D-13-3",

        "3D-15-1",
        "3D-15-2",

        "3D-16-1",
        "3D-16-2",
        "3D-16-3",

        "3D-18-1",
        "3D-18-2",
        "3D-18-3",
        "3D-18-4",
        "3D-18-5",
        "3D-18-6",

        "3D-19-1",
        "3D-19-2",
        "3D-19-3",
        "3D-19-4",

        "3D-shizhengfu",
      ];

      // if (options.layers && options.layers.length) {
      //   options.layers.forEach((layerOptions) => {
      //     viewer.imageryLayers.addImageryProvider(
      //       new Cesium.UrlTemplateImageryProvider(layerOptions)
      //     );
      //   });
      // }

      // services.forEach((item) => {
      //   viewer.scene.addS3MTilesLayerByScp(
      //     `http://32.128.6.46:8090/iserver/services/${item}/rest/realspace/datas/Config/config`,
      //     {
      //       name: item,
      //     }
      //   );
      // });

      viewer.scene.globe.depthTestAgainstTerrain = false;
    },
    pupClose () {
      this.$emit('handleClose', 'app')
    }
  },
};
</script>

<style lang="scss" scoped>
.cpt-command-task-check-popup {
  width: 900px;
  height: 600px;
  // position: absolute;
  // top: 15%;
  // left: calc(50% - 400px);
  // background: rgba(22, 29, 45, 0.9);
  // box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);
  // border-radius: 10px;
  // background-size: 100% 100%;
  z-index: 999;
  position: fixed;
  top: 15%;
  left: 28%;
  box-sizing: border-box;
  background-color: #0A293A;

  ::v-deep {
    .el-input {
      padding: 0;

      .el-input__inner {
        background: transparent;
        // background: yellow;
        border: none;
        color: #08c2d1;
        padding: 0;
      }
    }
  }

  .header {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // height: 44px;
    // background: rgba(0, 34, 140, 0.2);
    // box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.5);
    // border-radius: 10px 10px 0 0;

    height: 36px;
    background-color: #224D68;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 5px 10px 5px 10px;

    .hd-box {
      color: #ffffff;
    }

    .left {
      // margin-left: 12px;
      display: flex;
      align-items: center;
    }
  }

  .hd-box-r {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #D2DFFF;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .img {
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
  }

  .hd-box {
    // font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    color: #ffffff;
    // background-image: -webkit-linear-gradient(right, #E3AA77, #F5CDA9, #F9ECD3, #FCDBB1, #EDB07A);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    // letter-spacing: 0;
    font-weight: 700;
    // margin-left: 4px;
  }

  .popup_content {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;

    .task_detail {
      .task_detail_box {
        display: flex;
        margin-bottom: 15px;

        &>span {
          line-height: 40px;
          display: inline-block;
          width: 100px;
          text-align: right;
          margin-right: 20px;
          font-size: 16px;
          color: #ccedff;
        }

        &>div {
          width: 300px;
          height: 40px;
          // background: #000000;
          border: 1px solid #315EC7;
          font-family: MicrosoftYaHeiUI;
          font-size: 16px;
          color: #ccedff;
          line-height: 40px;
          padding-left: 15px;
          box-sizing: border-box;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
      }
    }

    #page-command-container {
      width: 420px;
      height: 430px;
    }
  }

  .btns {
    display: flex;
    // width: 430px;
    justify-content: center;
    align-items: center;
    // margin-left: 393px;
    margin-top: 25px;

    .el-button {
      width: 156px;
      height: 40px;
      padding: 0;
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #E0F6FF;
      border: none;
      border-radius: 0;

      &:hover {
        opacity: 0.7;
      }
    }

    .btn_first {
      // background: url("~@/assets/zong_lan_ye_img/btn_lv.png");
      background-size: 100%;
      // background: rgba(201, 6, 6, 0.3);
      background: #4E87AC;
      // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 10px 0 #ff5c00;
      border-radius: 6px;
    }

    .btn_second {
      // background: url("~@/assets/zong_lan_ye_img/btn_lan.png");
      // background-size: 100%;
      // background: rgba(0, 34, 140, 0.2);
      background: #4E87AC;
      // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 15px 0 #00b6ff;
      border-radius: 6px;
      box-shadow: unset;
    }
  }
}
</style>
