let baseUrl = process.env.VUE_APP_BASE_URL
export function downloadData (url, name) {
  let a = document.createElement('a')
  a.href = baseUrl + url;
  console.log(a.href, 'a.hrefa.hrefa.href');
  debugger
  a.download = name
  a.click()
  window.URL.revokeObjectURL(url)
}
