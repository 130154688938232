<template>
  <div class="content">
    <div class="content_head">
      <div class="left">
        <div class="line"></div>更新公告
      </div>
      <div class="right" @click="update">关闭</div>
    </div>
    <div class="content_box">
      <div class="title">{{updateData.newsTitle}}</div>
      <div class="content_detail mt10" v-html="updateData.newsContent">
        <!-- <div class="type mt8">新增功能</div>
        <div class="box mt6">
          1、总览页、鹰视首页改版；<br />
          2、鹰控-实时点调通知栏添加点调位置；<br />
          3、飞控中心、鹰视、地面站控制链路融合；<br />
          4、新增更新公告弹窗。<br />
        </div>
        <div class="type mt8">缺陷修复</div>
        <div class="box mt6">
          1、解决总览页导出报表时间显示错误的问题；<br />
          2、解决鹰控-航空航线模块航线标签页面显示不全问题；<br />
          3、解决鹰视鹰视首页设备统计模块响应过慢问题；<br />
          4、解决鹰视-飞控中心-无人机应用地面站链路未识别到挂载问题；<br />
        </div>-->
      </div>
      <div class="media" v-for="(item, index) in updateData.mmcNewsFileList" :key="index">
        <video
          v-if="item.fileUrl.indexOf('.mp4') > -1"
          class="mt20"
          controls
          :src="baseUrl+item.fileUrl"
        ></video>
        <img v-else :src="baseUrl+item.fileUrl" alt="">
        
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import { mapGetters } from "vuex";
export default {
  components: {},
  computed: {
    ...mapGetters(["user_info"])
  },
  props: {
    updateData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_IMG_URL,
    };
  },
  methods: {
    async update() {
      let res = await API.HOME.readingmessage({ newsId: String(this.updateData.id) });

      this.$emit("close");
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.content {
  position: fixed;
  left: calc(50% - 260px);
  top: 12%;
  z-index: 99;
  width: 530px;
  // height: 650px;
  background: rgba(0, 39, 121, 0.5);
  box-shadow: 0px 2px 8px 0px rgba(1, 162, 255, 0.7),
    inset 0px 0px 64px 0px rgba(26, 138, 227, 0.35),
    inset 0px 0px 8px 0px #019aff;
  border: 1px solid #43deff;
  backdrop-filter: blur(2px);
  .content_head {
    display: flex;
    justify-content: space-between;
    height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border: 1px solid #43deff;
    align-items: center;
    padding: 0 16px;
    .left {
      display: flex;
      align-items: center;
      .line {
        width: 3px;
        height: 15px;
        background: #ffbd36;
        margin-right: 5px;
      }
      font-size: 18px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #ffffff;
      text-shadow: 0px 2px 4px #136791;
    }
    .right {
      font-size: 18px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #00ffff;
      background-clip: text;
    }
  }
  .content_box {
    display: flex;
    flex-direction: column;
    width: 500px;
    padding: 30px 0;
    min-height: 60px;
    padding: 16px;
    overflow-y: auto;
    overflow-x: hidden;
    .title {
      font-size: 18px;
      font-weight: 500;
      color: #fff;
    }
    .box {
      line-height: 30px;
    }
    .content_detail {
      color: #fff;
      line-height: 22px;
    }
    .media{
      display: flex;
      justify-content: center ;
    }
    video {
      background: #333;
      width: 500px;
      height: 300px;
    }
  }
}
</style>
