<template>
  <div>
    <div class="root w1000 h450 mt50">
      <div class="dia_title">
        <div class="dia_title_left  wih100">
          <div class="jcsb ml10">
            <div class="pup_img">
              <img src="~@/assets/zong_lan_ye_img/jinye.png" style="width: 100%; height: 100%" alt="" />
            </div>
            <div class="fontOne">值班信息录入</div>
          </div>
          <div class="w20 h20 cp  ml670 mr25 cp" @click="addDutyInfo">
            <el-tooltip content="手动录入" placement="top">
              <img src="~@/assets/zong_lan_ye_img/jairu.png" class="w20 h20 dib ml10 mr10" alt="" />
            </el-tooltip>
          </div>
          <el-upload :limit="1" accept=".xlsx,.xls" action="" :http-request="uploadSectionFile">
            <el-tooltip content="导入execel" placement="top">
              <div class="iconfont f18  w20 h20 mr10 icon-daoru   cp" style="color:#bbc2cf"></div>
            </el-tooltip>
          </el-upload>
          <el-tooltip content="模板下载" placement="top">
            <img @click="download" src="~@/assets/zong_lan_ye_img/xaizai.png" class="dib ml10 w20 h20" alt="" />
          </el-tooltip>
          <el-tooltip content="保存" placement="top">
            <img @click="handle_info" src="~@/assets/zong_lan_ye_img/baocun.png" class="w20 ml10 h20 dib" alt="" />
          </el-tooltip>
          <div class=" ml10 cp" style="color:#f4f4f6" @click="() => $emit('handleClose')">关闭</div>
        </div>
      </div>
      <div class="content-box">
        <div class="header center">
          <div class="th">值班时间</div>
          <div class="th">值班领导</div>
          <div class="th">值班民警</div>
          <div class="th">值班辅警</div>
          <div class="th">值班辅警</div>
          <div class="th "><span class="dib">操作</span></div>

        </div>
        <div v-for="(item, index) in dutyInfo" :key="index">
          <div class="link-top"></div>
          <div class="addInput">
            <div class="td">
              <el-date-picker v-model="item.timeDate" value-format="yyyy-MM-dd" type="date" placeholder="选择日期时间"
                :picker-options="pickerOptions">
              </el-date-picker>
            </div>
            <!-- <div class="td">
              <div @click="handlePop(1,item)"  class="text center"><div>{{item.leaderOnDuty}}</div><div>{{item.leaderPhone}}</div></div>
              <div v-if="item.leadervisible" class="seclect" ref="leadbox" tabindex="0" @blur="item.leadervisible=false">
                <div class="innerbox">
                  <div
                    @click="selectLeader(item2,item)"
                    class="center"
                    v-for="(item2, index) in userList"
                    :key="index"
                  >
                    <span>{{ item2.name }}</span> <span>{{ item2.phone }}</span>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="td">
              <div class="load">
                <el-autocomplete popper-class="inline-input" v-model="item.leader" :fetch-suggestions="fetchLoaderList" placeholder="请输入" @select="selectLeader(item, $event)"></el-autocomplete>
              </div>
            </div>
            <div class="td">
              <div class="load">
                <el-autocomplete popper-class="inline-input" v-model="item.police" :fetch-suggestions="fetchLoaderList" placeholder="请输入" @select="selectPolice(item, $event)"></el-autocomplete>
              </div>
            </div>
            <div class="td">
              <div class="load">
                <el-autocomplete popper-class="inline-input" v-model="item.auxiliary1" :fetch-suggestions="fetchAuxiliaryList" placeholder="请输入" @select="selectAuxiliary1(item, $event)"></el-autocomplete>
              </div>
            </div>
            <div class="td">
              <div class="load">
                <el-autocomplete popper-class="inline-input" v-model="item.auxiliary2" :fetch-suggestions="fetchAuxiliaryList" placeholder="请输入" @select="selectAuxiliary2(item, $event)"></el-autocomplete>
              </div>
            </div>
            <!-- <img src="~@/assets/images/home/dutydelete.svg" alt="" /> -->
            <div class="td">
              <div class="delBtn" @click="deleteInfo(index)">
                删除行
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import API from '@/api'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now() - 24 * 60 * 60 * 1000
        },
      },
      leadervisible: false,
      policeVisible: false,
      assistVisible: false,
      dutyInfo: [],
      uploadFiles: [],
      myHeaders: {},
      userList: [],
      toggleVisible: false,
      current: { col: '', row: '' },
    }
  },
  computed: {
    ...mapGetters(['user_info']),
  },
  watch: {
    toggleVisible: {
      handler(val) {
        if (val) {
          document.getElementsByClassName(this.current.col)[
            this.current.row
          ].style.display = 'none'
          this.toggleVisible = false
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getLoaderUser()
    this.getAuxiliaryUser()
  },
  methods: {
    /**
     * 筛选领导列表
     */
    fetchLoaderList(queryString, cb) {
      let results = queryString
        ? this.loaderList.filter((item) => {
            return item.value.indexOf(queryString.toLowerCase()) === 0
          })
        : this.loaderList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    fetchAuxiliaryList(queryString, cb) {
      let results = queryString
        ? this.auxiliaryList.filter((item) => {
            return item.value.indexOf(queryString.toLowerCase()) === 0
          })
        : this.auxiliaryList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    /**
     * 选择领导
     */
    selectLeader(newItem, userItem) {
      newItem.leaderOnDuty = userItem.name
      newItem.leaderPhone = userItem.phone
    },
    selectPolice(newItem, userItem) {
      newItem.policeOnDuty = userItem.name
      newItem.policePhone = userItem.phone
    },
    focusFn(col, row) {
      this.current = { col, row }
      document.getElementsByClassName(col)[row].style.display = 'block'
      document
        .getElementsByClassName(col)
        [row].setAttribute('x-placement', 'bottom-start')
    },
    boxClick() {
      document.getElementsByClassName(this.current.col)[
        this.current.row
      ].style.display = 'none'
    },
    selectAuxiliary1(newItem, userItem) {
      newItem.auxiliaryPoliceOnDuty = userItem.name
      newItem.auxiliaryPolicePhone = userItem.phone
    },
    selectAuxiliary2(newItem, userItem) {
      newItem.auxiliaryPoliceOnDutyTwo = userItem.name
      newItem.auxiliaryPolicePhoneTwo = userItem.phone
    },
    /**
     * 获取领导用户数据
     */
    async getLoaderUser() {
      let res = await API.HOME.getRosterUser({
        type: 0,
        pageNo: 1,
        pageSize: 999,
      })
      this.loaderList = res.data.map((item) => {
        return {
          ...item,
          value: item.name + item.phone,
          leader: '',
          police: '',
          auxiliary1: '',
          auxiliary2: '',
        }
      })
      // this.userList.unshift({
      //   value: "切换手动输入",
      //   flag1: null,
      //   flag2: null,
      //   flag3: null,
      //   flag4: null,
      // });
      console.log(this.userList)
    },
    /**
     * 获取辅警用户数据
     */
    async getAuxiliaryUser() {
      let res = await API.HOME.getRosterUser({
        type: 1,
        pageNo: 1,
        pageSize: 999,
      })
      this.auxiliaryList = res.data.map((item) => {
        return {
          ...item,
          value: item.name + item.phone,
          leader: '',
          police: '',
          auxiliary1: '',
          auxiliary2: '',
        }
      })
      // this.userList.unshift({
      //   value: "切换手动输入",
      //   flag1: null,
      //   flag2: null,
      //   flag3: null,
      //   flag4: null,
      // });
      console.log(this.userList)
    },
    async download() {
      let url = `${process.env.VUE_APP_BASE_URL}/hawksystemserver/roster/exportTemplate?FLYINGSESSIONID=${this.user_info.FLYINGSESSIONID}&mmc-identity=${this.user_info['mmc-identity']}`
      const fileName = url
      if ('download' in document.createElement('a')) {
        let downloadElement = document.createElement('a')
        downloadElement.href = url
        downloadElement.download = fileName
        document.body.appendChild(downloadElement)
        downloadElement.click()
        document.body.removeChild(downloadElement)
      }
    },

    // 导入模板
    async uploadSectionFile(e) {
      let file = e.file
      const formData = new FormData()
      formData.append('file', file)
      //创建xhr
      console.log(formData)
      let res = await API.HOME.importRoster(formData)
      console.log('---------res', res)
      if (res) {
        this.$el_message(res.message)
      } else {
        this.$el_message('导入成功!')
      }
      // if (res!=null) {
      // this.$message.warning("请勿导入重复时间");

      // }
    },
    async handle_info() {
      try {
        if (
          this.dutyInfo.every(
            (item) =>
              item.timeDate &&
              item.leaderOnDuty &&
              item.policeOnDuty &&
              item.auxiliaryPoliceOnDuty
          )
        ) {
          if (this.dutyInfo?.length) {
            this.dutyInfo.map((item) => {
              delete item.leader
              delete item.police
              delete item.auxiliary1
              delete item.auxiliary2
              delete item.value
            })
            let res = await API.HOME.addRoster(this.dutyInfo)
            if (res) {
              this.$message.success(res)
            } else {
              this.$message.success('导入成功')
              this.dutyInfo = []
            }
          }
        } else this.$message.warning('请填入完整有效信息')
      } catch (error) {
        // this.$message.warning("请勿输入重复时间");
      }
    },
    deleteInfo(index) {
      this.dutyInfo.splice(index, 1)
    },
    addDutyInfo() {
      this.dutyInfo.push({
        timeDate: '',
        leaderOnDuty: '',
        policeOnDuty: '',
        auxiliaryPoliceOnDuty: '',
        auxiliaryPoliceOnDutyTwo: '',
        leaderPhone: '',
        policePhone: '',
        auxiliaryPolicePhone: '',
        auxiliaryPolicePhoneTwo: '',
        leadervisible: false,
        policeVisible: false,
        assistVisible: false,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.phone {
  font-size: 12px;
}

::v-deep .el-upload .el-upload--text {
  width: 100px;
}

::v-deep .el-upload-list {
  display: none;
}

::v-deep .el-date-editor.el-input {
  width: 140px;
}
::v-deep .el-input__inner {
  border: 1px solid #00b6ff;
}

.content-box {
  height: 400px;
  overflow: hidden;
  overflow-y: scroll;

  .link-top {
    width: 100%;
    height: 1px;
    border-top: dashed #00b6ff 1px;
    color: #92d9ff;
  }

  .header {
    height: 50px;
    justify-content: flex-start;

    .th {
      font-family: MicrosoftYaHei-Bold;
      font-size: 18px;
      color: #fff;
      letter-spacing: 1.3px;
      line-height: 16px;
      font-weight: 700;
      flex: 1;
      text-align: center;
    }
  }

  .addInput {
    height: 55px;
    box-sizing: border-box;
    display: flex;

    ::v-deep .el-input__icon {
      line-height: 5px;
    }

    /*中间的过度的横线*/

    img {
      height: 25px;
      width: 25px;
      margin-left: 4px;
      margin-top: 15px;
    }

    .td {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;

      .load {
        display: flex;
        // width: 160px;
        // height: 40px;
        box-shadow: inset 0 0 3px 0 #315ec7;
        border-radius: 5px;

        input {
          background-color: transparent;
          height: 40px;
          border: 0;
          color: #c0c4cc;
        }

        .name {
          width: 70px;
        }

        .phone {
          width: 100px;
        }
      }

      /deep/.el-select .el-input__suffix {
        display: none;
      }
      /deep/.el-select .el-input__inner {
        width: 0px;
        display: none;
      }
      ::v-deep .el-popper {
        position: absolute !important;
        left: -172px !important;
        top: 20px !important;
      }

      /deep/ .el-scrollbar {
        overflow: hidden;
        position: relative;
        background-color: #1a2131;
        border: 1px solid #315ec7;
      }

      //item选项的hover样式
      /deep/ .el-select-dropdown__item.hover,
      /deep/ .el-select-dropdown__item:hover {
        color: #c0c4cc;
      }

      .text {
        width: 160px;
        height: 40px;
        border: 1px solid #fff;
        border-radius: 5px;

        div {
          color: #fff;
        }
      }

      .seclect {
        outline: none;

        .innerbox {
          margin-top: 10px;
          width: 160px;
          height: 120px;
          overflow-y: scroll;
          margin-left: 5px;
        }

        width: 170px;
        height: 130px;
        position: absolute;
        top: 50px;
        z-index: 10;
        background-color: #1a2131;
        background-image: url('~@/assets/images/home/roster.png');
        background-repeat: no-repeat;

        span {
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #92d9ff;
          letter-spacing: 0;
          font-weight: 400;
        }
      }
    }
  }
}

.footer {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  margin: 0 20px;
  width: 90px;
  height: 35px;
  background: rgba(0, 3, 36, 0.4);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5),
    inset 0 0 20px 0 rgba(0, 182, 255, 0.8);
  border-radius: 6px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: rgba(0, 255, 255, 0.8);
  letter-spacing: 0;
  font-weight: 400;
}

.delBtn {
  width: 66px;
  height: 38px;
  line-height: 38px;
  background: rgba(38, 71, 238, 0.71);
  box-shadow: inset 0px 1px 3px 0px #ffffff,
    inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
  border-radius: 4px;
  border: 1px solid;
  color: #e0f6ff;
  text-align: center;
}

.delBtn:hover {
  background: rgba(4, 12, 50, 0.71);
}

.root {
  background: rgba(22, 29, 45, 0.8);
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.dia_title {
  background: linear-gradient(
    180deg,
    #9198ff 0%,
    rgba(45, 81, 153, 0.45) 40%,
    #05091a 100%
  );
  box-shadow: inset 0px 0px 10px 2px #3f9dff;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #427dff;
  height: 44px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .dia_title_left {
    .icon-baocun {
      color: #00ffff;
      font-size: 25px;
    }

    .pup_img {
      display: flex;
      width: 19px;
      height: 25px;
      margin: 0 4px 0 0;
    }

    .fontOne {
      font-size: 20px;
      font-family: YouSheBiaoTiHei;
      color: #14faff;
      line-height: 26px;
      text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
      background: linear-gradient(
        135deg,
        #e3aa77 0%,
        #f5cda9 38%,
        #f9ecd3 58%,
        #fcdbb1 79%,
        #edb07a 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .icon-daoru {
      color: #00ffff;
      font-size: 25px;
    }

    .icon-a-xiazai2px {
      color: #00ffff;
      font-size: 25px;
    }

    margin: 0;
    display: flex;
    align-items: center;

    ::v-deep {
      .el-input__inner {
        background: #000000 !important;
        border: 1px solid #00b6ff;
        font-family: MicrosoftYaHeiUI;
        font-size: 16px;
        color: #00b6ff;
      }
    }

    .title {
      font-family: MicrosoftYaHei-Bold;
      font-size: 18px;
      color: #00ffff;
      letter-spacing: 0;
      font-weight: 700;
      line-height: 44px;
    }

    .btn-find {
      width: 63px;
      height: 27px;
      background: rgba(0, 3, 36, 0.4);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5),
        inset 0 0 15px 0 rgba(0, 182, 255, 0.9);
      border-radius: 6px;
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: rgba(0, 255, 255, 0.8);
      letter-spacing: 0;
      font-weight: 400;
      text-align: center;
      line-height: 27px;
      margin-left: 10px;
    }
  }

  .dia_title_right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 0 !important;

    .dia_title_btn {
      background: rgba(0, 34, 140, 0.2);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 15px 0 #00b6ff;
      border-radius: 6px;
      line-height: 27px;
      text-align: center;
      width: 77.02px;
      height: 27px;
      margin-right: 10px !important;
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: rgba(0, 255, 255, 0.8);
      letter-spacing: 0;
      font-weight: 400;
    }

    .dia_title_close {
      font-family: MicrosoftYaHei;
      font-size: 18px;
      color: #00ffff;
      letter-spacing: 0;
      font-weight: 400;
      line-height: 44px;
      margin-right: 20px;
    }
  }
}

.cpt-home-online_date {
  height: 20%;
  width: 100%;
  position: relative;

  .progress-box {
    // width: 88px;
    width: 100%;
    height: 100%;
    // padding: 5% 0 0 10px;
    box-sizing: border-box;

    // position: relative;
    .device-btn {
      width: 65px;
      height: 26px;
      line-height: 26px;
      text-align: center;
      background: #129c9c;
      border-radius: 3px;
      opacity: 1;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #feffff;
      font-weight: 400;
      line-height: 26px;
      position: absolute;
      top: 2%;
      right: 10%;
      cursor: pointer;
      // top:5%;
      // right: 0;
    }

    .progress-boxGround {
      width: 95%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: row;
      // align-content: center;
      align-items: center;

      .progress-box-item {
        width: 77px;
        height: 77px;
        position: relative;
        flex: 1;

        .item-value {
          position: absolute;
          top: 20%;
          // left: 28%;
          text-align: center;
          width: 77px;

          .online-value {
            font-family: DINAlternate-Bold;
            font-size: 26px;
            color: #ffc15b;
            letter-spacing: 0;
            font-weight: 700;
            text-align: center;
          }

          .totle-value {
            font-family: DINAlternate-Bold;
            font-size: 12px;
            color: #43f0df;
            letter-spacing: 0;
            font-weight: 700;
            text-align: center;
          }
        }

        .item-title {
          width: 77px;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #92d9ff;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;
          padding-top: 8px;
          box-sizing: border-box;
        }
      }
    }
  }
}

.activeOnBox {
  background: rgba(0, 3, 36, 0.4) !important;
  // background: red;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5),
    inset 0 0 15px 0 rgba(0, 182, 255, 0.9);
  border-radius: 6px;
}

::v-deep {
  .el-input__inner {
    border: 1px solid #315ec7;
    box-shadow: inset 0 0 3px 0 #00b6ff;
    border-radius: 6px;
    width: 152px;
    height: 45px;
    background: #161d2d;
    color: #00ffff;
  }

  .el-input__icon {
    line-height: 20px;
  }
}
</style>